import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

function CardPhotoToPath(props) {
  const location = useLocation();
  function storeCourse(name) {
    localStorage.setItem('buName', name)
  }

  return (
    <Card
      sx={{
        width: 200,
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 3,
        boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        // backgroundColor: '#a3a3a3'
      }}
    >
      <CardActionArea
        component={Link}
        to={`${location.pathname}${props.name}/`}
        onClick={() => storeCourse(props.name)}
        sx={{
          height: '80%',
          // backgroundColor: '#a3a3a3'
        }}
      >
        <CardMedia
          component="img"
          image={props.fileLocation}
          alt={props.name}
          sx={{
            height: '95%',
            objectFit: 'cover',
            // backgroundColor: '#a3a3a3',
            paddingTop: 2
          }}
        />
      </CardActionArea>
      <CardContent
        sx={{
          height: '20%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 1,
          // backgroundColor: '#a3a3a3'
        }}
      >
        <Typography style={{ color: '#000000', fontSize: 18, fontWeight: 'bold' }} >
          {props.name}
        </Typography>
        {/* <Button size="small">
              Enter
            </Button> */}
      </CardContent>
    </Card>
  )
}

export default CardPhotoToPath
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Link,
    Grid
  } from "@material-ui/core";
  import { useNavigate } from 'react-router-dom';
  import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
  
  function MasterFooter(props) {
    const navigate = useNavigate();

    return (
      <Box {...props}>
         <Grid item xs={12} >
          <Button
            onClick={() => {navigate(`/${props.click}`, { replace: true })}}
            variant="contained"
            style={{ borderRadius: 5, fontSize: 20, fontWeight: "bold", background: '#575755', width: '10%' }}
          >
            <ArrowBackIosIcon style={{ color: '#ffffff' }} />
            back
          </Button>
        </Grid>
        <Grid item xs={12} style={{height: '1vh'}}>

        </Grid>
      </Box>
    );
  }
  
  MasterFooter.propTypes = {};
  
  export default MasterFooter;
  
import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import moment from 'moment-timezone';
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import HeaderDetail from '../components/header/HeaderDetail';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Time from "../assets/img/fast-time.png";
import Fighting from "../assets/img/first1.gif";
import Running from "../assets/img/running.gif";
import BarChartProductionTracking from '../components/chart/BarChartProductionTracking';
import Pin from "../assets/img/pin_light.png";
import Target from "../assets/img/svg/target.svg"
import Run from '../assets/img/running_png.png';
import Fist from '../assets/img/protest_png.png';
import db from "../firebase-config"
import { useParams } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#bcd6d0',
    padding: theme.spacing(0),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    //borderRadius: 10,
    width: '101.5%',
    height: '13vh',
    position: "relative",
    left: '-1.5vw'

}));

const ItemTarget = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(3),
    textAlign: 'center',
    border: "3px solid #4a4a49",
    borderRadius: 10,
    //height: '102%'

}));

const ItemBackground = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(1),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    borderRadius: 0,
    width: '101.5%',
    position: "relative",
    left: '-1.5vw'

}));


const PerformanceLine8Page2 = () => {
    const { bu, plant, factory, line } = useParams();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [performanceP2, setPerformanceP2] = useState([])

    const performance = () => {
        try {
            // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
            const buName = bu;
            const plantName = plant;
            const factoryName = factory;
            const lineName = line;

            // ตรวจสอบเงื่อนไขโดยใช้ object mapping
            const lineMapping = {
                "แปรรูปไก่_4117_FPP2_Line7-v2": 'LINE7',
                "แปรรูปไก่_4117_FPP2_Line8": 'LINE8',
                "แปรรูปไก่_4117_FPP2_Line9": 'LINE9',
                "แปรรูปไก่_4117_FPP2_Line10": 'LINE10',
            };

            // สร้าง key สำหรับจับคู่
            const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

            // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
            return lineMapping[key];
        } catch (error) {
            console.error("Error in line_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };

    const line_performance = () => {
        try {
            // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
            const buName = bu;
            const plantName = plant;
            const factoryName = factory;
            const lineName = line;

            // ตรวจสอบเงื่อนไขโดยใช้ object mapping
            const lineMapping = {
                "แปรรูปไก่_4117_FPP2_Line7-v2": 'Line7',
                "แปรรูปไก่_4117_FPP2_Line8": 'Line8',
                "แปรรูปไก่_4117_FPP2_Line9": 'Line9',
                "แปรรูปไก่_4117_FPP2_Line10": 'Line10',
            };

            // สร้าง key สำหรับจับคู่
            const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

            // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
            return lineMapping[key];
        } catch (error) {
            console.error("Error in line_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };

    const line_realtime = () => {
        try {
            // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
            const buName = bu;
            const plantName = plant;
            const factoryName = factory;
            const lineName = line;

            // ตรวจสอบเงื่อนไขโดยใช้ object mapping
            const lineMapping = {
                "แปรรูปไก่_4117_FPP2_Line7-v2": 'Last Bin',
                "แปรรูปไก่_4117_FPP2_Line8": 'Last Bin',
                "แปรรูปไก่_4117_FPP2_Line9": 'Last Bin',
                "แปรรูปไก่_4117_FPP2_Line10": 'Last Bin',
            };

            // สร้าง key สำหรับจับคู่
            const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

            // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
            return lineMapping[key];
        } catch (error) {
            console.error("Error in line_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };

    useEffect(() => {
        const docRef = db
            .collection("prod_mes_ignition_performance_page12_v2")
            .doc(`411700000000000000${performance()}ViaTumblerPerformance`);

        const unsubscribe = docRef.onSnapshot((doc) => {
            if (doc.exists) {
                setPerformanceP2({ id: doc.id, ...doc.data() });
            } else {
                setPerformanceP2(null);
            }
        }, (error) => {
            console.error("Error fetching document:", error);
        });

        return () => unsubscribe();
    }, []);

    var isValidTimeFormat = (time) =>
        typeof time === "string" && /^\s*([01]?\d|2[0-3]):([0-5]\d):([0-5]\d)(\.\d+)?\s*$/.test(time);

    var extractHourMinute = (time) => time.split(":").slice(0, 2);

    ///// split time non production time
    var non_production_time_before =
        !performanceP2?.NON_PRODUCTION_TIME || performanceP2?.NON_PRODUCTION_TIME === 'NA'
            ? ["-1 day", "00:00:00"]
            : performanceP2?.NON_PRODUCTION_TIME.split(",").map(t => t.trim()).filter(Boolean);

    var latest_time = non_production_time_before.at(-1); // ใช้ค่าล่าสุดจากอาร์เรย์

    var non_production_time =
        isValidTimeFormat(latest_time) ? extractHourMinute(latest_time) : ["00", "00"];

    var non_production_hour = Number(non_production_time[0]);
    var non_production_minute = Number(non_production_time[1]);

    ///// split time  production time
    var production_time_before = 
        !performanceP2?.PRODUCTION_TIME || performanceP2?.PRODUCTION_TIME === 'NA'
            ? ["-1 day", "00:00:00"]
            : (performanceP2?.PRODUCTION_TIME).split(",").map(t => t.trim()).filter(Boolean);

    var latest_production_time = production_time_before.at(-1); // ใช้ค่าล่าสุดจากอาร์เรย์

    var production_time = 
        isValidTimeFormat(latest_production_time) ? extractHourMinute(latest_production_time) : ["00", "00"]

    var production_hour = Number(production_time[0])

    var production_minute = Number(production_time[1])


    const getChartColor = () => {
        let color;
        try {
            if ((performanceP2?.ACTUAL_WEIGHT == undefined || performanceP2?.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP2?.ACTUAL_WEIGHT) >= (((((performanceP2?.PRODUCTION_TIME == undefined ? 0 : production_hour) + ((performanceP2?.PRODUCTION_TIME == undefined ? 0 : production_minute) / 60)) + ((performanceP2?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_hour) + ((performanceP2?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_minute) / 60))) * (performanceP2?.STD_FEED_RATE == undefined || performanceP2?.STD_FEED_RATE == 'NA' || performanceP2?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP2?.STD_FEED_RATE)) * 1)) {
                color = "#2e9281"
            } else if ((performanceP2?.ACTUAL_WEIGHT == undefined || performanceP2?.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP2?.ACTUAL_WEIGHT) >= (((((performanceP2?.PRODUCTION_TIME == undefined ? 0 : production_hour) + ((performanceP2?.PRODUCTION_TIME == undefined ? 0 : production_minute) / 60)) + ((performanceP2?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_hour) + ((performanceP2?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_minute) / 60))) * (performanceP2?.STD_FEED_RATE == undefined || performanceP2?.STD_FEED_RATE == 'NA' || performanceP2?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP2?.STD_FEED_RATE)) * 0.8)) {
                color = "#b58514"
            } else if ((performanceP2?.ACTUAL_WEIGHT == undefined || performanceP2?.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP2?.ACTUAL_WEIGHT) < (((((performanceP2?.PRODUCTION_TIME == undefined ? 0 : production_hour) + ((performanceP2?.PRODUCTION_TIME == undefined ? 0 : production_minute) / 60)) + ((performanceP2?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_hour) + ((performanceP2?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_minute) / 60))) * (performanceP2?.STD_FEED_RATE == undefined || performanceP2?.STD_FEED_RATE == 'NA' || performanceP2?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP2?.STD_FEED_RATE)) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getFeedrateColor = () => {
        let color;
        try {
            if ((performanceP2?.ACTUAL_FEED_RATE == undefined || performanceP2?.ACTUAL_FEED_RATE == 'NA' ? 0 : performanceP2?.ACTUAL_FEED_RATE) >= (((performanceP2?.STD_FEED_RATE == undefined || performanceP2?.STD_FEED_RATE == 'NA' || performanceP2?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP2?.STD_FEED_RATE) * 1)) {
                color = "#2e9281"
            } else if ((performanceP2?.ACTUAL_FEED_RATE == undefined || performanceP2?.ACTUAL_FEED_RATE == 'NA' ? 0 : performanceP2?.ACTUAL_FEED_RATE) >= (((performanceP2?.STD_FEED_RATE == undefined || performanceP2?.STD_FEED_RATE == 'NA' || performanceP2?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP2?.STD_FEED_RATE) * 0.8)) {
                color = "#b58514"
            } else if ((performanceP2?.ACTUAL_FEED_RATE == undefined || performanceP2?.ACTUAL_FEED_RATE == 'NA' ? 0 : performanceP2?.ACTUAL_FEED_RATE) < (((performanceP2?.STD_FEED_RATE == undefined || performanceP2?.STD_FEED_RATE == 'NA' || performanceP2?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP2?.STD_FEED_RATE) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getForecastColor = () => {
        let color;
        try {
            if ((performanceP2?.FORECAST_IN_KG == undefined || performanceP2?.FORECAST_IN_KG == 'NA' ? 0 : performanceP2?.FORECAST_IN_KG) >= (performanceP2?.TARGET_WEIGHT_KG == undefined || performanceP2?.TARGET_WEIGHT_KG == 'NA' ? 0 : performanceP2?.TARGET_WEIGHT_KG)) {
                color = "#2e9281"
            } else if ((performanceP2?.FORECAST_IN_KG == undefined || performanceP2?.FORECAST_IN_KG == 'NA' ? 0 : performanceP2?.FORECAST_IN_KG) < (performanceP2?.TARGET_WEIGHT_KG == undefined || performanceP2?.TARGET_WEIGHT_KG == 'NA' ? 0 : performanceP2?.TARGET_WEIGHT_KG)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }



    return (
        <>
            <Helmet>
                <title>Performance Page2 | MES</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <HeaderDetail
                        // line={no_data === 1 ? (performanceP2.LINE == 'NA' ? 'Line7' : performanceP2.LINE) : 'Line7'}
                        line={line_performance()}
                        sku={performanceP2?.SKU === undefined ? '-' : (performanceP2?.SKU == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : performanceP2?.SKU == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? performanceP2?.SKU : performanceP2?.SKU)}
                        icon={performanceP2?.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ height: '55px' }} />) : (<LightModeOutlinedIcon style={{ height: '55px' }} />)}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Item>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid item xs={2}>
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 32, lineHeight: "7.5vh" }} >
                                PO Date
                            </Typography>
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 43, lineHeight: "3vh" }} >
                                {performanceP2?.FACTORY_DATE === undefined ? '-' : moment(performanceP2?.FACTORY_DATE).format('DD MMM YY')}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} >
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 32, lineHeight: "7.5vh" }} >
                                Start
                            </Typography>
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                {performanceP2?.START_DATETIME === undefined ? '00:00' : (performanceP2?.START_DATETIME == 'NA' ? '-' : moment(performanceP2?.START_DATETIME).format('HH:mm'))}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} >
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 32, lineHeight: "7.5vh" }} >
                                End
                            </Typography>
                            <Typography style={{ color: performanceP2.FINISH_DATETIME == 'NA' ? "#636363" : "#000000", fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                {performanceP2?.FINISH_DATETIME === undefined ? "00:00" : (performanceP2?.FINISH_DATETIME == 'NA' ? performanceP2?.FORECAST_FINISH_DATETIME == undefined || performanceP2?.FORECAST_FINISH_DATETIME == 'NA' || performanceP2?.FORECAST_FINISH_DATETIME == null ? '-' : moment(performanceP2?.FORECAST_FINISH_DATETIME).format('HH:mm') : moment(performanceP2?.FINISH_DATETIME).format('HH:mm'))}
                            </Typography>
                        </Grid>

                        <Grid item xs={0.1} style={{ borderLeft: "3px dashed #3cbea8", height: '10vh', position: "relative", top: '0.5vw' }} >

                        </Grid>


                        <Grid item xs={2.7}>
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 32, lineHeight: "7vh" }} >
                                Production
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                    {performanceP2?.PRODUCTION_TIME === undefined ? 0 : (performanceP2?.PRODUCTION_TIME == 'NA' ? "-" : production_hour)}
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 35, lineHeight: "3vh", paddingTop: 5 }} >
                                    hr
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                    {performanceP2?.PRODUCTION_TIME === undefined ? 0 : (performanceP2?.PRODUCTION_TIME == 'NA' ? "-" : production_minute)}
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 35, lineHeight: "3vh", paddingTop: 5 }} >
                                    min
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={0.1} style={{ borderLeft: "3px dashed #3cbea8", height: '10vh', position: "relative", top: '0.5vw' }} >

                        </Grid>

                        <Grid item xs={2.7}>
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 32, lineHeight: "7vh" }} >
                                Non-Production
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                    {performanceP2?.NON_PRODUCTION_TIME === undefined ? 0 : (performanceP2?.NON_PRODUCTION_TIME == 'NA' ? '-' : non_production_hour)}
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 35, lineHeight: "3vh", paddingTop: 5 }} >
                                    hr
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                    {performanceP2.NON_PRODUCTION_TIME === undefined ? 0 : (performanceP2.NON_PRODUCTION_TIME == 'NA' ? '-' : non_production_minute)}
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 35, lineHeight: "3vh", paddingTop: 5 }} >
                                    min
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Item>
            </Grid>

            <Grid item xs={12} sm={12}>
                <ItemBackground>
                    <Grid item xs={12} sm={12} style={{ paddingTop: 5, paddingRight: 20, paddingLeft: 20 }}>
                        <ItemTarget>
                            <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
                                <Grid item xs={7} style={{ paddingLeft: 50 }}>
                                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                                        <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
                                            <Grid item xs={6}>
                                                <table>
                                                    <tbody>
                                                        <tr >
                                                            <td style={{ width: '30%', fontSize: 14, fontWeight: 'bold,' }} align="right">
                                                                {performanceP2?.IS_RUNNING == undefined ? <img src={Fist} height="70vh" /> : (performanceP2?.IS_RUNNING == 'YES' ? <img src={Fighting} height="70vh" /> : <img src={Fist} height="70vh" />)}
                                                            </td>
                                                            <td style={{ width: '20%', color: performanceP2?.PERCENT_ACTUAL_WEIGHT === undefined ? colors.neutral['green'] : getChartColor(), fontSize: 50, fontWeight: 'bold' }} align="right">
                                                                {performanceP2?.PERCENT_ACTUAL_WEIGHT === undefined ? 0 : performanceP2?.PERCENT_ACTUAL_WEIGHT}
                                                            </td>
                                                            <td style={{ width: '20%', color: performanceP2?.PERCENT_ACTUAL_WEIGHT === undefined ? colors.neutral['green'] : getChartColor(), fontSize: 45, fontWeight: 'bold' }} align="left">
                                                                %
                                                            </td>
                                                        </tr>

                                                        <tr >
                                                            <td style={{ width: '30%', color: colors.neutral['white'], fontSize: 45, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                Actual
                                                            </td>
                                                            <td style={{ width: '20%', color: performanceP2?.ACTUAL_WEIGHT === undefined ? colors.neutral['green'] : getChartColor(), fontSize: 60, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                {performanceP2?.ACTUAL_WEIGHT === undefined ? 0 : Math.round(performanceP2?.ACTUAL_WEIGHT).toLocaleString()}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['white'], fontSize: 30, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 11 }} align="left">
                                                                kg
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <table>
                                                    <tbody>
                                                        <tr >
                                                            <td style={{ width: '30%', fontSize: 14, fontWeight: 'bold,' }} align="right">
                                                                {performanceP2?.IS_RUNNING == undefined ? <img src={Run} height="70vh" /> : (performanceP2?.IS_RUNNING == 'YES' ? <img src={Running} height="70vh" /> : <img src={Run} height="70vh" />)}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['grey'], fontSize: 50, fontWeight: 'bold' }} align="right">
                                                                {performanceP2?.PERCENT_ACTUAL_WEIGHT === undefined ? 0 : (performanceP2?.PERCENT_ACTUAL_WEIGHT === 'NA' ? 'NA' : (100 - performanceP2?.PERCENT_ACTUAL_WEIGHT <= 0 ? 0 : 100 - performanceP2?.PERCENT_ACTUAL_WEIGHT))}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['grey'], fontSize: 45, fontWeight: 'bold' }} align="left">
                                                                %
                                                            </td>
                                                        </tr>

                                                        <tr >
                                                            <td style={{ width: '30%', color: colors.neutral['white'], fontSize: 45, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                Diff.
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['grey'], fontSize: 60, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                {performanceP2?.REMAINING_WEIGHT === undefined ? 0 : (performanceP2?.REMAINING_WEIGHT == 'NA' ? 'NA' : (performanceP2?.REMAINING_WEIGHT <= 0 ? 0 : Math.round(performanceP2?.REMAINING_WEIGHT).toLocaleString()))}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['grey'], fontSize: 30, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 11 }} align="left">
                                                                kg
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={9} textAlign="center" style={{ paddingTop: 30 }}>
                                        {(performanceP2?.TARGET_WEIGHT_KG ===
                                            ''
                                            && performanceP2?.ACTUAL_WEIGHT ===
                                            ''
                                            && performanceP2?.TARGET_NOW_KG ===
                                            ''
                                            && performanceP2?.PERCENT_TARGET_NOW ===
                                            ''
                                        ) || (performanceP2?.TARGET_WEIGHT_KG ===
                                            'NA'
                                            && performanceP2?.ACTUAL_WEIGHT ===
                                            'NA'
                                            ) ?
                                            <BarChartProductionTracking
                                                target_quantity={0}
                                                actual_weight={0}
                                                target_now={0}
                                                persent={0}
                                                //chart_color={getChartColor()}
                                                position={40}
                                                fontsize={24}
                                                width_svg={18}
                                                height_svg={18}
                                                height='55%'
                                            />
                                            : <BarChartProductionTracking
                                                target_quantity={performanceP2?.TARGET_WEIGHT_KG === undefined || performanceP2?.TARGET_WEIGHT_KG === 'NA' ? 0 : performanceP2?.TARGET_WEIGHT_KG}
                                                actual_weight={performanceP2?.ACTUAL_WEIGHT === undefined || performanceP2?.ACTUAL_WEIGHT === 'NA' ? 0 : performanceP2?.ACTUAL_WEIGHT}
                                                target_now={performanceP2?.TARGET_NOW_KG === undefined || performanceP2?.TARGET_NOW_KG === 'NA' ? 0 : Math.round(Number(performanceP2?.TARGET_NOW_KG))}
                                                persent={performanceP2?.PERCENT_TARGET_NOW === undefined || performanceP2?.PERCENT_TARGET_NOW === 'NA' ? 0 : performanceP2?.PERCENT_TARGET_NOW}
                                                chart_color={getChartColor()}
                                                position={performanceP2?.PERCENT_TARGET_NOW === undefined || performanceP2?.PERCENT_TARGET_NOW === 'NA' ? 0 : (performanceP2?.PERCENT_TARGET_NOW <= 50 ? 40 : -60)}
                                                fontsize={35}
                                                width_svg={30}
                                                height_svg={30}
                                                pin={Pin}
                                                height='90%'
                                                width='120%'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={5} style={{ paddingTop: 20 }}>
                                    <Grid item xs={12} textAlign="center">
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <img src={Target} height="70px" />
                                            <Typography style={{ color: colors.neutral['green'], fontWeight: "bold", fontSize: 60, paddingTop: 30 }}>
                                                &nbsp;Target
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} textAlign="center" >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['green'], fontSize: 70, fontWeight: 'bold', lineHeight: "4vh" }}>
                                                &nbsp;{performanceP2?.TARGET_WEIGHT_KG === undefined ? 0 : (performanceP2?.TARGET_WEIGHT_KG).toLocaleString()}
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['green'], fontSize: 40, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 9 }}>
                                                &nbsp;kg
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ItemTarget>
                    </Grid>
                </ItemBackground>
            </Grid>

            <Grid item xs={12} sm={12}>
                <ItemBackground>
                    <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
                        <Grid item xs={7} style={{ paddingLeft: 50 }}>
                            <Grid item xs={12} textAlign="center">
                                <Typography style={{ color: colors.neutral['white'], fontSize: 45 }} >
                                    Performance ({performanceP2?.STD_FEED_RATE === undefined ? "Std." + (0) : (performanceP2?.STD_FEED_RATE == "ไม่มีข้อมูล Std. Cap ในระบบ" ? (performanceP2?.STD_FEED_RATE).toLocaleString() : "Std." + (performanceP2?.STD_FEED_RATE).toLocaleString())})
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center" >
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: performanceP2?.ACTUAL_FEED_RATE === undefined ? colors.neutral['green'] : getFeedrateColor(), fontSize: 60, fontWeight: 'bold', lineHeight: "4vh" }} >
                                        {performanceP2?.ACTUAL_FEED_RATE === undefined ? 0 : (performanceP2?.ACTUAL_FEED_RATE == 'NA' ? 'NA' : (Math.round(performanceP2?.ACTUAL_FEED_RATE)).toLocaleString())}
                                    </Typography>
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 30, paddingTop: 15, lineHeight: "4vh" }} >
                                        &nbsp;kg/hr
                                    </Typography>
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 50, paddingTop: 5, lineHeight: "4vh" }} >
                                        &nbsp;({line_realtime()} {performanceP2?.ACTUAL_FEED_RATE_LAST_BIN == undefined ? 0 : Math.round(performanceP2?.ACTUAL_FEED_RATE_LAST_BIN).toLocaleString()})
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid item xs={12} textAlign="center" >
                                <Typography style={{ color: colors.neutral['white'], fontSize: 45 }}>
                                    Forecast
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center" style={{}}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 60, fontWeight: 'bold', lineHeight: "4vh" }} >
                                        {performanceP2?.FORECAST_IN_KG === undefined ? 0 : (performanceP2?.FORECAST_IN_KG).toLocaleString()}
                                    </Typography>
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 30, fontWeight: 'bold', paddingTop: 15, lineHeight: "4vh" }} >
                                        &nbsp;kg
                                    </Typography>
                                    <Typography style={{ color: performanceP2?.FORECAST === undefined ? colors.neutral['green'] : getForecastColor(), fontWeight: "bold", fontSize: 40, paddingTop: 5, lineHeight: "4vh" }} >
                                        &nbsp;({performanceP2?.FORECAST === undefined ? 0 : (performanceP2?.FORECAST >= 0 ? "+" + (performanceP2?.FORECAST).toLocaleString() : (performanceP2?.FORECAST).toLocaleString())})
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </ItemBackground>
            </Grid>
        </>
    )
}

export default PerformanceLine8Page2

import React from 'react'
import { Box, Grid, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import GaugeComponent from 'react-gauge-component'


function GaugeChart(props) {

    const scale = (((props.setting - props.std)/props.std) * 100) * 2

    const getSubArcs = () => {
      let sub_arcs;
      try {
        if (scale == 2) {
          sub_arcs = [{ limit: 0.25 }, { limit: 0.5 }, { limit: 0.75 }, { limit: 1 },{ limit: 1.25 }, { limit: 1.50 }, { limit: 1.75 }, { limit: 2.0 }]
          } else if (scale == 4) {
            sub_arcs = [{ limit: 0.5 }, { limit: 1 }, { limit: 1.5 }, { limit: 2 },{ limit: 2.5 }, { limit: 3 }, { limit: 3.5 }, { limit: 4 }]
          }
        return sub_arcs;
      }
      catch {
        console.log()
      }
    }

    const getColorGiveaway = () => {
        let color;
        try {
            if (scale == 2 && (props.value == undefined ? 0 : props.value) >= 0 && (props.value == undefined ? 0 : props.value) <= 0.25) {
                color = ['#2c8c7c', '#73D3C3', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#FFDF77', '#F4AD7E', '#EE6666']
              } else if (scale == 2 && (props.value == undefined ? 0 : props.value) > 0.25 && (props.value == undefined ? 0 : props.value) <= 0.50) {
                color = ['#80BAB0', '#37af9b', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#FFDF77', '#F4AD7E', '#EE6666']
              } else if (scale == 2 && (props.value == undefined ? 0 : props.value) >  0.50 && (props.value == undefined ? 0 : props.value) <= 0.75) {
                color =  ['#80BAB0', '#73D3C3', '#56cab7', '#C5EDE6', '#FFEAA8', '#FFDF77', '#F4AD7E', '#EE6666']
              } else if (scale == 2 && (props.value == undefined ? 0 : props.value) >  0.75 && (props.value == undefined ? 0 : props.value) <= 1) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#96ded2', '#FFEAA8', '#FFDF77', '#F4AD7E', '#EE6666']
              }else if (scale == 2 && (props.value == undefined ? 0 : props.value) >  1 && (props.value == undefined ? 0 : props.value) <= 1.25) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#C5EDE6', '#f5c842', '#FFDF77', '#F4AD7E', '#EE6666']
              } else if (scale == 2 && (props.value == undefined ? 0 : props.value) >  1.25 && (props.value == undefined ? 0 : props.value) <= 1.50) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#fcad00', '#F4AD7E', '#EE6666']
              } else if (scale == 2 && (props.value == undefined ? 0 : props.value) >  1.50 && (props.value == undefined ? 0 : props.value) <= 1.75) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#FFDF77', '#ec7728', '#EE6666']
              } else if (scale == 2 && (props.value == undefined ? 0 : props.value) >  1.75 ) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#FFDF77', '#F4AD7E', '#e20000']
              } else if (scale == 4 && (props.value == undefined ? 0 : props.value) >= 0 && (props.value == undefined ? 0 : props.value) <= 0.5) {
                color = ['#2c8c7c', '#73D3C3', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#FFDF77', '#F4AD7E', '#EE6666']
              } else if (scale == 4 && (props.value == undefined ? 0 : props.value) > 0.50 && (props.value == undefined ? 0 : props.value) <= 1) {
                color = ['#80BAB0', '#37af9b', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#FFDF77', '#F4AD7E', '#EE6666']
              } else if (scale == 4 && (props.value == undefined ? 0 : props.value) >  1 && (props.value == undefined ? 0 : props.value) <= 1.5) {
                color =  ['#80BAB0', '#73D3C3', '#56cab7', '#C5EDE6', '#FFEAA8', '#FFDF77', '#F4AD7E', '#EE6666']
              } else if (scale == 4 && (props.value == undefined ? 0 : props.value) >  1.5 && (props.value == undefined ? 0 : props.value) <= 2) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#96ded2', '#FFEAA8', '#FFDF77', '#F4AD7E', '#EE6666']
              } else if (scale == 4 && (props.value == undefined ? 0 : props.value) >  2 && (props.value == undefined ? 0 : props.value) <= 2.5) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#C5EDE6', '#f5c842', '#FFDF77', '#F4AD7E', '#EE6666']
              } else if (scale == 4 && (props.value == undefined ? 0 : props.value) >  2.5 && (props.value == undefined ? 0 : props.value) <= 3) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#fcad00', '#F4AD7E', '#EE6666']
              } else if (scale == 4 && (props.value == undefined ? 0 : props.value) >  3 && (props.value == undefined ? 0 : props.value) <= 3.5) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#FFDF77', '#ec7728', '#EE6666']
              } else if (scale == 4 && (props.value == undefined ? 0 : props.value) >  3.5 ) {
                color =  ['#80BAB0', '#73D3C3', '#9FE1D6', '#C5EDE6', '#FFEAA8', '#FFDF77', '#F4AD7E', '#e20000']
              }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getValue = () => {
      let value;
      try {
        if (scale == 2) {
          value = props.value >= 2 ? 2 : props.value
          } else if (scale == 4) {
            value = props.value >= 4 ? 4 : props.value
          }
        return value;
      }
      catch {
        console.log()
      }
    }
    return (
        <>

            <Box sx={{ height: '1vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <GaugeComponent
                    minValue={0}
                    maxValue={scale}
                    value={getValue()}
                    style={{ width: props.width, padding: 0, height: props.height }}
                    type="semicircle"
                    labels={{
                        valueLabel: {
                            formatTextValue: value => value + '%',
                            style: { fontSize: "48px", fill: "#f27e30" },
                            hide: true
                        },
                        tickLabels: {
                            type: "outer",
                            defaultTickValueConfig: {
                                formatTextValue: value => value + '%', fontSize: 10,
                                style: { fontSize: "10px", fill: "#ffffff" },
                            },
                            defaultTickLineConfig: {
                                style: { height: "1px" },
                            },
                            hideMinMax: true

                        }
                    }}
                    arc={{
                        colorArray: getColorGiveaway(),
                        subArcs: getSubArcs(),
                        padding: 0.02,
                        width: 0.3
                    }}
                    pointer={{
                        elastic: true,
                        animationDelay: 0,
                        color: "#c2c2c2",
                    }}
                />
            </Box>
        </>
    )

}
GaugeChart.propTypes = {}
export default GaugeChart


import { useRef, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { apiUrl } from '../../constants'
import moment from 'moment-timezone';
import axios from 'axios'
import db from "../../firebase-config"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import ColumnChartGiveaway from "../../compenents/chart/ColumnChartGiveaway";



const GiveawayLine9P2 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [chart_giveaway, setChart_giveaway] = useState([]);
    const [no_data, setNo_data] = useState(false);
    const [no_data_p2, setNo_data_p2] = useState(false);
    const [giveawayP1, setGiveawayP1] = useState({
        WARNING: "",
        PACK_SIZE_STD: "",
        SETTING_PACK_SIZE: ""
    });
    const [giveawayP2, setGiveawayP2] = useState({
        ACTUAL_PERCENT_GIVEAWAY: "",
        TARGET_GIVEAWAY: "",
        MIN_GIVEAWAY: ""
    });




    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_giveaway_5_min_timeframe")
            //.where("TIMESTAMP", ">=", timestr_chart)
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setChart_giveaway(getPostFromFirebase);
            });

        // return cleanup function
        return () => subscriber();
    }, [chart_giveaway]);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_LINE9_PAGE1}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        setGiveawayP1({
                            WARNING: response.data[0].WARNING,
                            SETTING_PACK_SIZE: response.data[0].SETTING_PACK_SIZE,
                            PACK_SIZE_STD: response.data[0].PACK_SIZE_STD,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_LINE9_PAGE2}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        setGiveawayP2({
                            ACTUAL_PERCENT_GIVEAWAY: response.data[0].ACTUAL_PERCENT_GIVEAWAY,
                            TARGET_GIVEAWAY: response.data[0].TARGET_GIVEAWAY,
                            MIN_GIVEAWAY: response.data[0].MIN_GIVEAWAY,
                        })
                        setNo_data_p2(1)

                    }
                })
                .catch(error => {
                    setNo_data_p2(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);

    function groupBy(chart_giveaway, property) {
        return chart_giveaway.reduce(function (memo, x) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    //group by name
    const grouped = groupBy(chart_giveaway, "TIMESTAMP");
    const keys = Object.keys(grouped);
    var output = [];

    //loop keys
    keys.forEach(key => {
        //merge using reduce
        const out = grouped[key].reduce((current) => {
            return {
                GIVEAWAY_PERCENT: current.GIVEAWAY_PERCENT,
                TIMESTAMP: current.TIMESTAMP,

            }
        });
        output.push(out);
    });


    const time_giveaway = output.map(item =>
        moment(new Date(item.TIMESTAMP)).tz('Asia/Bangkok').format('HH:mm')
    )

    const value_giveaway = output.map(i => i.GIVEAWAY_PERCENT == null ? 0 : i.GIVEAWAY_PERCENT);

    const show_value = value_giveaway == 0 ? [0] : value_giveaway;

    const show_time = time_giveaway == 0 ? [0] : time_giveaway;



    return (
        <>
            <Helmet>
                <title>Giveaway Line9 Page2 | MES</title>
            </Helmet>
            <Grid item xs={12} sm={12} style={{ backgroundColor: colors.neutral['black'], paddingTop: 0, paddingLeft: 20}}>
                <Grid item xs={12} textAlign="left" style={{ paddingRight: 0 }}>
                    <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold' }} >
                        Realtime Giveaway (%)
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid item xs={8} >
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{height: '30vh',position: "relative", top: '-9vw'}}>
                                <ColumnChartGiveaway
                                    value={show_value}
                                    time={show_time}
                                    setting={no_data == 1 ? Number(giveawayP1.SETTING_PACK_SIZE) : 0}
                                    std={no_data == 1 ? Number(giveawayP1.PACK_SIZE_STD) : 0}
                                    target={no_data_p2 == 1 ? (giveawayP2.TARGET_GIVEAWAY == undefined ? 0 : (giveawayP2.TARGET_GIVEAWAY == 'None' ? 0 : giveawayP2.TARGET_GIVEAWAY)) : 0}
                                    height='300%'
                                    fontsize={14}
                                />

                            </Grid>
                        </Grid>

                        <Grid item xs={4} style={{ paddingTop: 0 }}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: colors.neutral['white'], fontSize: 22, fontWeight: 'bold' }} >
                                    Best Practice
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: '#4AC6B1', fontSize: 32, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data_p2 == 1 ? (giveawayP2.MIN_GIVEAWAY == undefined ? '-' : (giveawayP2.MIN_GIVEAWAY == 'None' ? '-' : Number(giveawayP2.MIN_GIVEAWAY))) : '-'}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 25 }}>
                                <Typography style={{ color: colors.neutral['white'], fontSize: 22, fontWeight: 'bold' }} >
                                    Target
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: '#4AC6B1', fontSize: 32, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data_p2 == 1 ? (giveawayP2.TARGET_GIVEAWAY == undefined ? '-' : (giveawayP2.TARGET_GIVEAWAY == 'None' ? '-' : Number(giveawayP2.TARGET_GIVEAWAY))) : '-'}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 30 }} >
                                <Typography style={{ color: colors.neutral['white'], fontSize: 22, fontWeight: 'bold' }} >
                                    Actual
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: '#4AC6B1', fontSize: 32, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data_p2 == 1 ? (giveawayP2.ACTUAL_PERCENT_GIVEAWAY == undefined ? '-' : (giveawayP2.ACTUAL_PERCENT_GIVEAWAY == 'None' ? '-' : Number(giveawayP2.ACTUAL_PERCENT_GIVEAWAY))) : '-'}%
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default GiveawayLine9P2

import * as React from 'react';
import { Box, Grid, Typography } from "@mui/material";




function GridToSettingPlant(props) {
    return (


        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
            <Grid item xs={6} textAlign="right" style={{ paddingRight: 0, paddingLeft: 0 }}>
                {props.icon}
            </Grid>
            <Grid item xs={6} textAlign="center" style={{ paddingRight: 0, paddingLeft: 10 }}>
                <Typography style={{ color: '#000000', fontSize: 28, position: "relative", paddingLeft: 0, top: "0vw" }} textAlign="left">
                    {props.title}
                </Typography>
                <Typography style={{ color: '#c2c2c2', fontSize: 20, lineHeight: "2vh" }} textAlign="left">
                    {props.detail}
                </Typography>
            </Grid>
        </Grid>

    )
}


export default GridToSettingPlant
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import jwt from "jwt-decode";
import { setState } from "../redux/authSlice";
import { apiUrl } from "../constants";

const useExchangeToken = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const access_token = useSelector((state) => state.auth.access_token);
  const rb_ac_exp = useSelector((state) => state.auth.rb_ac_exp);

  const exchangeToken = async () => {
    // ถ้ามี access_token และยังไม่หมดอายุ คืนค่า access_token เดิม
    if (access_token && rb_ac_exp && Math.floor(Date.now() / 1000) < rb_ac_exp) {
      console.log("Access token is still valid. Returning existing token.");
      return access_token;
    }

    // ถ้า access_token หมดอายุ หรือไม่มี ให้แลกเปลี่ยน
    if (!access_token || access_token === '' || Math.floor(Date.now() / 1000) >= rb_ac_exp) {
      console.log("Access token expired or not found. Exchanging for a new one.");
      const data = { access_token: accessToken };
      try {
        const response = await axios.post(`${apiUrl.EXCHANGE_TOKEN_URL}`, data);
        if (response.status === 200) {
          const { access, refresh } = response.data.tokens;
          const access_user = jwt(access);
          const refresh_user = jwt(refresh);

          // อัปเดต token ใน Redux state
          dispatch(
            setState({
              access_token: access,
              refresh_token: refresh,
              rb_ac_exp: access_user.exp,
              rb_rf_exp: refresh_user.exp,
            })
          );

          console.log("Tokens updated successfully in Redux.");
          return access; // คืนค่า access token ใหม่
        } else {
          console.error("Failed to get new tokens. Please try again.");
          return null;
        }
      } catch (error) {
        console.error("Error exchanging token:", error);
        return null;
      }
    }
  };

  return exchangeToken;
};


export default useExchangeToken;

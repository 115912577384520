import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { AxpKit } from '@axons-library/portal-js-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { setState, setKit } from '../redux/authSlice';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.auth);

  const onAuthStateChanged = useCallback(async (authState) => {
    const activeSession = authState.sessions?.find((s) => s.sessionKey === authState.activeSessionKey);
    dispatch(setState({
      ...state,
      activeSession: activeSession || null,
      sessionKey: activeSession?.sessionKey || '',
      display: activeSession?.displayName || '',
      accessToken: activeSession?.accessToken || '',
      refreshToken: activeSession?.refreshToken || '',
      loading: false,
      isLogin: activeSession?.state === 'valid',
    }));

    if (!activeSession || activeSession.state !== 'valid') {
      console.log("No valid session, redirecting to login...");
      if (state.kit && activeSession?.sessionKey) {
        state.kit.clearToken(activeSession.sessionKey);
      }
      if (!state.isLogin) {  // ป้องกัน loop การ redirect ซ้ำ
        loginWithRedirect();
      }
    }
  }, [state.kit]);

  const init = useCallback(async () => {
    const appId = 'mes-prod';
    const baseUrl = 'https://api.axonscloud.com';
    // const appId = 'mes-uat';
    // const baseUrl = 'https://api.uat.axonscloud.com';
    const kit = AxpKit.load(appId, { baseUrl, storage: 'localStorage', defaultTenant: 'cpf' });
    kit.onActiveAuthStateChanged(onAuthStateChanged);
    await kit.init().catch(console.error.bind(console));
    dispatch(setKit(kit));
  }, [onAuthStateChanged]);

  const loginWithRedirect = async () => {
    let currentUrl = (window.location && window.location.href) || undefined;
    state.kit?.loginWithRedirectUrl(currentUrl);
  };

  const logout = async () => {
    const kit = state.kit;
    kit?.logout(kit.getCurrentTenantUrl());
  };

  const silentLogin = async (sessionKey) => {
    dispatch(setState({ loading: true }));
    try {
      await state.kit?.ssoSilent(sessionKey);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setState({ loading: false }));
    }
  };

  const fetchMyApps = useCallback(async () => {
    dispatch(setState({ fetching: true }));
    try {
      const resp = await state.kit?.listApplications();
      if (resp) {
        const applications = resp.applications || [];
        dispatch(setState({ myApps: applications }));
      }
    } catch (error) {
      console.log('[AuthStore] - fetchMyApps:', error);
    } finally {
      dispatch(setState({ fetching: false }));
    }
  }, [state.kit]);

  const refresh = async () => {
      dispatch(setState({ isRefreshing: true }));
    try {
      await state.kit?.refresh();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setState({ isRefreshing: false }));
    }
  };

  useEffect(() => {
    init();
  }, [init]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        init,
        loginWithRedirect,
        logout,
        silentLogin,
        fetchMyApps,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
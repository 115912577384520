import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import moment from 'moment-timezone';
import axios from 'axios'
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import HeaderDetail from '../components/header/HeaderDetail';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Time from "../assets/img/fast-time.png";
import Fighting from "../assets/img/first1.gif";
import Running from "../assets/img/running.gif";
import BarChartProductionTracking from '../components/chart/BarChartProductionTracking';
import Pin from "../assets/img/pin_light.png";
import Target from "../assets/img/svg/target.svg"
import Run from '../assets/img/running_png.png';
import Fist from '../assets/img/protest_png.png';
import db from "../firebase-config"
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";
import { useParams } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#bcd6d0',
    padding: theme.spacing(0),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    //borderRadius: 10,
    width: '101.5%',
    position: "relative",
    left: '-1.5vw'

}));

const ItemTarget = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(3),
    textAlign: 'center',
    border: "3px solid #4a4a49",
    borderRadius: 10,
    //height: '102%'

}));

const ItemBackground = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(1),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    borderRadius: 0,
    width: '101.5%',
    position: "relative",
    left: '-1.5vw'

}));


const PerformanceDetailPage2 = () => {
    const { bu, plant, factory, line } = useParams();
    const exchangeToken = useExchangeToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [no_data, setNo_data] = useState(false);
    const [performanceP2, setPerformanceP2] = useState({
        WARNING: "",
        LINE: "",
        SKU: "",
        FACTORY_DATE: "",
        SHIFT: "",
        TARGET: "",
        TARGET_UNIT: "",
        ACTUAL_WEIGHT: "",
        ACTUAL_WEIGHT_PERCENT: "",
        REMAINING: "",
        REMAINING_PERCENT: "",
        ACTUAL_FEED_RATE: "",
        STD_FEED_RATE: "",
        TOTAL_RUNTIME: "",
        PRODUCTION_TIME: "",
        NON_PRODUCTION_TIME: "",
        START_DATETIME: "",
        FINISH_DATETIME: "",
        FORECAST_FINISH_PRODUCTION_TIME: "",
        PRODUCTIVITY_TARGET_NOW: "",
        PRODUCTIVITY_TARGET_NOW_PERCENT: "",
        FORECAST_TOTAL_PRODUCTION: "",
        OPPORTUNITY_COST_OF_PRODUCTION: "",
        PRODUCTION_EFFICIENCY: ""
    })

    const [taglist, setTaglist] = useState([]);
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);
    const [error, setError] = useState(null);

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);

    useEffect(() => {
        const fetchInitialTokenAndData = async () => {
            try {
                const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
                if (token) {
                    setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
                }
            } catch (err) {
                console.error("Error exchanging token:", err);
                setError("Failed to exchange token");
            }
        };

        fetchInitialTokenAndData();
    }, [exchangeToken]);

    const api_performance = () => {
        try {
            // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
            const buName = bu;
            const plantName = plant;
            const factoryName = factory;
            const lineName = line;

            // ตรวจสอบเงื่อนไขโดยใช้ object mapping
            const apiMapping = {
                "แปรรูปไก่_4117_FPP2_Line7": apiUrl.PERFORMANCE_PAGE2,
                "แปรรูปไก่_4117_FPP2_Line8": apiUrl.PERFORMANCE_LINE8_PAGE2,
                "แปรรูปไก่_4117_FPP2_Line9": apiUrl.PERFORMANCE_LINE9_PAGE2,
                "แปรรูปไก่_4117_FPP2_Line10": apiUrl.PERFORMANCE_LINE10_PAGE2,
            };

            // สร้าง key สำหรับจับคู่
            const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

            // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
            return apiMapping[key];
        } catch (error) {
            console.error("Error in api_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };

    const line_performance = () => {
        try {
            // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
            const buName = bu;
            const plantName = plant;
            const factoryName = factory;
            const lineName = line;

            // ตรวจสอบเงื่อนไขโดยใช้ object mapping
            const lineMapping = {
                "แปรรูปไก่_4117_FPP2_Line7": 'Line7',
                "แปรรูปไก่_4117_FPP2_Line8": 'Line8',
                "แปรรูปไก่_4117_FPP2_Line9": 'Line9',
                "แปรรูปไก่_4117_FPP2_Line10": 'Line10',
            };

            // สร้าง key สำหรับจับคู่
            const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

            // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
            return lineMapping[key];
        } catch (error) {
            console.error("Error in line_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };

    const line_realtime = () => {
        try {
            // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
            const buName = bu;
            const plantName = plant;
            const factoryName = factory;
            const lineName = line;

            // ตรวจสอบเงื่อนไขโดยใช้ object mapping
            const lineMapping = {
                "แปรรูปไก่_4117_FPP2_Line7": 'Realtime',
                "แปรรูปไก่_4117_FPP2_Line8": 'Last Bin',
                "แปรรูปไก่_4117_FPP2_Line9": 'Last Bin',
                "แปรรูปไก่_4117_FPP2_Line10": 'Last Bin',
            };

            // สร้าง key สำหรับจับคู่
            const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

            // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
            return lineMapping[key];
        } catch (error) {
            console.error("Error in line_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };


    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${api_performance()}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    if (response.status == 200) {
                        setPerformanceP2({
                            WARNING: response.data.WARNING,
                            LINE: response.data.LINE,
                            SKU: response.data.SKU,
                            FACTORY_DATE: response.data.FACTORY_DATE,
                            SHIFT: response.data.SHIFT,
                            TARGET: response.data.TARGET,
                            TARGET_UNIT: response.data.TARGET_UNIT,
                            ACTUAL_WEIGHT: response.data.ACTUAL_WEIGHT,
                            ACTUAL_WEIGHT_PERCENT: response.data.ACTUAL_WEIGHT_PERCENT,
                            REMAINING: response.data.REMAINING,
                            REMAINING_PERCENT: response.data.REMAINING_PERCENT,
                            ACTUAL_FEED_RATE: response.data.ACTUAL_FEED_RATE,
                            STD_FEED_RATE: response.data.STD_FEED_RATE,
                            TOTAL_RUNTIME: response.data.TOTAL_RUNTIME,
                            PRODUCTION_TIME: response.data.PRODUCTION_TIME,
                            NON_PRODUCTION_TIME: response.data.NON_PRODUCTION_TIME,
                            START_DATETIME: response.data.START_DATETIME,
                            FINISH_DATETIME: response.data.FINISH_DATETIME,
                            FORECAST_FINISH_PRODUCTION_TIME: response.data.FORECAST_FINISH_PRODUCTION_TIME,
                            PRODUCTIVITY_TARGET_NOW: response.data.PRODUCTIVITY_TARGET_NOW,
                            PRODUCTIVITY_TARGET_NOW_PERCENT: response.data.PRODUCTIVITY_TARGET_NOW_PERCENT,
                            FORECAST_TOTAL_PRODUCTION: response.data.FORECAST_TOTAL_PRODUCTION,
                            OPPORTUNITY_COST_OF_PRODUCTION: response.data.OPPORTUNITY_COST_OF_PRODUCTION,
                            PRODUCTION_EFFICIENCY: response.data.PRODUCTION_EFFICIENCY
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }

    }, []);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_tag_list")
            .where("TAG", "==", "ACCUMURATE_WEIGHT")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setTaglist(getPostFromFirebase);
            });

        // return cleanup function
        return () => subscriber();
    }, [taglist]);


    var accumurate_weight = taglist.find((user) => user.TAG.includes("ACCUMURATE_WEIGHT"));


    ///// split time non production time

    var non_production_time = performanceP2.NON_PRODUCTION_TIME != 'NA' ? (performanceP2.NON_PRODUCTION_TIME).split(":") : "00:00"

    var non_production_hour = Number(non_production_time[0])

    var non_production_minute = Number(non_production_time[1])

    ///// split time production time

    var production_time = performanceP2.PRODUCTION_TIME != 'NA' ? (performanceP2.PRODUCTION_TIME).split(":") : "00:00"

    var production_hour = Number(production_time[0])

    var production_minute = Number(production_time[1])


    const getChartColor = () => {
        let color;
        try {
            if ((performanceP2.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP2.ACTUAL_WEIGHT) >= ((((production_hour + (production_minute / 60)) + (non_production_hour + (non_production_minute / 60))) * (performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP2.STD_FEED_RATE)) * 1)) {
                color = "#2e9281"
            } else if ((performanceP2.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP2.ACTUAL_WEIGHT) >= ((((production_hour + (production_minute / 60)) + (non_production_hour + (non_production_minute / 60))) * (performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP2.STD_FEED_RATE)) * 0.8)) {
                color = "#b58514"
            } else if ((performanceP2.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP2.ACTUAL_WEIGHT) < ((((production_hour + (production_minute / 60)) + (non_production_hour + (non_production_minute / 60))) * (performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP2.STD_FEED_RATE)) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getFeedrateColor = () => {
        let color;
        try {
            if ((performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) >= (((performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP2.STD_FEED_RATE) * 1)) {
                color = "#2e9281"
            } else if ((performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) >= (((performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP2.STD_FEED_RATE) * 0.8)) {
                color = "#b58514"
            } else if ((performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) < (((performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP2.STD_FEED_RATE) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getForecastColor = () => {
        let color;
        try {
            if ((performanceP2.FORECAST_TOTAL_PRODUCTION == 'NA' ? 0 : performanceP2.FORECAST_TOTAL_PRODUCTION) >= (performanceP2.TARGET == 'NA' ? 0 : performanceP2.TARGET)) {
                color = "#2e9281"
            } else if ((performanceP2.FORECAST_TOTAL_PRODUCTION == 'NA' ? 0 : performanceP2.FORECAST_TOTAL_PRODUCTION) < (performanceP2.TARGET == 'NA' ? 0 : performanceP2.TARGET)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }



    return (
        <>
            <Helmet>
                <title>Performance Page2 | MES</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <HeaderDetail
                        // line={no_data === 1 ? (performanceP2.LINE == 'NA' ? 'Line7' : performanceP2.LINE) : 'Line7'}
                        line={line_performance()}
                        sku={no_data === 1 ? (performanceP2.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : performanceP2.WARNING == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? performanceP2.SKU : performanceP2.SKU) : '-'}
                        icon={performanceP2.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ height: '55px' }} />) : (<LightModeOutlinedIcon style={{ height: '55px' }} />)}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Item>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid item xs={2}>
                            <img src={Time} height="90px" style={{ position: "relative", top: "0vw" }} />
                        </Grid>

                        <Grid item xs={2} >
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 32, lineHeight: "7vh" }} >
                                Start
                            </Typography>
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                {no_data === 1 ? (performanceP2.START_DATETIME == 'NA' ? '-' : moment(performanceP2.START_DATETIME).format('HH:mm')) : '00:00'}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} >
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 32, lineHeight: "7vh" }} >
                                End
                            </Typography>
                            <Typography style={{ color: performanceP2.FINISH_DATETIME == 'NA' ? "#636363" : "#000000", fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                {no_data === 1 ? (performanceP2.FINISH_DATETIME == 'NA' ? performanceP2.FORECAST_FINISH_PRODUCTION_TIME == 'NA' || performanceP2.FORECAST_FINISH_PRODUCTION_TIME == null ? '-' : moment(performanceP2.FORECAST_FINISH_PRODUCTION_TIME).format('HH:mm') : moment(performanceP2.FINISH_DATETIME).format('HH:mm')) : "00:00"}
                            </Typography>
                        </Grid>

                        <Grid item xs={0.1} style={{ borderLeft: "3px dashed #3cbea8", height: '10vh', position: "relative", top: '0.5vw' }} >

                        </Grid>


                        <Grid item xs={2.7}>
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 32, lineHeight: "7vh" }} >
                                Production
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                    {no_data === 1 ? (performanceP2.PRODUCTION_TIME == 'NA' ? "-" : production_hour) : 0}
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 35, lineHeight: "3vh", paddingTop: 5 }} >
                                    hr
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                    {no_data === 1 ? (performanceP2.PRODUCTION_TIME == 'NA' ? "-" : production_minute) : 0}
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 35, lineHeight: "3vh", paddingTop: 5 }} >
                                    min
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={0.1} style={{ borderLeft: "3px dashed #3cbea8", height: '10vh', position: "relative", top: '0.5vw' }} >

                        </Grid>

                        <Grid item xs={2.7}>
                            <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 32, lineHeight: "7vh" }} >
                                Non-Production
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                    {no_data === 1 ? (performanceP2.NON_PRODUCTION_TIME == 'NA' ? '-' : non_production_hour) : 0}
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 35, lineHeight: "3vh", paddingTop: 5 }} >
                                    hr
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 45, lineHeight: "3vh" }} >
                                    {no_data === 1 ? (performanceP2.NON_PRODUCTION_TIME == 'NA' ? '-' : non_production_minute) : 0}
                                </Typography>
                                <Typography style={{ color: colors.neutral['black'], fontWeight: "bold", fontSize: 35, lineHeight: "3vh", paddingTop: 5 }} >
                                    min
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Item>
            </Grid>

            <Grid item xs={12} sm={12}>
                <ItemBackground>
                    <Grid item xs={12} sm={12} style={{ paddingTop: 5, paddingRight: 20, paddingLeft: 20 }}>
                        <ItemTarget>
                            <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
                                <Grid item xs={7} style={{ paddingLeft: 50 }}>
                                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                                        <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
                                            <Grid item xs={6}>
                                                <table>
                                                    <tbody>
                                                        <tr >
                                                            <td style={{ width: '30%', fontSize: 14, fontWeight: 'bold,' }} align="right">
                                                                {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Fighting} height="70vh" /> : <img src={Fist} height="70vh" />) : <img src={Fist} height="70vh" />}
                                                            </td>
                                                            <td style={{ width: '20%', color: no_data == 1 ? getChartColor() : colors.neutral['green'], fontSize: 50, fontWeight: 'bold' }} align="right">
                                                                {no_data === 1 ? performanceP2.ACTUAL_WEIGHT_PERCENT : 0}
                                                            </td>
                                                            <td style={{ width: '20%', color: no_data == 1 ? getChartColor() : colors.neutral['green'], fontSize: 45, fontWeight: 'bold' }} align="left">
                                                                %
                                                            </td>
                                                        </tr>

                                                        <tr >
                                                            <td style={{ width: '30%', color: colors.neutral['white'], fontSize: 45, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                Actual
                                                            </td>
                                                            <td style={{ width: '20%', color: no_data == 1 ? getChartColor() : colors.neutral['green'], fontSize: 60, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                {no_data === 1 ? (performanceP2.ACTUAL_WEIGHT).toLocaleString() : 0}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['white'], fontSize: 30, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 11 }} align="left">
                                                                kg
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <table>
                                                    <tbody>
                                                        <tr >
                                                            <td style={{ width: '30%', fontSize: 14, fontWeight: 'bold,' }} align="right">
                                                                {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Running} height="70vh" /> : <img src={Run} height="70vh" />) : <img src={Run} height="70vh" />}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['grey'], fontSize: 50, fontWeight: 'bold' }} align="right">
                                                                {no_data === 1 ? (performanceP2.ACTUAL_WEIGHT_PERCENT === 'NA' ? 'NA' : (100 - performanceP2.ACTUAL_WEIGHT_PERCENT <= 0 ? 0 : 100 - performanceP2.ACTUAL_WEIGHT_PERCENT)) : 0}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['grey'], fontSize: 45, fontWeight: 'bold' }} align="left">
                                                                %
                                                            </td>
                                                        </tr>

                                                        <tr >
                                                            <td style={{ width: '30%', color: colors.neutral['white'], fontSize: 45, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                Diff.
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['grey'], fontSize: 60, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                {no_data === 1 ? (performanceP2.REMAINING).toLocaleString() : 0}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['grey'], fontSize: 30, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 11 }} align="left">
                                                                kg
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={9} textAlign="center" style={{ paddingTop: 30 }}>
                                        {(performanceP2.TARGET ===
                                            ''
                                            && performanceP2.ACTUAL_WEIGHT ===
                                            ''
                                            && performanceP2.PRODUCTIVITY_TARGET_NOW ===
                                            ''
                                            && performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT ===
                                            ''
                                        ) || (performanceP2.TARGET ===
                                            'NA'
                                            && performanceP2.ACTUAL_WEIGHT ===
                                            'NA'
                                            ) ?
                                            <BarChartProductionTracking
                                                target_quantity={0}
                                                actual_weight={0}
                                                target_now={0}
                                                persent={0}
                                                //chart_color={getChartColor()}
                                                position={40}
                                                fontsize={24}
                                                width_svg={18}
                                                height_svg={18}
                                                height='55%'
                                            />
                                            : <BarChartProductionTracking
                                                target_quantity={performanceP2.TARGET === 'NA' ? 0 : performanceP2.TARGET}
                                                actual_weight={performanceP2.ACTUAL_WEIGHT === 'NA' ? 0 : performanceP2.ACTUAL_WEIGHT}
                                                target_now={performanceP2.PRODUCTIVITY_TARGET_NOW === 'NA' ? 0 : performanceP2.PRODUCTIVITY_TARGET_NOW}
                                                persent={performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT === 'NA' ? 0 : performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT}
                                                chart_color={getChartColor()}
                                                position={performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT === 'NA' ? 0 : (performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT <= 50 ? 40 : -60)}
                                                fontsize={35}
                                                width_svg={30}
                                                height_svg={30}
                                                pin={Pin}
                                                height='90%'
                                                width='120%'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={5} style={{ paddingTop: 20 }}>
                                    <Grid item xs={12} textAlign="center">
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <img src={Target} height="70px" />
                                            <Typography style={{ color: colors.neutral['green'], fontWeight: "bold", fontSize: 60, paddingTop: 30 }}>
                                                &nbsp;Target
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} textAlign="center" >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['green'], fontSize: 70, fontWeight: 'bold', lineHeight: "4vh" }}>
                                                &nbsp;{no_data === 1 ? (performanceP2.TARGET).toLocaleString() : 0}
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['green'], fontSize: 40, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 9 }}>
                                                &nbsp;kg
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ItemTarget>
                    </Grid>
                </ItemBackground>
            </Grid>

            <Grid item xs={12} sm={12}>
                <ItemBackground>
                    <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
                        <Grid item xs={7} style={{ paddingLeft: 50 }}>
                            <Grid item xs={12} textAlign="center">
                                <Typography style={{ color: colors.neutral['white'], fontSize: 45 }} >
                                    Performance ({no_data === 1 ? (performanceP2.STD_FEED_RATE == "ไม่มีข้อมูล Std. Cap ในระบบ" ? (performanceP2.STD_FEED_RATE).toLocaleString() : "Std." + (performanceP2.STD_FEED_RATE).toLocaleString()) : "Std." + (0)})
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center" >
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: no_data == 1 ? getFeedrateColor() : colors.neutral['green'], fontSize: 60, fontWeight: 'bold', lineHeight: "4vh" }} >
                                        {no_data === 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 'NA' : (Math.round(performanceP2.PRODUCTION_EFFICIENCY)).toLocaleString()) : 0}
                                    </Typography>
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 30, paddingTop: 15, lineHeight: "4vh" }} >
                                        &nbsp;kg/hr
                                    </Typography>
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 50, paddingTop: 5, lineHeight: "4vh" }} >
                                        &nbsp;({line_realtime()} {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? (no_data == 1 ? (performanceP2.ACTUAL_FEED_RATE).toLocaleString() : 0) : 0) : 0})
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid item xs={12} textAlign="center" >
                                <Typography style={{ color: colors.neutral['white'], fontSize: 45 }}>
                                    Forecast
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center" style={{}}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 60, fontWeight: 'bold', lineHeight: "4vh" }} >
                                        {no_data === 1 ? (performanceP2.FORECAST_TOTAL_PRODUCTION).toLocaleString() : 0}
                                    </Typography>
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 30, fontWeight: 'bold', paddingTop: 15, lineHeight: "4vh" }} >
                                        &nbsp;kg
                                    </Typography>
                                    <Typography style={{ color: no_data == 1 ? getForecastColor() : colors.neutral['green'], fontWeight: "bold", fontSize: 40, paddingTop: 5, lineHeight: "4vh" }} >
                                        &nbsp;({no_data === 1 ? (performanceP2.OPPORTUNITY_COST_OF_PRODUCTION >= 0 ? "+" + (performanceP2.OPPORTUNITY_COST_OF_PRODUCTION).toLocaleString() : (performanceP2.OPPORTUNITY_COST_OF_PRODUCTION).toLocaleString()) : 0})
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </ItemBackground>
            </Grid>
        </>
    )
}

export default PerformanceDetailPage2

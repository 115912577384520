import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import db from "../firebase-config"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import HeaderDetail from '../components/header/HeaderDetail';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Target from "../assets/img/svg/target.svg"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import GrigToDetail from "../components/grid/GridToDetail";
import Fighting from "../assets/img/first1.gif";
import Running from "../assets/img/running.gif"
import Run from '../assets/img/running_png.png';
import Fist from '../assets/img/protest_png.png';
import { useParams } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#bcd6d0',
  padding: theme.spacing(0.8),
  textAlign: 'center',
  //border: '0.8px dashed #2e9281',
  //borderRadius: 10,
  width: '101.5%',
  position: "relative",
  left: '-1.5vw'

}));

const ItemTarget = styled(Paper)(({ theme }) => ({
  backgroundColor: '#000000',
  padding: theme.spacing(1),
  textAlign: 'center',
  //border: '0.8px dashed #2e9281',
  borderRadius: 0,
  width: '101.5%',
  position: "relative",
  left: '-1.5vw'

}));


const PerformanceLine8Page1 = () => {
  const { bu, plant, factory, line } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [performanceP1, setPerformanceP1] = useState([]);


  const line_performance = () => {
    try {
      // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
      const buName = bu;
      const plantName = plant;
      const factoryName = factory;
      const lineName = line;

      // ตรวจสอบเงื่อนไขโดยใช้ object mapping
      const lineMapping = {
        "แปรรูปไก่_4117_FPP2_Line7-v2": 'Line7',
        "แปรรูปไก่_4117_FPP2_Line8": 'Line8',
        "แปรรูปไก่_4117_FPP2_Line9": 'Line9',
        "แปรรูปไก่_4117_FPP2_Line10": 'Line10',
      };

      // สร้าง key สำหรับจับคู่
      const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

      // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
      return lineMapping[key];
    } catch (error) {
      console.error("Error in line_performance:", error);
      return null; // คืนค่า null หากเกิดข้อผิดพลาด
    }
  };

  const performance = () => {
    try {
      // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
      const buName = bu;
      const plantName = plant;
      const factoryName = factory;
      const lineName = line;

      // ตรวจสอบเงื่อนไขโดยใช้ object mapping
      const lineMapping = {
        "แปรรูปไก่_4117_FPP2_Line7-v2": 'LINE7',
        "แปรรูปไก่_4117_FPP2_Line8": 'LINE8',
        "แปรรูปไก่_4117_FPP2_Line9": 'LINE9',
        "แปรรูปไก่_4117_FPP2_Line10": 'LINE10',
      };

      // สร้าง key สำหรับจับคู่
      const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

      // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
      return lineMapping[key];
    } catch (error) {
      console.error("Error in line_performance:", error);
      return null; // คืนค่า null หากเกิดข้อผิดพลาด
    }
  };

  useEffect(() => {
    const docRef = db
      .collection("prod_mes_ignition_performance_page12_v2")
      .doc(`411700000000000000${performance()}ViaTumblerPerformance`);

    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        setPerformanceP1({ id: doc.id, ...doc.data() });
      } else {
        setPerformanceP1(null);
      }
    }, (error) => {
      console.error("Error fetching document:", error);
    });

    return () => unsubscribe();
  }, []);

  var isValidTimeFormat = (time) =>
    typeof time === "string" && /^\s*([01]?\d|2[0-3]):([0-5]\d):([0-5]\d)(\.\d+)?\s*$/.test(time);

  var extractHourMinute = (time) => time.split(":").slice(0, 2);

  ///// split time non production time
  var non_production_time_before =
    !performanceP1?.NON_PRODUCTION_TIME || performanceP1?.NON_PRODUCTION_TIME === 'NA'
      ? ["-1 day", "00:00:00"]
      : performanceP1?.NON_PRODUCTION_TIME.split(",").map(t => t.trim()).filter(Boolean);

  var latest_time = non_production_time_before.at(-1); // ใช้ค่าล่าสุดจากอาร์เรย์

  var non_production_time =
    isValidTimeFormat(latest_time) ? extractHourMinute(latest_time) : ["00", "00"];

  var non_production_hour = Number(non_production_time[0]);
  var non_production_minute = Number(non_production_time[1]);

  ///// split time  production time
  var production_time_before =
    !performanceP1?.PRODUCTION_TIME || performanceP1?.PRODUCTION_TIME === 'NA'
      ? ["-1 day", "00:00:00"]
      : (performanceP1?.PRODUCTION_TIME).split(",").map(t => t.trim()).filter(Boolean);

  var latest_production_time = production_time_before.at(-1); // ใช้ค่าล่าสุดจากอาร์เรย์

  var production_time =
    isValidTimeFormat(latest_production_time) ? extractHourMinute(latest_production_time) : ["00", "00"]

  var production_hour = Number(production_time[0])

  var production_minute = Number(production_time[1])



  const getChartColor = () => {
    let color;
    try {
      if ((performanceP1?.ACTUAL_WEIGHT == undefined || performanceP1?.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP1?.ACTUAL_WEIGHT) >= (((((performanceP1?.PRODUCTION_TIME == undefined ? 0 : production_hour) + ((performanceP1?.PRODUCTION_TIME == undefined ? 0 : production_minute) / 60)) + ((performanceP1?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_hour) + ((performanceP1?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_minute) / 60))) * (performanceP1?.STD_FEED_RATE == undefined || performanceP1?.STD_FEED_RATE == 'NA' || performanceP1?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1?.STD_FEED_RATE)) * 1)) {
        color = "#2e9281"
      } else if ((performanceP1?.ACTUAL_WEIGHT == undefined || performanceP1?.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP1?.ACTUAL_WEIGHT) >= (((((performanceP1?.PRODUCTION_TIME == undefined ? 0 : production_hour) + ((performanceP1?.PRODUCTION_TIME == undefined ? 0 : production_minute) / 60)) + ((performanceP1?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_hour) + ((performanceP1?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_minute) / 60))) * (performanceP1?.STD_FEED_RATE == undefined || performanceP1?.STD_FEED_RATE == 'NA' || performanceP1?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1?.STD_FEED_RATE)) * 0.8)) {
        color = "#b58514"
      } else if ((performanceP1?.ACTUAL_WEIGHT == undefined || performanceP1?.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP1?.ACTUAL_WEIGHT) < (((((performanceP1?.PRODUCTION_TIME == undefined ? 0 : production_hour) + ((performanceP1?.PRODUCTION_TIME == undefined ? 0 : production_minute) / 60)) + ((performanceP1?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_hour) + ((performanceP1?.NON_PRODUCTION_TIME == undefined ? 0 : non_production_minute) / 60))) * (performanceP1?.STD_FEED_RATE == undefined || performanceP1?.STD_FEED_RATE == 'NA' || performanceP1?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1?.STD_FEED_RATE)) * 0.8)) {
        color = "#7d2e1e"
      }
      return color;
    }
    catch {
      console.log()
    }
  }




  const getFeedrateColor = () => {
    let color;
    try {
      if ((performanceP1?.ACTUAL_FEED_RATE == undefined || performanceP1?.ACTUAL_FEED_RATE == 'NA' ? 0 : performanceP1?.ACTUAL_FEED_RATE) >= ((performanceP1?.STD_FEED_RATE == undefined || performanceP1?.STD_FEED_RATE == 'NA' || performanceP1?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1?.STD_FEED_RATE) * 1)) {
        color = "#2e9281"
      } else if ((performanceP1?.ACTUAL_FEED_RATE == undefined || performanceP1?.ACTUAL_FEED_RATE == 'NA' ? 0 : performanceP1?.ACTUAL_FEED_RATE) >= ((performanceP1?.STD_FEED_RATE == undefined || performanceP1?.STD_FEED_RATE == 'NA' || performanceP1?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1?.STD_FEED_RATE) * 0.8)) {
        color = "#b58514"
      } else if ((performanceP1?.ACTUAL_FEED_RATE == undefined || performanceP1?.ACTUAL_FEED_RATE == 'NA' ? 0 : performanceP1?.ACTUAL_FEED_RATE) < ((performanceP1?.STD_FEED_RATE == undefined || performanceP1?.STD_FEED_RATE == 'NA' || performanceP1?.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1?.STD_FEED_RATE) * 0.8)) {
        color = "#7d2e1e"
      }
      return color;
    }
    catch {
      console.log()
    }
  }








  return (
    <>
      <Helmet>
        <title>Performance Page1 | MES</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <HeaderDetail
            // line={no_data === 1 ? (performanceP1.LINE == 'NA' ? 'Line7' : performanceP1.LINE) : 'Line7'}
            line={line_performance()}
            sku={performanceP1?.SKU === undefined ? '-' : (performanceP1?.SKU == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : performanceP1?.SKU == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? performanceP1?.SKU : performanceP1?.SKU)}
            icon={performanceP1?.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ height: '55px' }} />) : (<LightModeOutlinedIcon style={{ height: '55px' }} />)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Item>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
            <Typography style={{ color: '#b58514', fontSize: 20, lineHeight: "17px", fontWeight: 'bold' }} >
              <img src={Target} height="120px" />
            </Typography>
            <Typography style={{ color: colors.neutral['green'], fontSize: 100, fontWeight: 'bold', lineHeight: "17px", paddingTop: 5 }} >
              &nbsp;เป้าหมาย&nbsp;
            </Typography>
            <Typography style={{ color: colors.neutral['green'], fontSize: 120, lineHeight: "17px", fontWeight: 'bold' }} >
              &nbsp;{performanceP1?.TARGET_WEIGHT_KG === undefined ? 0 : (performanceP1?.TARGET_WEIGHT_KG).toLocaleString()}&nbsp;
            </Typography>
            <Typography style={{ color: colors.neutral['green'], fontSize: 50, lineHeight: "17px", fontWeight: 'bold', paddingTop: 20 }} >
              กก.
            </Typography>
          </Box>
        </Item>

      </Grid>

      <Grid item xs={12} sm={12}>
        <ItemTarget>
          <Grid container rowSpacing={0.8} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
            <GrigToDetail
              color={performanceP1?.ACTUAL_WEIGHT == undefined ? colors.neutral['green'] : (performanceP1?.ACTUAL_WEIGHT === 'NA' ? colors.neutral['green'] : getChartColor())}
              image={performanceP1?.IS_RUNNING != undefined ? (performanceP1?.IS_RUNNING == 'YES' ? <img src={Fighting} height="100vh" /> : <img src={Fist} height="100vh" />) : <img src={Fist} height="100vh" />}
              section={'ทำได้'}
              persent={performanceP1?.PERCENT_ACTUAL_WEIGHT === undefined ? 0 : performanceP1?.PERCENT_ACTUAL_WEIGHT}
              color_persent={performanceP1?.ACTUAL_WEIGHT == undefined ? colors.neutral['green'] : (performanceP1?.ACTUAL_WEIGHT === 'NA' ? colors.neutral['green'] : getChartColor())}
              value={performanceP1?.ACTUAL_WEIGHT === undefined ? 0 : Math.round(performanceP1?.ACTUAL_WEIGHT).toLocaleString()}
            />

            <GrigToDetail
              color={'#4a4a49'}
              image={performanceP1?.IS_RUNNING != undefined ? (performanceP1?.IS_RUNNING == 'YES' ? <img src={Running} height="100vh" /> : <img src={Run} height="100vh" />) : <img src={Run} height="100vh" />}
              //width={no_data === 1 ? (performanceP1.ACTUAL_WEIGHT_PERCENT === 'NA' ? '20vh' : (100 - performanceP1.ACTUAL_WEIGHT_PERCENT < 100 ? '20vh' : '40vh')) : '20vh'}
              section={'คงเหลือ'}
              persent={performanceP1?.PERCENT_ACTUAL_WEIGHT === undefined ? 0 : (performanceP1?.PERCENT_ACTUAL_WEIGHT === 'NA' ? 'NA' : (100 - performanceP1?.PERCENT_ACTUAL_WEIGHT <= 0 ? 0 : 100 - performanceP1?.PERCENT_ACTUAL_WEIGHT))}
              color_persent={colors.secondary[800]}
              value={performanceP1?.REMAINING_WEIGHT === undefined ? 0 : (performanceP1?.REMAINING_WEIGHT == 'NA' ? 'NA' : (performanceP1?.REMAINING_WEIGHT <= 0 ? 0 : Math.round(performanceP1?.REMAINING_WEIGHT).toLocaleString()))}
            />
          </Grid>

          <Grid item xs={12} textAlign="left" style={{ paddingRight: 0, paddingLeft: 150 }}>
            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} textAlign="center">
              <Typography style={{ color: getFeedrateColor(), fontSize: 70, position: "relative", paddingLeft: 0, top: "0vw" }}>
                {performanceP1?.ACTUAL_FEED_RATE === undefined ? 0 : (performanceP1?.ACTUAL_FEED_RATE == 'NA' ? 'NA' : (Math.round(performanceP1?.ACTUAL_FEED_RATE)).toLocaleString())}
              </Typography>
              <Typography style={{ color: '#ffffff', fontSize: 50, paddingTop: 18 }}>
                &nbsp;กก./ชม.&nbsp;({performanceP1?.STD_FEED_RATE === undefined ? "Std." + 0 : (performanceP1?.STD_FEED_RATE == "ไม่มีข้อมูล Std. Cap ในระบบ" ? (performanceP1?.STD_FEED_RATE).toLocaleString() : "Std." + (performanceP1?.STD_FEED_RATE).toLocaleString())})
              </Typography>
            </Box>
          </Grid>
        </ItemTarget>
      </Grid>



    </>
  )
}

export default PerformanceLine8Page1

import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import CardPhoto from '../compenents/card/CardPhotoToUseLocation';
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import chickenKoratImg from "../assets/img/chickenKorat.jpg"
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";


const CardPhotoMemo = React.memo(CardPhoto);

const LineList = () => {
  const exchangeToken = useExchangeToken();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [line, setLine] = useState([]);
  const [isTokenExchanged, setIsTokenExchanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useCheckToken();
  const ac_token = useSelector(state => state.auth.access_token);

  function storeCourse(name) {
    localStorage.setItem('line', name)
  }

  const factoryList = [
    { id: 1, name: "Line 7", fileLocation: chickenKoratImg },
    { id: 2, name: "Line 8", fileLocation: chickenKoratImg },
    // {id: 3, name: "Line 9", fileLocation: chickenKoratImg},
  ]

  useEffect(() => {
    const fetchInitialTokenAndData = async () => {
      try {
        const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
        if (token) {
          setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
        }
      } catch (err) {
        console.error("Error exchanging token:", err);
        setError("Failed to exchange token");
      }
    };

    fetchInitialTokenAndData();
  }, [exchangeToken]);


  useEffect(() => {
    const fetchMyAPI = async () => {
      if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl.MASTER_PLANT}${localStorage.getItem("buName")}/${localStorage.getItem("plant")}/${localStorage.getItem("factory")}`, {
          headers: {
            Authorization: `Bearer ${ac_token}`,
          },
        });

        if (response.status === 200) {
          setLine(response.data.PLANT || [])
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching Line data:", err);
      } finally {
        setLoading(false);
      }
    };

    if (isTokenExchanged && ac_token) {
      fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
    }
  }, [isTokenExchanged, ac_token]);


  const lineData = useMemo(
    () =>
      line.length === 0
        ? []
        : Object.keys(line).map((key) => ({ key, name: line[key] })),
    [line]
  );


  return (
    <Box style={{ width: "100%", height: "90vh" }}>
      <Helmet>
        <title>Line List | MES</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            m='1%'
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="100%"
            gap="1%"
          >
            {/* ESTIMATED */}
            {lineData.map((item) => (
              <Box
                key={item.key || item.id}
                gridColumn="span 2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => storeCourse(item.name)}
              >
                <CardPhotoMemo name={item.name} fileLocation={item.fileLocation || chickenKoratImg} />
              </Box>
            ))
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LineList

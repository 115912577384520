import * as React from 'react';
import { Box, Grid, Typography} from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



function GrigToDetail(props) {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: props.color,
        padding: theme.spacing(0),
        textAlign: 'center',
        //border: '0.8px dashed #2e9281',
        borderRadius: 30,
        //height: '80%'

    }));

    return (

        <Grid item xs={6}  >
            <Grid item xs={12} textAlign="center" style={{ paddingRight: 150, paddingLeft: 120 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} style={{ position: "relative", top: "0vw" }} textAlign="center">
                    <Typography >
                        {props.image}
                    </Typography>
                    <Typography style={{ color: "#ffffff", fontSize: 70, position: "relative", top: '2vw'}}>
                    &nbsp;{props.section}
                    </Typography>
                    <Typography style={{ color: props.color_persent, fontSize: 90, position: "relative", paddingLeft: 0, top: "1.5vw" }}>
                    &nbsp;{props.persent}%
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} textAlign="center" style={{ paddingRight: 150, paddingLeft: 150 }}>
                <Item>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} style={{ position: "relative", top: "0vw" }} textAlign="center">
                        <Typography style={{ color: '#ffffff', fontSize: 120, position: "relative", paddingLeft: 0, top: "0vw" }}>
                            {props.value}
                        </Typography>
                        <Typography style={{ color: '#ffffff', fontSize: 50, position: "relative", paddingTop: 30, top: "0vw" }}>
                        &nbsp;กก.
                        </Typography>
                    </Box>
                </Item>
            </Grid>
        </Grid>
    )
}


export default GrigToDetail
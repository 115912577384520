import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesConfig from './RoutesConfig';
import AuthGuard from './components/auth/AuthGuard';
import AuthStateTransfer from './components/auth/AuthStateTransfer'
import { useDispatch, useSelector } from "react-redux";


const App = () => {
  //const { isLogin } = useAuth(); // ใช้ useAuth ในการเช็คสถานะการเข้าสู่ระบบ

  return (
    // <Router>
    //   <div>
    //     <AuthStateTransfer />
    //     <div className="h-screen bg-default-50">
    //       <div className="page-content container mx-auto h-full">
    //         <AuthGuard>
    //           <RoutesConfig loggedIn={isLogin} />
    //           {/* <RoutesConfig loggedIn={useSelector((state) => state.auth.isLogin)} /> */}
    //         </AuthGuard>
    //       </div>
    //     </div>
    //   </div>
    // </Router>
    <Router>
      <div>
        <AuthStateTransfer />
        <div className="h-screen bg-default-50">
          <div className="page-content container mx-auto h-full">
            <AuthGuard>
              <RoutesConfig loggedIn={!!useSelector((state) => state.auth.sessionKey)} />
              {/* <RoutesConfig loggedIn={!!localStorage.getItem('sessionKey')} /> */}
            </AuthGuard>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setState } from '../redux/authSlice';
import { apiUrl } from '../constants';

const useCheckToken = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.auth.access_token);
  const refreshToken = useSelector(state => state.auth.refresh_token);
  const rb_ac_exp = useSelector(state => state.auth.rb_ac_exp);
  const rb_rf_exp = useSelector(state => state.auth.rb_ac_exp);

  useEffect(() => {
    const checkToken = async () => {
      try {
        if (rb_rf_exp && rb_rf_exp < Date.now() / 1000) {
          console.log("Refresh token has expired. Redirecting to login.");
          dispatch(setState({
            access_token: '',
            refresh_token: '',
            rb_ac_exp: '',
            rb_rf_exp: '',
            isLogin: false,
          }));
          window.location.href = 'https://cpf.axonscloud.com/login';
          localStorage.clear()
        } else if (rb_ac_exp && rb_ac_exp < Date.now() / 1000) {
          const response = await axios.post(`${apiUrl.REFRESH_TOKEN_URL}`, { refresh: refreshToken });
          dispatch(setState({ access_token: response.data.access }));
        }
      } catch (error) {
        console.error("Failed to check or refresh token", error);
        window.location.href = 'https://cpf.axonscloud.com/login';
        localStorage.clear()
      }
    };

    checkToken();
  }, [accessToken, refreshToken, dispatch]);
};


export default useCheckToken;

import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { apiUrl } from "../constants";
import axios from "axios";
import CardPhoto from "../compenents/card/CardPhotoToPath";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import chickenImg from "../assets/img/chickenTender.png";
import { useSelector } from "react-redux";
import useCheckToken from "../hooks/useCheckToken";
import useExchangeToken from "../hooks/useExchangeToken";

const CardPhotoMemo = React.memo(CardPhoto);

const BUList = () => {
  const exchangeToken = useExchangeToken();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isTokenExchanged, setIsTokenExchanged] = useState(false);
  const [bu, setBu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const buList = [
    { id: 1, name: "แปรรูปไก่", fileLocation: chickenImg },
  ];

  useCheckToken();
  const ac_token = useSelector((state) => state.auth.access_token);

  //console.log(isTokenExchanged)

  useEffect(() => {
    const fetchInitialTokenAndData = async () => {
      try {
        const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
        if (token) {
          setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
        }
      } catch (err) {
        console.error("Error exchanging token:", err);
        setError("Failed to exchange token");
      }
    };

    fetchInitialTokenAndData();
  }, [exchangeToken]);

  useEffect(() => {
    const fetchMyAPI = async () => {
      if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl.MASTER_BU}`, {
          headers: {
            Authorization: `Bearer ${ac_token}`,
          },
        });

        if (response.status === 200) {
          setBu(response.data.BU);
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching BU data:", err);
      } finally {
        setLoading(false);
      }
    };

    if (isTokenExchanged && ac_token) {
      fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
    }
  }, [isTokenExchanged, ac_token]);



  const buData = useMemo(
    () =>
      bu.length === 0
        ? []
        : Object.keys(bu).map((key) => ({ key, name: bu[key] })),
    [bu, buList]
  );

  // แสดง Loading หรือ Error
  // if (loading) {
  //   return <Typography>Loading...</Typography>;
  // }

  // if (error) {
  //   return <Typography color="error">Error: {error}</Typography>;
  // }

  return (
    <Box style={{ width: "100%", height: "93.5vh" }}>
      <Helmet>
        <title>BU | MES</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            m="1%"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="100%"
            gap="1%"
          >
            {buData.map((item) => (
              <Box
                key={item.key || item.id}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CardPhotoMemo
                  name={item.name}
                  fileLocation={item.fileLocation || chickenImg}
                />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BUList;


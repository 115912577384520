export const apiUrl = {
REFRESH_TOKEN_URL: `https://immjai-backendapi-ecs.cpfmes.com/account/token/refresh/`,
EXCHANGE_TOKEN_URL:`https://immjai-backendapi-ecs.cpfmes.com/account/exchange-token/`,
GET_TUMBLER_BETWEEN_2DAY: `https://immjai-backendapi-ecs.cpfmes.com/api/tumbler/4117/00000000000000/`,
GET_TUMBLER_CHECK_STATUS: `https://immjai-backendapi-ecs.cpfmes.com/api/tumbler/4117/00000000000000/`,
POST_INSERT_TUMBLER: `https://immjai-backendapi-ecs.cpfmes.com/api/tumbler/`,
PUT_UPDATE_TUMBLER: `https://immjai-backendapi-ecs.cpfmes.com/api/tumbler-update/`,
MASTER_BU: `https://immjai-backendapi-ecs.cpfmes.com/api/master-plant/bu`,
MASTER_PLANT: `https://immjai-backendapi-ecs.cpfmes.com/api/master-plant/`,
USER: `https://immjai-backendapi-ecs.cpfmes.com/account/user/`,
REGISTER: `https://immjai-backendapi-ecs.cpfmes.com/account/register-user/`,
MASTER_PLANT_STD: `https://immjai-backendapi-ecs.cpfmes.com/api/model-master-plant/`,
EDIT_MASTER_PLANT: `https://immjai-backendapi-ecs.cpfmes.com/api/master-plant/`,
GET_MASTER_STD: `https://immjai-backendapi-ecs.cpfmes.com/api/master-std/`,
POST_MASTER_STD: `https://immjai-backendapi-ecs.cpfmes.com/api/model-master-std/`,
UNLOCK_GIVEAWAY: `https://immjai-backendapi-ecs.cpfmes.com/api/function/giveaway/`,
GET_TUMBLER_STATUS: `https://immjai-backend-api.cpfmes.com/line-performance/v2/status/4117/00000000000000/`,
PUT_TUMBLER_STATUS: `https://immjai-backend-api.cpfmes.com/line-performance/v2/edit-status`,

PERFORMANCE_PAGE1: `https://t3ttp2x2r9.execute-api.ap-southeast-1.amazonaws.com/prd/4117/fur2/api/line-monitoring/page1/line7`,
PERFORMANCE_PAGE2: `https://t3ttp2x2r9.execute-api.ap-southeast-1.amazonaws.com/prd/4117/fur2/api/line-monitoring/page2/line7`,
PERFORMANCE_LINE8_PAGE1: `https://t3ttp2x2r9.execute-api.ap-southeast-1.amazonaws.com/prd/4117/fur2/api/line-monitoring/page1/line8`,
PERFORMANCE_LINE8_PAGE2: `https://t3ttp2x2r9.execute-api.ap-southeast-1.amazonaws.com/prd/4117/fur2/api/line-monitoring/page2/line8`,
PERFORMANCE_LINE9_PAGE1: `https://t3ttp2x2r9.execute-api.ap-southeast-1.amazonaws.com/prd/4117/fur2/api/line-monitoring/page1/line9`,
PERFORMANCE_LINE9_PAGE2: `https://t3ttp2x2r9.execute-api.ap-southeast-1.amazonaws.com/prd/4117/fur2/api/line-monitoring/page2/line9`,
PERFORMANCE_LINE10_PAGE1: `https://t3ttp2x2r9.execute-api.ap-southeast-1.amazonaws.com/prd/4117/fur2/api/line-monitoring/page1/line10`,
PERFORMANCE_LINE10_PAGE2: `https://t3ttp2x2r9.execute-api.ap-southeast-1.amazonaws.com/prd/4117/fur2/api/line-monitoring/page2/line10`,

GIVEAWAY_PAGE1: `https://immjai-backend-api.cpfmes.com/4117/fur2/api/giveaway/page1/line7`,
GIVEAWAY_PAGE2: `https://immjai-backend-api.cpfmes.com/4117/fur2/api/giveaway/page2/line7`,
GIVEAWAY_LINE8_PAGE1: `https://immjai-backend-api.cpfmes.com/4117/fur2/api/giveaway/page1/line8`,
GIVEAWAY_LINE8_PAGE2: `https://immjai-backend-api.cpfmes.com/4117/fur2/api/giveaway/page2/line7`,
GIVEAWAY_LINE9_PAGE1: `https://immjai-backend-api.cpfmes.com/4117/fur2/api/giveaway/page1/line9`,
GIVEAWAY_LINE9_PAGE2: `https://immjai-backend-api.cpfmes.com/4117/fur2/api/giveaway/page2/line9`,
GIVEAWAY_LINE10_PAGE1: `https://immjai-backend-api.cpfmes.com/4117/fur2/api/giveaway/page1/line10`,
GIVEAWAY_LINE10_PAGE2: `https://immjai-backend-api.cpfmes.com/4117/fur2/api/giveaway/page2/line10`,

//// web mes korat
GET_ALL_FACTORY_DATA: `https://immjai-backendapi-ecs.cpfmes.com/api/all-factory-line/4117/`, 
GET_SAP_DATA: `https://immjai-backendapi-ecs.cpfmes.com/api/sap/data/`,
GET_SPECIFIC_LINE_DATA: `https://immjai-backendapi-ecs.cpfmes.com/api/line/4117/`,
MACHINE_RUNTIME: `https://immjai-backendapi-ecs.cpfmes.com/api/machine-runtime/4117/`,
DELETE_DEFECT: `https://immjai-backendapi-ecs.cpfmes.com/api/defect/delete/`,
DELECT_NO_EMPLOYEE: `https://immjai-backendapi-ecs.cpfmes.com/api/number-of-employee/delete/`,
GET_DEFECT: `https://immjai-backendapi-ecs.cpfmes.com/api/defect/get/4117/`,
GET_NO_EMPLOYEE: `https://immjai-backendapi-ecs.cpfmes.com/api/number-of-employee/get/4117/`,
GET_DEFECT_FOR_DONUT: `https://immjai-backendapi-ecs.cpfmes.com/api/defect/get/donut/4117/`,
ADD_DEFECT: `https://immjai-backendapi-ecs.cpfmes.com/api/add-defect/`,
EDIT_DEFECT: `https://immjai-backendapi-ecs.cpfmes.com/api/defect/update/`,
ADD_NO_EMPLOYEE: `https://immjai-backendapi-ecs.cpfmes.com/api/add-number-of-employee/`,
EDIT_NO_EMPLOYEE: `https://immjai-backendapi-ecs.cpfmes.com/api/number-of-employee/update/`,
DAILY_REPORT: `https://immjai-backendapi-ecs.cpfmes.com/api/post-date/get/4117/LINE7/`,
UPDATE_PO: `https://t3ttp2x2r9.execute-api.ap-southeast-1.amazonaws.com/prd/sap/outbound/meskr`,

}
import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useContext } from "react";
import { tokens } from "../../theme";
import db from "../../firebase-config";
import HeaderDetail from '../../components/header/HeaderDetail';
import LineChartHeatingYield from "../../components/chart/LineChartHeatingYield";
import Watch from "../../assets/img/watch.png";
import Temperature from "../../assets/img/temperature.png";
import PopupHeatingYield from "../../components/model/PopupHeatingYield"

const ItemHeatingYield = styled(Paper)(({ theme }) => ({
    backgroundColor: '#18443c',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 0,
    // height: '100%'

}));

///linear-gradient color to Material UI Grid background(https://stackoverflow.com/questions/48849340/how-to-add-linear-gradient-color-to-material-ui-chip-background)
const ItemChart = styled(Paper)(({ theme }) => ({
    background: 'linear-gradient(#0c6c2c, #448c26, #e4ac0c, #cc741c, #940404)',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 0,
    // height: '100px'

}));

const ItemKG = styled(Paper)(({ theme }) => ({
    backgroundColor: '#302c2c',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 15,
    // height: '50%',
}));





export default function HeatingYield() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');

    const [heatingYield, setHeatingYield] = useState([]);
    const [heatingYieldChart, setHeatingYieldChart] = useState([]);
    const [keeplive, setkeeplive] = useState([]);

    console.log("This is from my branch");
    useEffect(() => {
        const subscriberHeatingYield = db
            .collection("prod_mes_ignition_heating_yield")
            .onSnapshot((querySnapshot) => {
                const heatingYieldData = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setHeatingYield(heatingYieldData);
            });

        const subscriberHeatingYield_5_mtf = db
            .collection("prod_mes_ignition_heating_yield_5mtf")
            .onSnapshot((querySnapshot) => {
                const heatingYield = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setHeatingYieldChart(heatingYield);
            });

        const subscriberKeepAlive = db
            .collection("prod_mes_ignition_keep_alive")
            .onSnapshot((querySnapshot) => {
                const keepAlive = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setkeeplive(keepAlive);
            });

        // Cleanup listeners
        return () => {
            subscriberHeatingYield();
            subscriberHeatingYield_5_mtf();
            subscriberKeepAlive();
        };
    }, []);



    function groupBy(heatingYieldChart, property) {
        return heatingYieldChart.reduce(function (memo, x) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    // Filter the heatingYieldChart to only include data from the last 1 hour
    const now = moment().tz('Asia/Bangkok');
    const oneHourAgo = now.clone().subtract(1, 'hours');

    const heatingYieldLastHour = heatingYieldChart.filter(item => {
        const timestamp = moment(item.TIMESTAMP).tz('Asia/Bangkok');
        return timestamp.isSame(now, 'day') && timestamp.isBetween(oneHourAgo, now);
    });

    const grouped = groupBy(heatingYieldLastHour, "TIMESTAMP");
    const keys = Object.keys(grouped);
    var output = [];

    //loop keys
    keys.forEach(key => {
        //merge using reduce
        const out = grouped[key].reduce((current) => {
            return {
                PERCENT: current.PERCENT,
                TIMESTAMP: current.TIMESTAMP,

            }
        });
        output.push(out);
    });

    const time_heating_yield = output.map(item =>
        moment(new Date(item.TIMESTAMP)).tz('Asia/Bangkok').format('HH:mm')
    )

    const value_heating_yield = output.map(i => i.PERCENT == null ? 0 : (i.PERCENT).toFixed(1));

    const value_chart_heating_yield = value_heating_yield.slice((value_heating_yield.length - 12), value_heating_yield.length);

    const filteredValues = value_chart_heating_yield.map(Number).filter(num => num > 0);

    const min_chart = filteredValues.length > 0 ? Math.min(...filteredValues) : null;


    const max_chart = Math.max(...value_chart_heating_yield.map(Number));

    //console.log(min_chart)

    const sku = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7ACTIVE_SKU"));

    const actual_feed_rate = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7ACTUAL_FEED_RATE"));

    const actual_kg = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7ACTUAL_KG"));

    const avg_heating_yield = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7AVG_HEATING_YIELD"));

    const benefit = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7BENEFIT"));

    const best_practice_heating_yield = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7BEST_PRACTICE_HEATING_YIELD"));

    const dwell_time = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7DWELL_TIME"));

    const min = dwell_time?.DWELL_TIME == undefined ? '' : (dwell_time?.DWELL_TIME).split(" ")

    const fryer_temp = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7FRYER_TEMP"));

    const std_feed_rate = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7STD_FEED_RATE"));

    const std_heating_yield = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7STD_HEATING_YIELD"));

    const target_kg = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7TARGET_KG"));

    const warning_heating_yield = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7WARNING"));

    const shift = heatingYield == undefined ? '' : heatingYield.find((x) => x.id.includes("4117.00000000000000.Line7SHIFT"));

    //// check status machine
    const status_cooking = keeplive == undefined ? '' : keeplive.find((x) => x.ID.includes("Further.4117.Belt_Scale.Belt_Scale_Fryer.Line_7.0NoError"));

    const status_fryer = keeplive == undefined ? '' : keeplive.find((x) => x.ID.includes("Further.4117.Fryer.EFRB5107.Line7.MachineState"));

    const status_cooling = keeplive == undefined ? '' : keeplive.find((x) => x.ID.includes("Further.4117.Belt_Scale.Belt_Scale_1.Line_7.0NoError"));

    const heating_yield_range = () => {
        let range;
        try {
            if (sku?.ACTIVE_SKU == 'MN-F-2001') {
                range = '84-90'
            } else if (sku?.ACTIVE_SKU == 'MN-F-3006') {
                range = '80-88'
            } else if (sku?.ACTIVE_SKU == 'MN-F-3017') {
                range = '90-96'
            } else if (sku?.ACTIVE_SKU == 'CBS-F-3037') {
                range = '89-95'
            } else if (sku?.ACTIVE_SKU == 'MN-F-3026') {
                range = '84-90'
            } else if (sku?.ACTIVE_SKU == 'MN-F-3030') {
                range = '82-88'
            } else if (sku?.ACTIVE_SKU == 'MN-F-3001') {
                range = '80-88'
            } else if (sku?.ACTIVE_SKU == 'MN-F-3019') {
                range = '84-90'
            } else if (sku?.ACTIVE_SKU == 'MN-F-3016') {
                range = '90-96'
            } else if (sku?.ACTIVE_SKU == 'MN-F-3034') {
                range = '80-88'
            } else if (sku?.ACTIVE_SKU == 'CBH-F-3030') {
                range = '92-98'
            } else if (sku?.ACTIVE_SKU == 'MN-F-3048') {
                range = '82-88'
            }
            return range;
        }
        catch {
            console.log()
        }
    }




    return (
        <>
            {warning_heating_yield?.MESSAGE != null || status_cooking?.VALUE == false || status_fryer?.IS_CONNECTED == false || status_cooling?.VALUE == false ? <PopupHeatingYield /> :
                <Helmet>
                    <title>Heating Yield | MES </title>
                </Helmet>
            }
            <Grid item xs={12} style={{ paddingTop: 25 }}>
                <HeaderDetail
                    line={'Line 7'}
                    sku={sku?.ACTIVE_SKU == undefined ? '-' : sku?.ACTIVE_SKU}
                    icon={shift?.SHIFT == undefined ? <LightModeOutlinedIcon style={{ height: '55px' }} /> : shift?.SHIFT == 'D' ? <LightModeOutlinedIcon style={{ height: '55px' }} /> : <DarkModeOutlinedIcon style={{ height: '55px' }} />}
                />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0, backgroundColor: colors.neutral['black'] }}>
                <Grid container rowSpacing={0.5} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={7} >
                        <Grid item xs={12} textAlign="center" style={{ paddingTop: 0, paddingLeft: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "8vh", position: "relative", top: "0vw" }} >
                                    Heating Yield&nbsp;
                                </Typography>
                                <Typography style={{ color: colors.neutral['white'], fontSize: 30, fontWeight: 'bold', lineHeight: "8vh", paddingTop: 1.5 }} >
                                    (%)
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} textAlign="center" style={{ paddingTop: 0, paddingLeft: 10 }}>
                            <ItemHeatingYield>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                    <Grid item xs={3.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['grey'], fontSize: 36, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                Std.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                {std_heating_yield?.STD_HEATING_YIELD == undefined ? '-' : std_heating_yield?.STD_HEATING_YIELD}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 24, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                {sku?.ACTIVE_SKU == undefined ? '-' : heating_yield_range()}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={0.1} style={{ borderLeft: "1px dashed #000000", height: '16vh' }} >

                                    </Grid>

                                    <Grid item xs={3.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['grey'], fontSize: 36, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                Avg. Actual
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: '#b4a41c', fontSize: 48, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                {avg_heating_yield?.AVG_HEATING_YIELD == undefined ? '-' : (Math.round(avg_heating_yield?.AVG_HEATING_YIELD * 100) / 100).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={0.1} style={{ borderLeft: "1px dashed #000000", height: '16vh' }} >

                                    </Grid>

                                    <Grid item xs={3.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['grey'], fontSize: 36, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                Best Practice
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                {best_practice_heating_yield?.BEST_PRACTICE_HEATING_YIELD == undefined ? '-' : (Math.round(best_practice_heating_yield?.BEST_PRACTICE_HEATING_YIELD * 100) / 100).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </ItemHeatingYield>
                        </Grid>

                        <Grid item xs={12} textAlign="center" style={{ paddingTop: 5, paddingLeft: 10 }} >
                            <ItemChart style={{ height: '48vh' }}>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="right" >
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 32, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        Realtime 1 Hr.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" >

                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-5vw' }}>
                                    <LineChartHeatingYield
                                        value={value_heating_yield.slice((value_heating_yield.length - 12), value_heating_yield.length)}
                                        time={time_heating_yield.slice((time_heating_yield.length - 12), time_heating_yield.length)}
                                        target={best_practice_heating_yield?.BEST_PRACTICE_HEATING_YIELD == undefined ? 0 : (Math.round(best_practice_heating_yield?.BEST_PRACTICE_HEATING_YIELD * 100) / 100).toFixed(2)}
                                        min={min_chart - 0.5}
                                        max={max_chart + 0.5}
                                    />
                                </Grid>

                            </ItemChart>

                        </Grid>
                    </Grid>

                    <Grid item xs={5} >
                        <Grid item xs={12} textAlign="center" style={{ paddingRight: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "8vh", position: "relative", top: "0vw" }} >
                                    FG&nbsp;
                                </Typography>
                                <Typography style={{ color: colors.neutral['white'], fontSize: 30, fontWeight: 'bold', lineHeight: "8vh", paddingTop: 1 }} >
                                    (kg)
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} textAlign="center" style={{ paddingRight: 10, paddingLeft: 10 }}>
                            <ItemKG style={{ height: '30vh' }}>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                    <Grid item xs={5.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['grey'], fontSize: 36, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                Actucl
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold', lineHeight: "8vh" }} >
                                                {actual_kg?.ACTUAL_KG == undefined ? '-' : Math.round(actual_kg?.ACTUAL_KG).toLocaleString()}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                <Typography style={{ color: colors.neutral['grey'], fontSize: 28, fontWeight: 'bold', lineHeight: "8vh", paddingTop: 2 }} >
                                                    Target&nbsp;
                                                </Typography>
                                                <Typography style={{ color: colors.neutral['grey'], fontSize: 36, fontWeight: 'bold', lineHeight: "8vh" }} >
                                                    {target_kg?.TARGET_KG == undefined ? '-' : (target_kg?.TARGET_KG).toLocaleString()}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={0.2} style={{ borderLeft: "1px dashed #c2c2c2", height: '25vh', position: "relative", top: "1vw" }} >

                                    </Grid>

                                    <Grid item xs={5.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['grey'], fontSize: 36, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                Benefit
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: benefit?.BENEFIT < 0 ? '#bd1904' : '#2e9281', fontSize: 48, fontWeight: 'bold', lineHeight: "8vh" }} >
                                                {benefit?.BENEFIT == undefined ? '-' : (benefit?.BENEFIT < 0 ? Math.round(benefit?.BENEFIT).toLocaleString() : '+' + Math.round(benefit?.BENEFIT).toLocaleString())}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ItemKG>
                        </Grid>

                        <Grid item xs={12} textAlign="center" style={{ paddingTop: 18, paddingRight: 10, paddingLeft: 10 }}>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                <Grid item xs={6} >
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                        <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "8vh" }} >
                                            Feed rate
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} textAlign="center"  >
                                        <ItemKG style={{ height: '25vh' }}>
                                            <Grid item xs={12} textAlign="center" >
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 58, fontWeight: 'bold', lineHeight: "14vh" }} >
                                                    {actual_feed_rate?.ACTUAL_FEED_RATE == undefined ? '-' : Math.round(actual_feed_rate?.ACTUAL_FEED_RATE)}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 28, fontWeight: 'bold', lineHeight: "9vh", paddingTop: 2 }} >
                                                        Std.&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 36, fontWeight: 'bold', lineHeight: "9vh" }} >
                                                        {std_feed_rate?.STD_FEED_RATE == undefined ? '-' : (std_feed_rate?.STD_FEED_RATE).toLocaleString()}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 28, fontWeight: 'bold', lineHeight: "9vh", paddingTop: 2 }} >
                                                        kg/hr
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </ItemKG>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6} style={{ paddingLeft: 5 }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                        <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "8vh" }} >
                                            Machine
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                        <ItemKG style={{ height: '25vh' }}>
                                            <Grid item xs={12} md={12} lg={12} xl={12} >
                                                <table style={{ width: '60%', paddingTop: 10 }} align="center">
                                                    <tbody>
                                                        <tr >
                                                            <td style={{ width: '40%', lineHeight: "8vh" }} align="right">
                                                                <img src={Temperature} height="50vh" style={{ position: "relative", top: "0.2vw" }} />
                                                            </td>
                                                            <td style={{ width: '40%', color: '#2e9281', fontSize: 60, fontWeight: 'bold', lineHeight: "8vh" }} align="right">
                                                                {fryer_temp?.FRYER_TEMP == undefined ? '-' : Math.round(fryer_temp?.FRYER_TEMP)}
                                                            </td>
                                                            <td style={{ width: '30%', color: colors.neutral['white'], fontSize: 40, fontWeight: 'bold', lineHeight: "8vh" }} align="left">
                                                                ํC
                                                            </td>
                                                        </tr>

                                                        <tr >
                                                            <td style={{ width: '40%', lineHeight: "8vh" }} align="right">
                                                                <img src={Watch} height="50vh" style={{ position: "relative", top: "0.4vw" }} />
                                                            </td>
                                                            <td style={{ width: '40%', lineHeight: "8vh" }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }} textAlign="right" >
                                                                    <Typography style={{ color: '#2e9281', fontSize: 60, fontWeight: 'bold', lineHeight: "10vh", paddingTop: 1 }} align="left">
                                                                        {dwell_time?.DWELL_TIME == undefined ? '-' : min[0]}
                                                                    </Typography>
                                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 40, fontWeight: 'bold', lineHeight: "10vh", paddingTop: 2.5 }} align="left">
                                                                        m
                                                                    </Typography>
                                                                    <Typography style={{ color: '#2e9281', fontSize: 60, fontWeight: 'bold', lineHeight: "10vh", paddingTop: 1 }} align="left">
                                                                        {dwell_time?.DWELL_TIME == undefined ? '-' : min[2]}
                                                                    </Typography>
                                                                </Box>
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.neutral['white'], fontSize: 40, fontWeight: 'bold', lineHeight: "10vh", paddingTop: 3.5 }} align="left">
                                                                s
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </ItemKG>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid >
            </Grid >
        </>
    );
}

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormHelperText,
    Link,
    TextField,
    Typography
} from '@material-ui/core';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { apiUrl } from "../../constants";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useSelector } from 'react-redux';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";

const MySwal = withReactContent(Swal);


const NewMasterSTD = () => {
    const exchangeToken = useExchangeToken();
    const navigate = useNavigate();
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);
    const [error, setError] = useState(null);

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);


    useEffect(() => {
        const exchange = async () => {
            if (!isTokenExchanged) {
                try {
                    await exchangeToken(); // แลกเปลี่ยนโทเค็น
                    setIsTokenExchanged(true);
                } catch (err) {
                    console.error("Error exchanging token:", err);
                    setError("Failed to exchange token");
                }
            }
        };

        exchange();
    }, [isTokenExchanged, exchangeToken]);

    

    // จะทำงานหลังจากUser click submit to post user info to register API
    const handleRegister = React.useCallback(
        async (credentials, setSubmitting, setStatus) => {
            // เซตbodyสำหรับpostไปในregister api

            var body = {
                plant_id: credentials.plant.toUpperCase(),
                factory: credentials.factory,
                line: credentials.line,
                material_description: credentials.material_description,
                belt_scale_feed_rate: credentials.belt_scale_feed_rate == '' ? 0 : credentials.belt_scale_feed_rate,
                multihead_upper_limit: credentials.multihead_upper_limit == '' ? 0 : credentials.multihead_upper_limit,
                multihead_auto_pack: credentials.multihead_auto_pack == '' ? 0 : credentials.multihead_auto_pack,
                multihead_target_giveaway: credentials.multihead_target_giveaway == '' ? 0 : credentials.multihead_target_giveaway,
                multihead_target_pack: credentials.multihead_target_pack == '' ? 0 : credentials.multihead_target_pack,
                multihead_upper_weight: credentials.multihead_upper_weight == '' ? 0 : credentials.multihead_upper_weight,
                multihead_speed_target: credentials.multihead_speed_target == '' ? 0 : credentials.multihead_speed_target,
                multihead_df_weight: credentials.multihead_df_weight == '' ? 0 : credentials.multihead_df_weight,
                check_on: credentials.plant.toUpperCase() + credentials.factory + credentials.line + credentials.material_description

            }
            console.log(body)

            const AddUser = async () => {
                const result = await axios.post(`${apiUrl.POST_MASTER_STD}`, body, {
                    headers: {
                        Authorization: `Bearer ${ac_token}`
                    }
                })
                return result.data;
            };

            // เรียก Adduser เพื่อpost api
            const data = await AddUser()

            if (data.id) {
                MySwal.fire({
                    title: 'Good job!',
                    text: 'Successfully created new master std!',
                    icon: 'success',
                    confirmButtonText: 'Ok,Got it!'
                }).then(() => {
                    // redirect to settings page
                    navigate('/list-master-std', { replace: true });
                });
            } else {
                MySwal.fire({
                    title: 'Failed!',
                    text: 'Failed to created new master std!',
                    icon: 'error',
                    confirmButtonText: 'Ok,Got it!'
                });
            }
        }
    );

    return (
        <>
            <Helmet>
                <title>New Master STD | MES </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: '#f5f5f5',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >
                <Container maxWidth="md">
                    <Formik
                        initialValues={{
                            plant_id: '',
                            factory: '',
                            line: '',
                            material_description: '',
                            // belt_scale_feed_rate: '',
                            // multihead_upper_limit: '',
                            // multihead_auto_pack: '',
                            // multihead_target_giveaway: '',
                            // multihead_target_pack: '',
                            // multihead_upper_weight: '',
                            // multihead_speed_target: '',
                            // multihead_df_weight: '',

                        }}
                        validationSchema={Yup.object().shape({
                            plant: Yup.string().max(255).required('Plant is required'),
                            factory: Yup.string().max(255).required('Factory is required'),
                            line: Yup.string().max(255).required('Line is required'),
                            material_description: Yup.string().max(255).required('SKU is required'),
                            // belt_scale_feed_rate: Yup.string().max(255).required('Belt scale feed rate is required'),
                            // multihead_upper_limit: Yup.string().max(255).required('Multihead upper limit is required'),
                            // multihead_auto_pack: Yup.string().max(255).required('Multihead auto pack is required'),
                            // multihead_target_giveaway: Yup.string().max(255).required('Multihead target giveaway is required'),
                            // multihead_target_pack: Yup.string().max(255).required('Multihead target pack is required'),
                            // multihead_upper_weight: Yup.string().max(255).required('Multihead upper weight is required'),
                            // multihead_speed_target: Yup.string().max(255).required('Multihead speed target is required'),
                            // multihead_df_weight: Yup.string().max(255).required('Multihead df weight is required'),
                        })}
                        onSubmit={async (values, { setSubmitting, setStatus }) => {
                            setSubmitting(true);
                            handleRegister(values, setSubmitting, setStatus);
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            status
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: 3, mt: 1 }}>
                                    <Typography color="textPrimary" style={{ fontSize: 68 }}>
                                        New Master STD
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        style={{ fontSize: 20 }}
                                    >

                                    </Typography>
                                </Box>
                                {status}
                                <TextField
                                    error={Boolean(touched.plant && errors.plant)}
                                    fullWidth
                                    helperText={touched.plant && errors.plant}
                                    label="Plant Id"
                                    margin="normal"
                                    name="plant"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.plant}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <TextField
                                    error={Boolean(touched.factory && errors.factory)}
                                    fullWidth
                                    helperText={touched.factory && errors.factory}
                                    label="Factory"
                                    margin="normal"
                                    name="factory"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.factory}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                                <TextField
                                    error={Boolean(touched.line && errors.line)}
                                    fullWidth
                                    helperText={touched.line && errors.line}
                                    label="Line"
                                    margin="normal"
                                    name="line"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.line}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                                <TextField
                                    error={Boolean(touched.material_description && errors.material_description)}
                                    fullWidth
                                    helperText={touched.material_description && errors.material_description}
                                    label="SKU"
                                    margin="normal"
                                    name="material_description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.material_description}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <TextField
                                    type="number"
                                    error={Boolean(touched.belt_scale_feed_rate && errors.belt_scale_feed_rate)}
                                    fullWidth
                                    helperText={touched.belt_scale_feed_rate && errors.belt_scale_feed_rate}
                                    label="Feed rate"
                                    margin="normal"
                                    name="belt_scale_feed_rate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.belt_scale_feed_rate}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <TextField
                                    type="number"
                                    error={Boolean(touched.multihead_upper_limit && errors.multihead_upper_limit)}
                                    fullWidth
                                    helperText={touched.multihead_upper_limit && errors.multihead_upper_limit}
                                    label="Multihead upper limit"
                                    margin="normal"
                                    name="multihead_upper_limit"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.multihead_upper_limit}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <TextField
                                    type="number"
                                    error={Boolean(touched.multihead_auto_pack && errors.multihead_auto_pack)}
                                    fullWidth
                                    helperText={touched.multihead_auto_pack && errors.multihead_auto_pack}
                                    label="Multihead auto pack"
                                    margin="normal"
                                    name="multihead_auto_pack"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.multihead_auto_pack}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <TextField
                                    type="number"
                                    error={Boolean(touched.multihead_target_giveaway && errors.multihead_target_giveaway)}
                                    fullWidth
                                    helperText={touched.multihead_target_giveaway && errors.multihead_target_giveaway}
                                    label="Multihead target giveaway"
                                    margin="normal"
                                    name="multihead_target_giveaway"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.multihead_target_giveaway}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <TextField
                                    type="number"
                                    error={Boolean(touched.multihead_target_pack && errors.multihead_target_pack)}
                                    fullWidth
                                    helperText={touched.multihead_target_pack && errors.multihead_target_pack}
                                    label="Multihead target pack"
                                    margin="normal"
                                    name="multihead_target_pack"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.multihead_target_pack}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <TextField
                                    type="number"
                                    error={Boolean(touched.multihead_upper_weight && errors.multihead_upper_weight)}
                                    fullWidth
                                    helperText={touched.multihead_upper_weight && errors.multihead_upper_weight}
                                    label="Multihead upper weight"
                                    margin="normal"
                                    name="multihead_upper_weight"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.multihead_upper_weight}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <TextField
                                    error={Boolean(touched.multihead_speed_target && errors.multihead_speed_target)}
                                    fullWidth
                                    helperText={touched.multihead_speed_target && errors.multihead_speed_target}
                                    label="Multihead speed target"
                                    margin="normal"
                                    name="multihead_speed_target"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.multihead_speed_target}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <TextField
                                    type="number"
                                    error={Boolean(touched.multihead_df_weight && errors.multihead_df_weight)}
                                    fullWidth
                                    helperText={touched.multihead_df_weight && errors.multihead_df_weight}
                                    label="Multihead df weight"
                                    margin="normal"
                                    name="multihead_df_weight"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.multihead_df_weight}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />

                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        ml: -1
                                    }}
                                >
                                </Box>
                                <Divider />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        p: 2
                                    }}
                                >
                                    <Button
                                        style={{ background: '#575755', color: "white", fontSize: 18 }}
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        component={RouterLink}
                                        to="/list-master-std"
                                        sx={{ mr: 3 }}
                                    >
                                        <ArrowLeftIcon />
                                        Back to master std
                                    </Button>
                                    <Button
                                        style={{ background: '#009688', color: "white", fontSize: 18 }}
                                        disabled={isSubmitting}
                                        //fullWidth
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Create new master std
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </>
    );
};

export default NewMasterSTD;

import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import db from "../firebase-config"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import HeaderDetail from '../compenents/header/HeaderDetail';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Target from "../assets/img/svg/target.svg"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import GrigToDetail from "../compenents/grid/GridToDetail";
import Fighting from "../assets/img/first1.gif";
import Running from "../assets/img/running.gif"
import Run from '../assets/img/running_png.png';
import Fist from '../assets/img/protest_png.png';
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#bcd6d0',
  padding: theme.spacing(0.8),
  textAlign: 'center',
  //border: '0.8px dashed #2e9281',
  //borderRadius: 10,
  //height: '102%'

}));

const ItemTarget = styled(Paper)(({ theme }) => ({
  backgroundColor: '#000000',
  padding: theme.spacing(1),
  textAlign: 'center',
  //border: '0.8px dashed #2e9281',
  borderRadius: 0,
  //height: '65vh'

}));



const PerformanceDetailPage1 = () => {
  const exchangeToken = useExchangeToken();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [no_data, setNo_data] = useState(false);
  const [no_data_p2, setNo_data_p2] = useState(false);
  const [performanceP1, setPerformanceP1] = useState({
    WARNING: "",
    LINE: "",
    SKU: "",
    FACTORY_DATE: "",
    SHIFT: "",
    TARGET: "",
    TARGET_UNIT: "",
    ACTUAL_WEIGHT: "",
    ACTUAL_WEIGHT_PERCENT: "",
    REMAINING: "",
    REMAINING_PERCENT: "",
    ACTUAL_FEED_RATE: "",
    STD_FEED_RATE: ""
  })

  const [performanceP2, setPerformanceP2] = useState({
    PRODUCTION_TIME: "",
    NON_PRODUCTION_TIME: "",
    FINISH_DATETIME: "",
    PRODUCTION_EFFICIENCY: ""
  })

  const [taglist, setTaglist] = useState([]);
  const [isTokenExchanged, setIsTokenExchanged] = useState(false);
  const [error, setError] = useState(null);

  useCheckToken();
  const ac_token = useSelector(state => state.auth.access_token);

  useEffect(() => {
    const fetchInitialTokenAndData = async () => {
      try {
        const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
        if (token) {
          setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
        }
      } catch (err) {
        console.error("Error exchanging token:", err);
        setError("Failed to exchange token");
      }
    };

    fetchInitialTokenAndData();
  }, [exchangeToken]);

  const api_performance = () => {
    try {
      // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
      const buName = localStorage.getItem("buName");
      const plant = localStorage.getItem("plant");
      const factory = localStorage.getItem("factory");
      const line = localStorage.getItem("line");

      // ตรวจสอบเงื่อนไขโดยใช้ object mapping
      const apiMapping = {
        "แปรรูปไก่_4117_00000000000000_Line7": apiUrl.PERFORMANCE_PAGE1,
        "แปรรูปไก่_4117_00000000000000_Line8": apiUrl.PERFORMANCE_LINE8_PAGE1,
        "แปรรูปไก่_4117_00000000000000_Line9": apiUrl.PERFORMANCE_LINE9_PAGE1,
        "แปรรูปไก่_4117_00000000000000_Line10": apiUrl.PERFORMANCE_LINE10_PAGE1,
      };

      // สร้าง key สำหรับจับคู่
      const key = `${buName}_${plant}_${factory}_${line}`;

      // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
      return apiMapping[key];
    } catch (error) {
      console.error("Error in api_performance:", error);
      return null; // คืนค่า null หากเกิดข้อผิดพลาด
    }
  };

  const line_performance = () => {
    try {
      // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
      const buName = localStorage.getItem("buName");
      const plant = localStorage.getItem("plant");
      const factory = localStorage.getItem("factory");
      const line = localStorage.getItem("line");

      // ตรวจสอบเงื่อนไขโดยใช้ object mapping
      const lineMapping = {
        "แปรรูปไก่_4117_00000000000000_Line7": 'Line7',
        "แปรรูปไก่_4117_00000000000000_Line8": 'Line8',
        "แปรรูปไก่_4117_00000000000000_Line9": 'Line9',
        "แปรรูปไก่_4117_00000000000000_Line10": 'Line10',
      };

      // สร้าง key สำหรับจับคู่
      const key = `${buName}_${plant}_${factory}_${line}`;

      // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
      return lineMapping[key];
    } catch (error) {
      console.error("Error in line_performance:", error);
      return null; // คืนค่า null หากเกิดข้อผิดพลาด
    }
  };



  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${api_performance()}`, {
        "headers": {
          "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
        }
      }
      )
        .then(response => {
          //console.log(response.data)
          if (response.status == 200) {
            setPerformanceP1({
              WARNING: response.data.WARNING,
              LINE: response.data.LINE,
              SKU: response.data.SKU,
              FACTORY_DATE: response.data.FACTORY_DATE,
              SHIFT: response.data.SHIFT,
              TARGET: response.data.TARGET,
              TARGET_UNIT: response.data.TARGET_UNIT,
              ACTUAL_WEIGHT: response.data.ACTUAL_WEIGHT,
              ACTUAL_WEIGHT_PERCENT: response.data.ACTUAL_WEIGHT_PERCENT,
              REMAINING: response.data.REMAINING,
              REMAINING_PERCENT: response.data.REMAINING_PERCENT,
              ACTUAL_FEED_RATE: response.data.ACTUAL_FEED_RATE,
              STD_FEED_RATE: response.data.STD_FEED_RATE
            })
            setNo_data(1)

          }
        })
        .catch(error => {
          setNo_data(2)
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1000)
    return () => {
      clearInterval(interval);
    }

  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.PERFORMANCE_PAGE2}`, {
        "headers": {
          "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
        }
      }
      )
        .then(response => {
          //console.log(response.data)
          if (response.status == 200) {
            setPerformanceP2({
              PRODUCTION_TIME: response.data.PRODUCTION_TIME,
              NON_PRODUCTION_TIME: response.data.NON_PRODUCTION_TIME,
              FINISH_DATETIME: response.data.FINISH_DATETIME,
              PRODUCTION_EFFICIENCY: response.data.PRODUCTION_EFFICIENCY
            })
            setNo_data_p2(1)
          }
        })
        .catch(error => {
          setNo_data_p2(2)
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1000)
    return () => {
      clearInterval(interval);
    }

  }, []);

  useEffect(() => {
    const getPostFromFirebase = [];
    const subscriber = db
      .collection("meskr_line_monitoring_tag_list")
      .where("TAG", "==", "ACCUMURATE_WEIGHT")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getPostFromFirebase.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        setTaglist(getPostFromFirebase);
      });

    // return cleanup function
    return () => subscriber();
  }, [taglist]);

  var accumurate_weight = taglist.find((user) => user.TAG.includes("ACCUMURATE_WEIGHT"));

  ///// split time non production time
  var non_production_time = no_data_p2 == 1 ? (performanceP2.NON_PRODUCTION_TIME != 'NA' ? (performanceP2.NON_PRODUCTION_TIME).split(":") : "00:00") : "00:00"

  var non_production_hour = Number(non_production_time[0])

  var non_production_minute = Number(non_production_time[1])

  ///// split time  production time
  var production_time = no_data_p2 == 1 ? (performanceP2.PRODUCTION_TIME != 'NA' ? (performanceP2.PRODUCTION_TIME).split(":") : "00:00") : "00:00"

  var production_hour = Number(production_time[0])

  var production_minute = Number(production_time[1])


  const getChartColor = () => {
    let color;
    try {
      if ((no_data == 1 ? (performanceP1.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP1.ACTUAL_WEIGHT) : 0) >= (((((no_data_p2 == 1 ? production_hour : 0) + ((no_data_p2 == 1 ? production_minute : 0) / 60)) + ((no_data_p2 == 1 ? non_production_hour : 0) + ((no_data_p2 == 1 ? non_production_minute : 0) / 60))) * (no_data == 1 ? (performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1.STD_FEED_RATE) : 0)) * 1)) {
        color = "#2e9281"
      } else if ((no_data == 1 ? (performanceP1.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP1.ACTUAL_WEIGHT) : 0) >= (((((no_data_p2 == 1 ? production_hour : 0) + ((no_data_p2 == 1 ? production_minute : 0) / 60)) + ((no_data_p2 == 1 ? non_production_hour : 0) + ((no_data_p2 == 1 ? non_production_minute : 0) / 60))) * (no_data == 1 ? (performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1.STD_FEED_RATE) : 0)) * 0.8)) {
        color = "#b58514"
      } else if ((no_data == 1 ? (performanceP1.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP1.ACTUAL_WEIGHT) : 0) < (((((no_data_p2 == 1 ? production_hour : 0) + ((no_data_p2 == 1 ? production_minute : 0) / 60)) + ((no_data_p2 == 1 ? non_production_hour : 0) + ((no_data_p2 == 1 ? non_production_minute : 0) / 60))) * (no_data == 1 ? (performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1.STD_FEED_RATE) : 0)) * 0.8)) {
        color = "#7d2e1e"
      }
      return color;
    }
    catch {
      console.log()
    }
  }






  const getFeedrateColor = () => {
    let color;
    try {
      if ((no_data_p2 == 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) : 0) >= ((no_data == 1 ? ((performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP1.STD_FEED_RATE) : 0) * 1)) {
        color = "#2e9281"
      } else if ((no_data_p2 == 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) : 0) >= ((no_data == 1 ? ((performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP1.STD_FEED_RATE) : 0) * 0.8)) {
        color = "#b58514"
      } else if ((no_data_p2 == 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) : 0) < ((no_data == 1 ? ((performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP1.STD_FEED_RATE) : 0) * 0.8)) {
        color = "#7d2e1e"
      }
      return color;
    }
    catch {
      console.log()
    }
  }








  return (
    <>
      <Helmet>
        <title>Performance Page1 | MES</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <HeaderDetail
            // line={no_data === 1 ? (performanceP1.LINE == 'NA' ? 'Line7' : performanceP1.LINE) : 'Line7'}
            line={line_performance()}
            sku={no_data === 1 ? (performanceP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : performanceP1.WARNING == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? performanceP1.SKU : performanceP1.SKU) : '-'}
            icon={performanceP1.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ height: '55px' }} />) : (<LightModeOutlinedIcon style={{ height: '55px' }} />)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Item>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
            <Typography style={{ color: '#b58514', fontSize: 20, lineHeight: "17px", fontWeight: 'bold' }} >
              <img src={Target} height="120px" />
            </Typography>
            <Typography style={{ color: colors.neutral['green'], fontSize: 100, fontWeight: 'bold', lineHeight: "17px", paddingTop: 5 }} >
              &nbsp;เป้าหมาย&nbsp;
            </Typography>
            <Typography style={{ color: colors.neutral['green'], fontSize: 120, lineHeight: "17px", fontWeight: 'bold' }} >
              &nbsp;{no_data === 1 ? (performanceP1.TARGET).toLocaleString() : 0}&nbsp;
            </Typography>
            <Typography style={{ color: colors.neutral['green'], fontSize: 50, lineHeight: "17px", fontWeight: 'bold', paddingTop: 20 }} >
              kg
            </Typography>
          </Box>
        </Item>

      </Grid>

      <Grid item xs={12} sm={12}>
        <ItemTarget>
          <Grid container rowSpacing={0.8} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
            <GrigToDetail
              color={performanceP1.ACTUAL_WEIGHT === 'NA' ? colors.neutral['green'] : getChartColor()}
              image={accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Fighting} height="120vh" /> : <img src={Fist} height="120vh" />) : <img src={Fist} height="120vh" />}
              section={'ทำได้'}
              persent={no_data === 1 ? performanceP1.ACTUAL_WEIGHT_PERCENT : 0}
              color_persent={performanceP1.ACTUAL_WEIGHT === 'NA' ? colors.neutral['green'] : getChartColor()}
              value={no_data === 1 ? (performanceP1.ACTUAL_WEIGHT).toLocaleString() : 0}
            />

            <GrigToDetail
              color={'#4a4a49'}
              image={accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Running} height="120vh" /> : <img src={Run} height="120vh" />) : <img src={Run} height="120vh" />}
              section={'คงเหลือ'}
              persent={no_data === 1 ? (performanceP1.ACTUAL_WEIGHT_PERCENT === 'NA' ? 'NA' : (100 - performanceP1.ACTUAL_WEIGHT_PERCENT <= 0 ? 0 : 100 - performanceP1.ACTUAL_WEIGHT_PERCENT)) : 0}
              color_persent={colors.secondary[800]}
              value={no_data === 1 ? (performanceP1.REMAINING).toLocaleString() : 0}
            />
          </Grid>

          <Grid item xs={12} textAlign="left" style={{ paddingRight: 0, paddingLeft: 150 }}>
            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} textAlign="center">
              <Typography style={{ color: getFeedrateColor(), fontSize: 70, position: "relative", paddingLeft: 0, top: "0vw" }}>
                {no_data_p2 === 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 'NA' : (Math.round(performanceP2.PRODUCTION_EFFICIENCY)).toLocaleString()) : 0}
              </Typography>
              <Typography style={{ color: '#ffffff', fontSize: 50, paddingTop: 18 }}>
                &nbsp;กก./ชม.&nbsp;({no_data === 1 ? (performanceP1.STD_FEED_RATE == "ไม่มีข้อมูล Std. Cap ในระบบ" ? (performanceP1.STD_FEED_RATE).toLocaleString() : "Std." + (performanceP1.STD_FEED_RATE).toLocaleString()) : "Std." + 0})
              </Typography>
            </Box>
          </Grid>
        </ItemTarget>
      </Grid>



    </>
  )
}

export default PerformanceDetailPage1

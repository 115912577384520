import React, { useEffect, useState } from "react";
import * as  FileSaver  from "file-saver";
import XLSX from 'sheetjs-style'
import { Box, Grid, Card } from "@mui/material";
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';


const ExportExcel = ({excelData, fileName}) => {
const fileType  = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
    const data_excel = new Blob([excelBuffer], { type: fileType});
    FileSaver.saveAs(data_excel, fileName + fileExtension);
  }

  return (
      <>
        
    <Button variant="contained" style={{fontSize: 16, fontWeight: "bold", background: '#9c9797'}} startIcon={<GetAppIcon style={{ color: '#ffffff' }} />}  onClick={(e) => {exportToExcel(fileName); window.location.reload(false);}}>  
        Export 
    </Button>

      </>
  )

}

export default ExportExcel;
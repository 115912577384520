import { useRef, useEffect, useState, useMemo } from 'react'
import moment from 'moment-timezone';
import '../styles.css'
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import { Box, Grid, Card, Tooltip, Stack, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Slide
} from "@material-ui/core/";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";
import Circular from "../components/model/Circular";

const slideProps = {
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: { enter: "auto", exit: "auto" }
};



const RejectInInfeed = () => {
    const exchangeToken = useExchangeToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const today = new Date();
    const hourago = new Date(today.getTime());
    const hourago_start = new Date(today.getTime() - (48 * 60 * 60 * 1000));
    const datetime = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
    const date_start = moment(hourago_start).tz('Asia/Bangkok').format('YYYY-MM-DD');
    const date_end = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [reject_preparation_zone, setReject_preparation_zone] = useState([]);
    const [done, setDone] = useState(false);
    const [popupTimeout, setPopupTimeout] = useState(false);
    const [openPopupTimeout, setOpenPopupTimeout] = useState(false);

    const [noData, setNoData] = useState(false);
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);

    const handleClosePopupTimeout = () => {
        setOpenPopupTimeout(false);
    };

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);


    useEffect(() => {
        const fetchInitialTokenAndData = async () => {
            try {
                const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
                if (token) {
                    setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
                }
            } catch (err) {
                console.error("Error exchanging token:", err);
                setError("Failed to exchange token");
            }
        };

        fetchInitialTokenAndData();
    }, [exchangeToken]);


    useEffect(() => {
        const fetchMyAPI = async () => {
            if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
            setLoading(true);
            const timeout = 10000; // กำหนด timeout 10 วินาที (10,000 มิลลิวินาที)
            const timeoutError = new Promise((_, reject) =>
                setTimeout(() => reject(new Error("Request timed out")), timeout)
            );
            try {
                setDone(true);
                const response = await Promise.race([
                    axios.get(`${apiUrl.GET_TUMBLER_BETWEEN_2DAY}${date_start}/${date_end}/`, {
                        headers: {
                            Authorization: `Bearer ${ac_token}`,
                            // "X-xss-protection": "1; mode=block;",
                            // "X-frame-options": "SAMEORIGIN",
                            // "X-content-type-options": "nosniff",
                            // "Cache-Control": "no-cache, private",
                            // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                            // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                        },
                    }),
                    timeoutError // ตรวจสอบ timeout
                ]);
                if (response.status === 200) {
                    setReject_preparation_zone(response.data.filter(i => i.STATUS === "Reject"))
                    setDone(false);
                    setPopupTimeout(1)
                }
            } catch (err) {
                if (err.message === "Request timed out") {
                    console.error("Timeout Error: The request took too long to complete.");
                } else if (err.request.status === 404) {
                    setPopupTimeout(2)
                } else {
                    setPopupTimeout(3)
                }
                setDone(false);
            } finally {
                setLoading(false);
            }
        };

        if (isTokenExchanged && ac_token) {
            fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
        }
    }, [isTokenExchanged, ac_token, date_start, date_end]);

    const sort_factory_date = reject_preparation_zone.sort((a, b) => a.FACTORY_DATE.localeCompare(b.FACTORY_DATE));

    const sort_batch = sort_factory_date.sort((a, b) => a.AT_REJECTED_TIMESTAMP.localeCompare(b.AT_REJECTED_TIMESTAMP));



    const categories = useMemo(() => {
        const categories = {};
        reject_preparation_zone.forEach((item) => {
            if (!categories[item.MATERIAL_DESCRIPTION]) {
                categories[item.MATERIAL_DESCRIPTION] = [];
            }
            categories[item.MATERIAL_DESCRIPTION].push(item);
        });
        return categories;
    }, [reject_preparation_zone]);

    useEffect(() => {
        if (popupTimeout === 2) {
            setOpenPopupTimeout(true);
        } else if (popupTimeout === 3) {
            setOpenPopupTimeout(true);
        }
    }, [popupTimeout]);

    return (
        <Box style={{ width: "100%", height: "79vh" }}>
            <Helmet>
                <title>Reject | MES</title>
            </Helmet>
            {done ? <Circular /> :
                <Grid container spacing={2} style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }} >
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }} >
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ position: "relative", top: '0vw', height: '0px' }}>
                                <Box >
                                    <div>
                                        {Object.entries(categories).map((entry) => {
                                            const category = entry[0];
                                            const itemList = entry[1];

                                            const categories_time = {};
                                            for (const item of itemList) {
                                                if (item.FACTORY_DATE in categories_time) {
                                                    categories_time[item.FACTORY_DATE].push(item);
                                                } else {
                                                    categories_time[item.FACTORY_DATE] = [item];
                                                }
                                            }

                                            return (
                                                <Accordion
                                                    TransitionComponent={Slide}
                                                    TransitionProps={slideProps}
                                                    sx={{
                                                        backgroundColor: "#ffffff"
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {Object.entries(categories_time).map((entry) => {
                                                            const category_list = entry[0];
                                                            const item_list = entry[1];
                                                            return (
                                                                <Accordion
                                                                    TransitionComponent={Slide}
                                                                    TransitionProps={slideProps}
                                                                    sx={{
                                                                        backgroundColor: "#ffffff"
                                                                    }}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                    >
                                                                        <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category_list}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        {item_list.map((item) => {

                                                                            return <Card sx={{
                                                                                p: 1,
                                                                                margin: 'auto',
                                                                                //maxWidth: 1500,
                                                                                flexGrow: 1,
                                                                                backgroundColor: (theme) =>
                                                                                    theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
                                                                            }}>
                                                                                <Grid item xs={12} sm container>
                                                                                    <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "0vw" }}>
                                                                                        <Grid item xs>
                                                                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                                <Typography style={{ fontSize: 18, fontWeight: 'bold' }} gutterBottom>
                                                                                                    {item.MATERIAL_DESCRIPTION}&nbsp;&nbsp;&nbsp;
                                                                                                </Typography>

                                                                                            </Box>

                                                                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                                <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px', width: '80px' }} onClick={() => { }}>
                                                                                                    {item.LINE}
                                                                                                </Typography>
                                                                                                <Button style={{ backgroundColor: '#E8AB6D', color: "black", fontSize: 18, height: '20px' }} onClick={() => { }}>
                                                                                                    {item.SHIFT}
                                                                                                </Button>
                                                                                            </Box>

                                                                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '5%' }} textAlign="left" >

                                                                                            </Box>

                                                                                            <Typography style={{ fontSize: 15, width: '1000%' }} gutterBottom>
                                                                                                {(item.AT_REJECTED_TIMESTAMP).split("Z")[0]}
                                                                                            </Typography>
                                                                                            <Typography style={{ fontSize: 18 }} gutterBottom>
                                                                                                ชุดนวด: {item.BATCH}
                                                                                            </Typography>
                                                                                            <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px' }}>
                                                                                                {item.ACTUAL_BARREL_WEIGHT} kg.
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item xs container spacing={1} style={{ position: "relative", top: "1vw" }} textAlign="right">
                                                                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                                                                            <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                                                                                                <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} >IN STOCK</Button>
                                                                                                <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} >PROCESSING</Button>
                                                                                                <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} >COMPLETED</Button>
                                                                                                <Button style={{ height: '30px', width: '85px', fontWeight: 'bold', color: '#eb2626', backgroundColor: '#c7c9c8', fontSize: 15 }} >REJECT</Button>
                                                                                            </ButtonGroup>
                                                                                        </Grid>

                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Card>
                                                                        })}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            );
                                                        })}
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })}
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Dialog
                open={openPopupTimeout}
                onClose={handleClosePopupTimeout}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {popupTimeout == 2 ? 'No data available' : 'Unable to load data. Please try again.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClosePopupTimeout(); popupTimeout == 2 ? handleClosePopupTimeout() : window.location.reload(false); }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default RejectInInfeed

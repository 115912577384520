// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
}

.wrapper {
  /* padding-top: 10rem; */
  background-color: #000000;
}

.slide {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  text-align: center;
  height: 226px;
  background-color: #eee;
  border-radius: 16px;
}

.swiper-container {
  height: 700px;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 27px;
  height: 44px;
  margin-top: -22px;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: transparent;
  border: 1px solid #68b8ff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 20px;
  height: 6px;
  transition: width 0.5s;
  border-radius: 5px;
  background: #68b8ff;
  border: 1px solid transparent;
}`, "",{"version":3,"sources":["webpack://./src/compenents/slider/styles.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,SAAS;EACT,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,6BAA6B;AAC/B","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n}\n\n.wrapper {\n  /* padding-top: 10rem; */\n  background-color: #000000;\n}\n\n.slide {\n  font-size: 1.5rem;\n  padding: 0;\n  margin: 0;\n  text-align: center;\n  height: 226px;\n  background-color: #eee;\n  border-radius: 16px;\n}\n\n.swiper-container {\n  height: 700px;\n}\n\n.swiper-button-next,\n.swiper-button-prev {\n  position: absolute;\n  top: 50%;\n  z-index: 10;\n  width: 27px;\n  height: 44px;\n  margin-top: -22px;\n}\n\n.swiper-pagination {\n  position: absolute;\n  bottom: 10px;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.swiper-pagination-bullet {\n  width: 6px;\n  height: 6px;\n  background: transparent;\n  border: 1px solid #68b8ff;\n  opacity: 1;\n}\n\n.swiper-pagination-bullet-active {\n  width: 20px;\n  height: 6px;\n  transition: width 0.5s;\n  border-radius: 5px;\n  background: #68b8ff;\n  border: 1px solid transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import { Box, Grid, Container, Tab, Tabs,  Toolbar, Typography, useTheme } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment-timezone';


function HeaderDetail(props) {
    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
    const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
          setTime(new Date());
        }, 1000);
    
        return () => clearInterval(interval);
      }, []);
    
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" style={{ backgroundColor: "#000000", position: "relative"}}>
                <Toolbar variant="regular" >
                    <Grid container spacing={3}>
                        <Grid item xs={1.5} style={{backgroundColor: "#2e9281", height: '9.6vh', position: "relative", top: "1.6vw", }} textAlign="center">
                            <Typography
                                style={{ color: "#ffffff", fontWeight: "bold", fontSize: 50, position: "relative", left: "0vw", lineHeight: "20px" }}
                            >
                                {props.line}&nbsp;&nbsp;
                            </Typography>
                        </Grid>
                        <Grid item xs={1} textAlign="left">
                            <Typography
                                style={{color: "#ffffff", fontWeight: "bold", fontSize: 50, position: "relative", left: "0vw" }}
                            >
                                SKU:
                            </Typography>
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                            <Typography
                                style={{ color: "#2e9281", fontWeight: "bold", fontSize: 50, position: "relative", left: '-2vw' }}
                            >
                                {props.sku}
                            </Typography>
                        </Grid>
                        <Grid item xs={5.5} textAlign="right">
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Typography
                                    style={{ color: "#ffffff", fontWeight: "bold", fontSize: 50 }}
                                    textAlign="right"
                                >
                                    {timestr}&nbsp;&nbsp;{time.toLocaleTimeString('en-GB')}
                                </Typography>
                                
                            </Box>
                        </Grid>
                        <Grid item xs={1} textAlign="left">
                            <Typography
                                style={{ position: "relative", paddingTop: 10 }}
                            >
                                 {props.icon}
                            </Typography>
                        </Grid>

                    </Grid>
                </Toolbar>
            </AppBar>

        </Box>
    );
  }
  
  export default HeaderDetail
import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { tokens } from "../../theme";
import { useTheme } from '@mui/material';
import optionImg from "../../assets/img/menuWhite.png"
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { setState } from "../../redux/authSlice";
import { useDispatch } from "react-redux";

const settings = [
  {
    icon: <PersonAdd fontSize="small" />,
    name: 'account'
  },
  {
    icon: <SettingsIcon fontSize="small" />,
    name: 'setting'
  }
]

function TopBar() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (e) => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    // Clear Redux State
    dispatch(setState({
      access_token: '',
      refresh_token: '',
      rb_ac_exp: '',
      rb_rf_exp: '',
      isLogin: false,
    }));

    // Clear LocalStorage
    localStorage.clear();

    // Redirect to Login Page
    window.location.href = 'https://cpf-test.uat.axonscloud.com/login';
  };



  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: colors.primary[600] }}>
        <Toolbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: '0 16px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link
                to="/"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography style={{ color: colors.neutral['white'], fontSize: 24, fontWeight: 'bold' }} sx={{ flexGrow: 1 }}>
                  MES
                </Typography>
              </Link>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button style={{ color: colors.neutral['white'], fontSize: 20, fontWeight: 'bold' }} onClick={handleLogout}>Logout</Button>
              <Button onClick={handleOpenUserMenu} >
                <img width="20px" height="20px" src={optionImg} alt="More Options" />
              </Button>
              <Menu
                anchorEl={anchorElUser}
                id="account-menu"
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                onClick={handleCloseUserMenu}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.name} accessKey={setting.name} onClick={() => {navigate(`/${setting.name}`, { replace: true }); handleCloseUserMenu()}}>
                    <ListItemIcon>
                      {setting.icon}
                    </ListItemIcon>
                    {setting.name}
                  </MenuItem>
                ))}
              </Menu>

            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default TopBar
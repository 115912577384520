import React, { useEffect, useState } from "react";
import PerformanceDetailPage1 from '../pages/PerformanceDetailPage1';
import PerformanceDetailPage2 from '../pages/PerformanceDetailPage2';
import SliderSwiper from "../compenents/slider/SliderSwiper";



export default function PerformanceTab() {
    return (

        <SliderSwiper
            page1={<PerformanceDetailPage1 />}
            page2={<PerformanceDetailPage2 />}
        />
    );
}






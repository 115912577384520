import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import db from "../firebase-config";
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import HeaderMarinationTrack from '../components/header/HeaderMarinationTracking';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Time from '../assets/img/time.png';
import LensIcon from '@mui/icons-material/Lens';
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    border: '2px dashed #4A4A49',
    borderRadius: 10,
    height: '100%'

}));

const ItemInstock = styled(Paper)(({ theme }) => ({
    backgroundColor: '#0e2c27',
    padding: theme.spacing(0.4),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 5

}));

const ItemReady = styled(Paper)(({ theme }) => ({
    backgroundColor: '#302c2c',
    ...theme.typography.body2,
    padding: theme.spacing(0.2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



const MarinationTrackingPage2 = () => {
    const exchangeToken = useExchangeToken();
    const today = new Date();
    const hourago = new Date(today.getTime());

    const yesterday = new Date(today);  // คัดลอกวันที่ปัจจุบัน
    yesterday.setDate(today.getDate() - 2);  
    yesterday.setHours(0, 0, 0, 0);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [marinate, setMarinate] = useState([])
    const [marinate_page1, setMarinate_page1] = useState([])
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);
    const [error, setError] = useState(null);

    useCheckToken();
    //const ac_token = useSelector(state => state.auth.access_token);

    useEffect(() => {
        const exchange = async () => {
            if (!isTokenExchanged) {
                try {
                    await exchangeToken(); // แลกเปลี่ยนโทเค็น
                    setIsTokenExchanged(true);
                } catch (err) {
                    console.error("Error exchanging token:", err);
                    setError("Failed to exchange token");
                }
            }
        };

        exchange();
    }, [isTokenExchanged, exchangeToken]);


    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_preparation_zone_page1")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                setMarinate_page1(getPostFromFirebase);

                sessionStorage.setItem('batch_line7_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_BATCH"))?.CURRENT_BATCH);
                sessionStorage.setItem('batch_line8_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_BATCH"))?.CURRENT_BATCH);
                sessionStorage.setItem('batch_line9_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_BATCH"))?.CURRENT_BATCH);
                sessionStorage.setItem('batch_line10_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_BATCH"))?.CURRENT_BATCH);
                sessionStorage.setItem('batch_line11_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_BATCH"))?.CURRENT_BATCH);
                sessionStorage.setItem('batch_line12_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_BATCH"))?.CURRENT_BATCH);

            });

        // return cleanup function
        return () => subscriber();
    }, [marinate_page1]);


    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_preparation_zone_page2")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                setMarinate(getPostFromFirebase);
            });

        // return cleanup function
        return () => subscriber();
    }, [marinate]);

    const batch_line7 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE7NUMBER_OF_BATCH"));

    const batch_line8 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE8NUMBER_OF_BATCH"));

    const batch_line9 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE9NUMBER_OF_BATCH"));

    const batch_line10 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE10NUMBER_OF_BATCH"));

    const batch_line11 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE11NUMBER_OF_BATCH"));

    const batch_line12 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE12NUMBER_OF_BATCH"));

    const kg_instock_line7 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE7KG_IN_STOCK"));

    const kg_instock_line8 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE8KG_IN_STOCK"));

    const kg_instock_line9 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE9KG_IN_STOCK"));

    const kg_instock_line10 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE10KG_IN_STOCK"));

    const kg_instock_line11 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE11KG_IN_STOCK"));

    const kg_instock_line12 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE12KG_IN_STOCK"));



    const ready_line7 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE7"));

    const ready_line8 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE8"));

    const ready_line9 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE9"));

    const ready_line10 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE10"));

    const ready_line11 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE11"));

    const ready_line12 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE12"));


    const slice_ready_line7 = ready_line7 === undefined ? '' : ready_line7.slice(0, 4).filter(item => {
        const factoryDate = new Date(item.FACTORY_DATE);
        return factoryDate >= yesterday; // ตรวจสอบว่าค่า FACTORY_DATE อยู่ในช่วงตั้งแต่เมื่อวานจนถึงตอนนี้
    });

    const slice_ready_line8 = ready_line8 === undefined ? '' : ready_line8.slice(0, 4).filter(item => {
        const factoryDate = new Date(item.FACTORY_DATE);
        return factoryDate >= yesterday;
    });

    const slice_ready_line9 = ready_line9 === undefined ? '' : ready_line9.slice(0, 4).filter(item => {
        const factoryDate = new Date(item.FACTORY_DATE);
        return factoryDate >= yesterday;
    });

    const slice_ready_line10 = ready_line10 === undefined ? '' : ready_line10.slice(0, 4).filter(item => {
        const factoryDate = new Date(item.FACTORY_DATE);
        return factoryDate >= yesterday;
    });

    const slice_ready_line11 = ready_line11 === undefined ? '' : ready_line11.slice(0, 4).filter(item => {
        const factoryDate = new Date(item.FACTORY_DATE);
        return factoryDate >= yesterday;
    });

    const slice_ready_line12 = ready_line12 === undefined ? '' : ready_line12.slice(0, 4).filter(item => {
        const factoryDate = new Date(item.FACTORY_DATE);
        return factoryDate >= yesterday;
    });



    const hour_line7 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE7HOUR"));

    const hour_line8 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE8HOUR"));

    const hour_line9 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE9HOUR"));

    const hour_line10 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE10HOUR"));

    const hour_line11 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE11HOUR"));

    const hour_line12 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE12HOUR"));


    const minute_line7 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE7MIN"));

    const minute_line8 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE8MIN"));

    const minute_line9 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE9MIN"));

    const minute_line10 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE10MIN"));

    const minute_line11 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE11MIN"));

    const minute_line12 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE12MIN"));


    const batch_line7_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_BATCH"));

    const batch_line8_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_BATCH"));

    const batch_line9_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_BATCH"));

    const batch_line10_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_BATCH"));

    const batch_line11_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_BATCH"));

    const batch_line12_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_BATCH"));


    return (
        <>
            <Helmet>
                <title>Marination Tracking Page1 | MES</title>
            </Helmet>
            <Grid container spacing={1} style={{position: "relative", top: '-1.5vw'}}>
                <Grid item xs={12} sm={12}>
                    <HeaderMarinationTrack
                    />
                </Grid>

                <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }} style={{ backgroundColor: '#000000', paddingRight: 0, paddingTop: 5, paddingLeft: 10 }}>
                    <Grid item xs={6}>
                        <Item>
                            <Grid item xs={12} style={{ height: '23vh' }}>
                                {batch_line7 !== undefined ?
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                        <Grid item xs={4.3}>
                                            <Grid item xs={12} textAlign="left">
                                                <Typography style={{ color: '#2e9281', fontSize: 40, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "-0.2vw" }} >
                                                    &nbsp;Line 7
                                                </Typography>
                                            </Grid>
                                            {batch_line7?.NUMBER_OF_BATCH === 0 ? '' :
                                                <ItemInstock>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {batch_line7 == undefined ? '-' : (batch_line7?.NUMBER_OF_BATCH).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 30, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;Batch
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 52, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {kg_instock_line7 == undefined ? '-' : Math.round(kg_instock_line7?.KG_IN_STOCK).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;kg
                                                        </Typography>
                                                    </Box>
                                                </ItemInstock>
                                            }

                                            {batch_line7?.NUMBER_OF_BATCH === 0 ? '' :
                                                <Grid item xs={12} style={{ paddingTop: 10 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <img src={Time} height="28px" />
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            &nbsp;{hour_line7 == undefined ? '-' : hour_line7?.HOUR}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "3vh", position: "relative", top: "0.1vw" }} >
                                                            hr&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            {minute_line7 == undefined ? '-' : minute_line7?.MINUTE}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                                                            min
                                                        </Typography>
                                                    </Box>
                                                </Grid>}
                                        </Grid>

                                        <Grid item xs={0.2}>

                                        </Grid>

                                        <Grid item xs={7.5}>
                                            <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                                                {slice_ready_line7.length === 0
                                                    ? ''
                                                    : slice_ready_line7.map((item) => {
                                                        return <Grid item xs={6} style={{ paddingRight: 3 }}>
                                                            <ItemReady>
                                                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                    <Grid item xs={6} textAlign="left">
                                                                        <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.neutral['grey'], fontSize: 38, lineHeight: "5vh", position: "relative", top: "0vw" }} >
                                                                            &nbsp;{item.BATCH}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {(batch_line7_page1?.CURRENT_BATCH === '' ? 0 : batch_line7_page1?.CURRENT_BATCH) === item.BATCH ?
                                                                        <Grid item xs={6} style={{ position: "relative", top: "0.4vw" }} textAlign="right">
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>&nbsp;&nbsp;
                                                                        </Grid>
                                                                        : ''}

                                                                </Grid>
                                                                <Grid item xs={12} >
                                                                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                        <Grid item xs={3} textAlign="right">
                                                                        </Grid>
                                                                        <Grid item xs={9} textAlign="right">
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                                                                <Typography style={{ color: colors.neutral['white'], fontSize: 40, lineHeight: "5vh" }} >
                                                                                    {Math.round(item.KG).toLocaleString()}&nbsp;
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </ItemReady>
                                                        </Grid>
                                                    })}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ''}
                            </Grid>
                        </Item>
                    </Grid>

                    <Grid item xs={6}>
                        <Item>
                            <Grid item xs={12} style={{ height: '23vh' }}>
                                {batch_line8 !== undefined ?
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                        <Grid item xs={4.3}>
                                            <Grid item xs={12} textAlign="left">
                                                <Typography style={{ color: '#2e9281', fontSize: 40, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "-0.2vw" }} >
                                                    &nbsp;Line 8
                                                </Typography>
                                            </Grid>
                                            {batch_line8?.NUMBER_OF_BATCH === 0 ? '' :
                                                <ItemInstock>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {batch_line8 == undefined ? '-' : (batch_line8?.NUMBER_OF_BATCH).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 30, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;Batch
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 52, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {kg_instock_line8 == undefined ? '-' : Math.round(kg_instock_line8?.KG_IN_STOCK).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;kg
                                                        </Typography>
                                                    </Box>
                                                </ItemInstock>
                                            }

                                            {batch_line8?.NUMBER_OF_BATCH === 0 ? '' :
                                                <Grid item xs={12} style={{ paddingTop: 10 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <img src={Time} height="28px" />
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            &nbsp;{hour_line8 == undefined ? '-' : hour_line8?.HOUR}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "3vh", position: "relative", top: "0.1vw" }} >
                                                            hr&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            {minute_line8 == undefined ? '-' : minute_line8?.MINUTE}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                                                            min
                                                        </Typography>
                                                    </Box>
                                                </Grid>}
                                        </Grid>

                                        <Grid item xs={0.2}>

                                        </Grid>

                                        <Grid item xs={7.5}>
                                            <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                                                {slice_ready_line8.length === 0
                                                    ? ''
                                                    : slice_ready_line8.map((item) => {
                                                        return <Grid item xs={6} style={{ paddingRight: 3 }}>
                                                            <ItemReady>
                                                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                    <Grid item xs={6} textAlign="left">
                                                                        <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.neutral['grey'], fontSize: 38, lineHeight: "5vh", position: "relative", top: "0vw" }} >
                                                                            &nbsp;{item.BATCH}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {(batch_line8_page1?.CURRENT_BATCH === '' ? 0 : batch_line8_page1?.CURRENT_BATCH) === item.BATCH ?
                                                                        <Grid item xs={6} style={{ position: "relative", top: "0.4vw" }} textAlign="right">
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>&nbsp;&nbsp;
                                                                        </Grid>
                                                                        : ''}

                                                                </Grid>
                                                                <Grid item xs={12} >
                                                                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                        <Grid item xs={3} textAlign="right">
                                                                        </Grid>
                                                                        <Grid item xs={9} textAlign="right">
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                                                                <Typography style={{ color: colors.neutral['white'], fontSize: 40, lineHeight: "5vh" }} >
                                                                                    {Math.round(item.KG).toLocaleString()}&nbsp;
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </ItemReady>
                                                        </Grid>
                                                    })}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ''}
                            </Grid>
                        </Item>
                    </Grid>

                    <Grid item xs={6}>
                        <Item>
                            <Grid item xs={12} style={{ height: '23vh' }}>
                                {batch_line9 !== undefined ?
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                        <Grid item xs={4.3}>
                                            <Grid item xs={12} textAlign="left">
                                                <Typography style={{ color: '#2e9281', fontSize: 40, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "-0.2vw" }} >
                                                    &nbsp;Line 9
                                                </Typography>
                                            </Grid>
                                            {batch_line9?.NUMBER_OF_BATCH === 0 ? '' :
                                                <ItemInstock>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {batch_line9 == undefined ? '-' : (batch_line9?.NUMBER_OF_BATCH).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 30, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;Batch
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 52, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {kg_instock_line9 == undefined ? '-' : Math.round(kg_instock_line9?.KG_IN_STOCK).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;kg
                                                        </Typography>
                                                    </Box>
                                                </ItemInstock>
                                            }

                                            {batch_line9?.NUMBER_OF_BATCH === 0 ? '' :
                                                <Grid item xs={12} style={{ paddingTop: 10 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <img src={Time} height="28px" />
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            &nbsp;{hour_line9 == undefined ? '-' : hour_line9?.HOUR}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "3vh", position: "relative", top: "0.1vw" }} >
                                                            hr&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            {minute_line9 == undefined ? '-' : minute_line9?.MINUTE}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                                                            min
                                                        </Typography>
                                                    </Box>
                                                </Grid>}
                                        </Grid>

                                        <Grid item xs={0.2}>

                                        </Grid>

                                        <Grid item xs={7.5}>
                                            <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                                                {slice_ready_line9.length === 0
                                                    ? ''
                                                    : slice_ready_line9.map((item) => {
                                                        return <Grid item xs={6} style={{ paddingRight: 3 }}>
                                                            <ItemReady>
                                                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                    <Grid item xs={6} textAlign="left">
                                                                        <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.neutral['grey'], fontSize: 38, lineHeight: "5vh", position: "relative", top: "0vw" }} >
                                                                            &nbsp;{item.BATCH}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {(batch_line9_page1?.CURRENT_BATCH === '' ? 0 : batch_line9_page1?.CURRENT_BATCH) === item.BATCH ?
                                                                        <Grid item xs={6} style={{ position: "relative", top: "0.4vw" }} textAlign="right">
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>&nbsp;&nbsp;
                                                                        </Grid>
                                                                        : ''}

                                                                </Grid>
                                                                <Grid item xs={12} >
                                                                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                        <Grid item xs={3} textAlign="right">
                                                                        </Grid>
                                                                        <Grid item xs={9} textAlign="right">
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                                                                <Typography style={{ color: colors.neutral['white'], fontSize: 40, lineHeight: "5vh" }} >
                                                                                    {Math.round(item.KG).toLocaleString()}&nbsp;
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </ItemReady>
                                                        </Grid>
                                                    })}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ''}
                            </Grid>
                        </Item>
                    </Grid>

                    <Grid item xs={6}>
                        <Item>
                            <Grid item xs={12} style={{ height: '23vh' }}>
                                {batch_line10 !== undefined ?
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                        <Grid item xs={4.3}>
                                            <Grid item xs={12} textAlign="left">
                                                <Typography style={{ color: '#2e9281', fontSize: 40, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "-0.2vw" }} >
                                                    &nbsp;Line 10
                                                </Typography>
                                            </Grid>
                                            {batch_line10?.NUMBER_OF_BATCH === 0 ? '' :
                                                <ItemInstock>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {batch_line10 == undefined ? '-' : (batch_line10?.NUMBER_OF_BATCH).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 30, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;Batch
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 52, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {kg_instock_line10 == undefined ? '-' : Math.round(kg_instock_line10?.KG_IN_STOCK).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;kg
                                                        </Typography>
                                                    </Box>
                                                </ItemInstock>
                                            }

                                            {batch_line10?.NUMBER_OF_BATCH === 0 ? '' :
                                                <Grid item xs={12} style={{ paddingTop: 10 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <img src={Time} height="28px" />
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            &nbsp;{hour_line10 == undefined ? '-' : hour_line10?.HOUR}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "3vh", position: "relative", top: "0.1vw" }} >
                                                            hr&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            {minute_line10 == undefined ? '-' : minute_line10?.MINUTE}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                                                            min
                                                        </Typography>
                                                    </Box>
                                                </Grid>}
                                        </Grid>

                                        <Grid item xs={0.2}>

                                        </Grid>

                                        <Grid item xs={7.5}>
                                            <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                                                {slice_ready_line10.length === 0
                                                    ? ''
                                                    : slice_ready_line10.map((item) => {
                                                        return <Grid item xs={6} style={{ paddingRight: 3 }}>
                                                            <ItemReady>
                                                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                    <Grid item xs={6} textAlign="left">
                                                                        <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.neutral['grey'], fontSize: 38, lineHeight: "5vh", position: "relative", top: "0vw" }} >
                                                                            &nbsp;{item.BATCH}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {(batch_line10_page1?.CURRENT_BATCH === '' ? 0 : batch_line10_page1?.CURRENT_BATCH) === item.BATCH ?
                                                                        <Grid item xs={6} style={{ position: "relative", top: "0.4vw" }} textAlign="right">
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>&nbsp;&nbsp;
                                                                        </Grid>
                                                                        : ''}

                                                                </Grid>
                                                                <Grid item xs={12} >
                                                                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                        <Grid item xs={3} textAlign="right">
                                                                        </Grid>
                                                                        <Grid item xs={9} textAlign="right">
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                                                                <Typography style={{ color: colors.neutral['white'], fontSize: 40, lineHeight: "5vh" }} >
                                                                                    {Math.round(item.KG).toLocaleString()}&nbsp;
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </ItemReady>
                                                        </Grid>
                                                    })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ''}
                            </Grid>
                        </Item>
                    </Grid>

                    <Grid item xs={6}>
                        <Item>
                            <Grid item xs={12} style={{ height: '23vh' }}>
                                {batch_line11 !== undefined ?
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                        <Grid item xs={4.3}>
                                            <Grid item xs={12} textAlign="left">
                                                <Typography style={{ color: '#2e9281', fontSize: 40, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "-0.2vw" }} >
                                                    &nbsp;Line 11
                                                </Typography>
                                            </Grid>
                                            {batch_line11?.NUMBER_OF_BATCH === 0 ? '' :
                                                <ItemInstock>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {batch_line11 == undefined ? '-' : (batch_line11?.NUMBER_OF_BATCH).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 30, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;Batch
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 52, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {kg_instock_line11 == undefined ? '-' : Math.round(kg_instock_line11?.KG_IN_STOCK).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;kg
                                                        </Typography>
                                                    </Box>
                                                </ItemInstock>
                                            }

                                            {batch_line11?.NUMBER_OF_BATCH === 0 ? '' :
                                                <Grid item xs={12} style={{ paddingTop: 10 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <img src={Time} height="28px" />
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            &nbsp;{hour_line11 == undefined ? '-' : hour_line11?.HOUR}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "3vh", position: "relative", top: "0.1vw" }} >
                                                            hr&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            {minute_line11 == undefined ? '-' : minute_line11?.MINUTE}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                                                            min
                                                        </Typography>
                                                    </Box>
                                                </Grid>}
                                        </Grid>

                                        <Grid item xs={0.2}>

                                        </Grid>

                                        <Grid item xs={7.5}>
                                            <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                                                {slice_ready_line11.length === 0
                                                    ? ''
                                                    : slice_ready_line11.map((item) => {
                                                        return <Grid item xs={6} style={{ paddingRight: 3 }}>
                                                            <ItemReady>
                                                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                    <Grid item xs={6} textAlign="left">
                                                                        <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.neutral['grey'], fontSize: 38, lineHeight: "5vh", position: "relative", top: "0vw" }} >
                                                                            &nbsp;{item.BATCH}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {(batch_line11_page1?.CURRENT_BATCH === '' ? 0 : batch_line11_page1?.CURRENT_BATCH) === item.BATCH ?
                                                                        <Grid item xs={6} style={{ position: "relative", top: "0.4vw" }} textAlign="right">
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>&nbsp;&nbsp;
                                                                        </Grid>
                                                                        : ''}

                                                                </Grid>
                                                                <Grid item xs={12} >
                                                                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                        <Grid item xs={3} textAlign="right">
                                                                        </Grid>
                                                                        <Grid item xs={9} textAlign="right">
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                                                                <Typography style={{ color: colors.neutral['white'], fontSize: 40, lineHeight: "5vh" }} >
                                                                                    {Math.round(item.KG).toLocaleString()}&nbsp;
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </ItemReady>
                                                        </Grid>
                                                    })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ''}
                            </Grid>
                        </Item>
                    </Grid>

                    <Grid item xs={6}>
                        <Item>
                            <Grid item xs={12} style={{ height: '23vh' }}>
                                {batch_line12 !== undefined ?
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                        <Grid item xs={4.3}>
                                            <Grid item xs={12} textAlign="left">
                                                <Typography style={{ color: '#2e9281', fontSize: 40, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "-0.2vw" }} >
                                                    &nbsp;Line 12
                                                </Typography>
                                            </Grid>
                                            {batch_line12?.NUMBER_OF_BATCH === 0 ? '' :
                                                <ItemInstock>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 46, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {batch_line12 == undefined ? '-' : (batch_line12?.NUMBER_OF_BATCH).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 30, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;Batch
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 52, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                            {kg_instock_line12 == undefined ? '-' : Math.round(kg_instock_line12?.KG_IN_STOCK).toLocaleString()}
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "6vh", position: "relative", top: "0.3vw" }} >
                                                            &nbsp;kg
                                                        </Typography>
                                                    </Box>
                                                </ItemInstock>
                                            }

                                            {batch_line12?.NUMBER_OF_BATCH === 0 ? '' :
                                                <Grid item xs={12} style={{ paddingTop: 10 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <img src={Time} height="28px" />
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            &nbsp;{hour_line12 == undefined ? '-' : hour_line12?.HOUR}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "3vh", position: "relative", top: "0.1vw" }} >
                                                            hr&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#74dcbc', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                            {minute_line12 == undefined ? '-' : minute_line12?.MINUTE}&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                                                            min
                                                        </Typography>
                                                    </Box>
                                                </Grid>}
                                        </Grid>

                                        <Grid item xs={0.2}>

                                        </Grid>

                                        <Grid item xs={7.5}>
                                            <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                                                {slice_ready_line12.length === 0
                                                    ? ''
                                                    : slice_ready_line12.map((item) => {
                                                        return <Grid item xs={6} style={{ paddingRight: 3 }}>
                                                            <ItemReady>
                                                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                    <Grid item xs={6} textAlign="left">
                                                                        <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.neutral['grey'], fontSize: 38, lineHeight: "5vh", position: "relative", top: "0vw" }} >
                                                                            &nbsp;{item.BATCH}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {(batch_line12_page1?.CURRENT_BATCH === '' ? 0 : batch_line12_page1?.CURRENT_BATCH) === item.BATCH ?
                                                                        <Grid item xs={6} style={{ position: "relative", top: "0.4vw" }} textAlign="right">
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>
                                                                            <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 18 }}></LensIcon>&nbsp;&nbsp;
                                                                        </Grid>
                                                                        : ''}

                                                                </Grid>
                                                                <Grid item xs={12} >
                                                                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                                                        <Grid item xs={3} textAlign="right">
                                                                        </Grid>
                                                                        <Grid item xs={9} textAlign="right">
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                                                                <Typography style={{ color: colors.neutral['white'], fontSize: 40, lineHeight: "5vh" }} >
                                                                                    {Math.round(item.KG).toLocaleString()}&nbsp;
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </ItemReady>
                                                        </Grid>
                                                    })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ''}
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>

            </Grid>

        </>
    )
}

export default MarinationTrackingPage2

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { apiUrl } from "../../constants";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    Grid,
    TextField,
    Stack,
    Snackbar,
    Alert
} from '@material-ui/core';
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";


function StandardSettingEdit(props) {
    const exchangeToken = useExchangeToken();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    // กำหนดค่าเริ่มต้น
    const [values, setValues] = useState({
        plant_id: '',
        factory: '',
        line: '',
        material_description: '',
        belt_scale_feed_rate: '',
        multihead_upper_limit: '',
        multihead_auto_pack: '',
        multihead_target_giveaway: '',
        multihead_target_pack: '',
        multihead_upper_weight: '',
        multihead_speed_target: '',
        multihead_df_weight: '',
        check_on: ''
    });
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);
    const [error, setError] = useState(null);

    useCheckToken();

    const ac_token = useSelector(state => state.auth.access_token);


    useEffect(() => {
        const exchange = async () => {
            if (!isTokenExchanged) {
                try {
                    await exchangeToken(); // แลกเปลี่ยนโทเค็น
                    setIsTokenExchanged(true);
                } catch (err) {
                    console.error("Error exchanging token:", err);
                    setError("Failed to exchange token");
                }
            }
        };

        exchange();
    }, [isTokenExchanged, exchangeToken]);



    // will be trigerred every time user change input field set state of that value
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };


    useEffect(() => {
        if (isTokenExchanged && ac_token) {
            async function fetchMyAPI() {
                const url_string = window.location.href
                var url = new URL(url_string);
                var id = Number(url.searchParams.get("id"));
                const GetUserById = async () => {
                    var urls = `${apiUrl.GET_MASTER_STD}get/${sessionStorage.getItem('master_std_plant')}/00000000000000/`
                    try {
                        const result = await axios({
                            method: "get", //you can set what request you want to be
                            url: urls,
                            headers: {
                                Authorization: `Bearer ${ac_token}`,
                                // "X-xss-protection": "1; mode=block;",
                                // "X-frame-options": "SAMEORIGIN",
                                // "X-content-type-options": "nosniff",
                                // "Cache-Control": "no-cache, private",
                                // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                                // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                            }
                        });
                        return result.data.filter(i => i.id === id);
                    } catch (error) {
                        console.log("Error fetching user:", error);
                    }
                }
                var result = await GetUserById();
                setValues(result[0])
            }
            fetchMyAPI()
        }
    }, [ac_token, isTokenExchanged]);

    delete values.multihead_performance




    // will be triggered when uer click submit button and patch user detail
    const handleSubmit = async () => {
        // สร้างสำเนาของ values และแปลงค่าที่เป็น " " ให้เป็น 0
        const updatedValues = {
            ...values,
            ...Object.fromEntries(
                Object.entries(values).map(([key, value]) => [key, value === " " ? 0 : value])
            )
        };

        console.log(updatedValues); // ตรวจสอบค่าที่จะส่งไปยัง API

        var url_edit = `${apiUrl.GET_MASTER_STD}update/${updatedValues.check_on}/`;

        await axios.patch(url_edit, updatedValues, {
            headers: {
                Authorization: `Bearer ${ac_token}`,
                // "X-xss-protection": "1; mode=block;",
                // "X-frame-options": "SAMEORIGIN",
                // "X-content-type-options": "nosniff",
                // "Cache-Control": "no-cache, private",
                // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
            }
        })
            .then(response => {
                if (response.status === 200) {
                    setOpen(true);
                    setTimeout(() => {
                        navigate(`/list-master-std`, { replace: true });
                    }, 2000);
                } else {
                    setOpen2(true);
                }
            })
            .catch(error => {
                setOpen2(true);
                console.error(error);
            });
    };



    // back to userlist page
    const handleBack = () => {
        navigate(`/list-master-std`, { replace: true });
    }

    return (
        <Box style={{ width: "100%", height: "93.5vh" }}>
            <Helmet>
                <title>Edit | MES </title>
            </Helmet>
            <form
                autoComplete="off"
                noValidate
                {...props}
            >
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={open} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert severity="success" sx={{ width: '100%' }}>
                            The changes have been saved successfully.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={open2} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert severity="error" sx={{ width: '100%' }}>
                            Failed to save changes.
                        </Alert>
                    </Snackbar>
                </Stack>
                <Card sx={{ ml: 2, mt: 2, mr: 2 }}>
                    <Box sx={{ mb: 3, mt: 0, ml: 2 }}>
                        <Typography color="textPrimary" style={{ fontSize: 56 }}>
                            Edit Master STD
                        </Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            style={{ fontSize: 20 }}
                        >
                            The information can be edited
                        </Typography>
                    </Box>
                    <Divider />

                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Plant"
                                    name="plant_id"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.plant_id === " ") {
                                            setValues({ ...values, plant_id: 0 });
                                        }
                                    }}
                                    required
                                    value={values.plant_id}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Factory"
                                    name="factory"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.factory === " ") {
                                            setValues({ ...values, factory: 0 });
                                        }
                                    }}
                                    required
                                    value={values.factory}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Line"
                                    name="line"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.line === " ") {
                                            setValues({ ...values, line: 0 });
                                        }
                                    }}
                                    required
                                    value={values.line}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="SKU"
                                    name="material_description"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.material_description === " ") {
                                            setValues({ ...values, material_description: 0 });
                                        }
                                    }}
                                    required
                                    value={values.material_description}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    type="number"
                                    fullWidth
                                    label="Feed rate"
                                    name="belt_scale_feed_rate"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.belt_scale_feed_rate === " ") {
                                            setValues({ ...values, belt_scale_feed_rate: 0 });
                                        }
                                    }}
                                    required
                                    value={values.belt_scale_feed_rate}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    type="number"
                                    fullWidth
                                    label="Multihead upper limit"
                                    name="multihead_upper_limit"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.multihead_upper_limit === " ") {
                                            setValues({ ...values, multihead_upper_limit: 0 });
                                        }
                                    }}
                                    required
                                    value={values.multihead_upper_limit}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    type="number"
                                    fullWidth
                                    label="Multihead auto pack"
                                    name="multihead_auto_pack"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.multihead_auto_pack === " ") {
                                            setValues({ ...values, multihead_auto_pack: 0 });
                                        }
                                    }}
                                    required
                                    value={values.multihead_auto_pack}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    type="number"
                                    fullWidth
                                    label="Multihead target giveaway"
                                    name="multihead_target_giveaway"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.multihead_target_giveaway === " ") {
                                            setValues({ ...values, multihead_target_giveaway: 0 });
                                        }
                                    }}
                                    required
                                    value={values.multihead_target_giveaway}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    type="number"
                                    fullWidth
                                    label="Multihead target pack"
                                    name="multihead_target_pack"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.multihead_target_pack === " ") {
                                            setValues({ ...values, multihead_target_pack: 0 });
                                        }
                                    }}
                                    required
                                    value={values.multihead_target_pack}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    type="number"
                                    fullWidth
                                    label="Multihead upper weight"
                                    name="multihead_upper_weight"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.multihead_upper_weight === " ") {
                                            setValues({ ...values, multihead_upper_weight: 0 });
                                        }
                                    }}
                                    required
                                    value={values.multihead_upper_weight}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Multihead speed target"
                                    name="multihead_speed_target"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.multihead_speed_target === " ") {
                                            setValues({ ...values, multihead_speed_target: 0 });
                                        }
                                    }}
                                    required
                                    value={values.multihead_speed_target}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    type="number"
                                    fullWidth
                                    label="Multihead df weight"
                                    name="multihead_df_weight"
                                    onChange={handleChange}
                                    onBlur={() => {
                                        if (values.multihead_df_weight === " ") {
                                            setValues({ ...values, multihead_df_weight: 0 });
                                        }
                                    }}
                                    required
                                    value={values.multihead_df_weight}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#000000",
                                            fontFamily: 'CPF_Imm_Sook',
                                            //fontWeight: "bold",
                                            fontSize: 18
                                        },
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >

                        <Button
                            style={{ background: '#575755', color: "white", fontSize: 18 }}
                            variant="contained"
                            onClick={handleBack}
                            sx={{ mr: 3 }}
                        >
                            Back to master std
                        </Button>

                        <Button
                            style={{ background: '#009688', color: "white", fontSize: 18 }}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Save details
                        </Button>
                    </Box>
                </Card>
            </form>
        </Box>
    );
}

StandardSettingEdit.propTypes = {};

export default StandardSettingEdit;


import React, { useRef, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth'; // Adjust the import path to your actual AuthContext file

const AuthStateTransfer = () => {
  const { init } = useAuth();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      init();
      initialized.current = true;
    }
  }, [init]);

  return null;
};

export default AuthStateTransfer;

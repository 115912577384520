import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import db from "../firebase-config";
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import HeaderMarinationTrack from '../components/header/HeaderMarinationTracking';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import BarChartRoomStatus from '../components/chart/BarChartRoomStatus';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    border: '1.5px dashed #2e9281',
    borderRadius: 10,
    height: '102%'

}));

const ItemSummary = styled(Paper)(({ theme }) => ({
    backgroundColor: '#18443c',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 0,
    //height: '100%'

}));

const ItemTotal = styled(Paper)(({ theme }) => ({
    backgroundColor: '#c4e4dc',
    padding: theme.spacing(0.3),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 5,
    height: '100%',
    //width: '102%',
}));

const ItemNumber = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 5,
    height: '100%',
    //idth: '105%',
}));

const ItemLine = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(0.6),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 5,
    //height: '70%',
}));

const ItemRoomStatus = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(1.8),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 5,
    //height: '30px',
}));

const ItemTotalBarrel = styled(Paper)(({ theme }) => ({
    backgroundColor: '#302c2c',
    padding: theme.spacing(0.2),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 5,
    height: '50%',
}));


const MarinationTrackingPage1 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [marinate_page1, setMarinate_page1] = useState([])


    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_preparation_zone_page1")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                setMarinate_page1(getPostFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [marinate_page1]);

    const overall_total_kg_instock_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7OVERALL_TOTAL_KG_IN_STOCK"));

    const overall_total_kg_instock_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8OVERALL_TOTAL_KG_IN_STOCK"));

    const overall_total_kg_instock_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9OVERALL_TOTAL_KG_IN_STOCK"));

    const overall_total_kg_instock_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10OVERALL_TOTAL_KG_IN_STOCK"));

    const overall_total_kg_instock_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11OVERALL_TOTAL_KG_IN_STOCK"));

    const overall_total_kg_instock_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12OVERALL_TOTAL_KG_IN_STOCK"));


    const total_kg_processing_line7_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

    const total_kg_processing_line8_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

    const total_kg_processing_line9_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

    const total_kg_processing_line10_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

    const total_kg_processing_line11_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

    const total_kg_processing_line12_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));


    const sku_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_SKU"));

    const sku_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_SKU"));

    const sku_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_SKU"));

    const sku_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_SKU"));

    const sku_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_SKU"));

    const sku_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_SKU"));


    const batch_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_BATCH"));

    const batch_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_BATCH"));

    const batch_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_BATCH"));

    const batch_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_BATCH"));

    const batch_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_BATCH"));

    const batch_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_BATCH"));


    const total_barrel_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_BARREL"));

    const total_barrel_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_BARREL"));

    const total_barrel_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_BARREL"));

    const total_barrel_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_BARREL"));

    const total_barrel_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_BARREL"));

    const total_barrel_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_BARREL"));


    const total_kg_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG"));

    const total_kg_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG"));

    const total_kg_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG"));

    const total_kg_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG"));

    const total_kg_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG"));

    const total_kg_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG"));


    const total_barrel_processing_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

    const total_barrel_processing_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

    const total_barrel_processing_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

    const total_barrel_processing_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

    const total_barrel_processing_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

    const total_barrel_processing_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_BARREL_PROCESSING_AND_COMPLETED"));


    const total_kg_processing_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG_PROCESSING_AND_COMPLETED"));

    const total_kg_processing_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG_PROCESSING_AND_COMPLETED"));

    const total_kg_processing_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG_PROCESSING_AND_COMPLETED"));

    const total_kg_processing_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG_PROCESSING_AND_COMPLETED"));

    const total_kg_processing_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG_PROCESSING_AND_COMPLETED"));

    const total_kg_processing_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG_PROCESSING_AND_COMPLETED"));


    const total_barrel_instock_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_BARREL_IN_STOCK"));

    const total_barrel_instock_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_BARREL_IN_STOCK"));

    const total_barrel_instock_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_BARREL_IN_STOCK"));

    const total_barrel_instock_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_BARREL_IN_STOCK"));

    const total_barrel_instock_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_BARREL_IN_STOCK"));

    const total_barrel_instock_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_BARREL_IN_STOCK"));


    const total_kg_instock_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG_IN_STOCK"));

    const total_kg_instock_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG_IN_STOCK"));

    const total_kg_instock_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG_IN_STOCK"));

    const total_kg_instock_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG_IN_STOCK"));

    const total_kg_instock_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG_IN_STOCK"));

    const total_kg_instock_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG_IN_STOCK"));


    const processingLines = [
        total_kg_processing_line7_sum,
        total_kg_processing_line8_sum,
        total_kg_processing_line9_sum,
        total_kg_processing_line10_sum,
        total_kg_processing_line11_sum,
        total_kg_processing_line12_sum
    ];
    
    const sum_total_kg_processing = processingLines.reduce((sum, line) => {
        const value = line?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY;
        return sum + (value === '' || value == null ? 0 : value);
    }, 0);


    const instockLines = [
        overall_total_kg_instock_line7,
        overall_total_kg_instock_line8,
        overall_total_kg_instock_line9,
        overall_total_kg_instock_line10,
        overall_total_kg_instock_line11,
        overall_total_kg_instock_line12
    ];
    
    const sum_overall_total_kg_instock = instockLines.reduce((sum, line) => {
        const value = line?.OVERALL_TOTAL_KG_IN_STOCK;
        return sum + (value === '' || value == null ? 0 : Number(value));
    }, 0);


    const sum_instock_processing = Math.round(sum_total_kg_processing) + Math.round(sum_overall_total_kg_instock)



    const overall_total_barrel_instock_line7 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7OVERALL_TOTAL_BARREL_IN_STOCK"));

    const overall_total_barrel_instock_line8 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8OVERALL_TOTAL_BARREL_IN_STOCK"));

    const overall_total_barrel_instock_line9 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9OVERALL_TOTAL_BARREL_IN_STOCK"));

    const overall_total_barrel_instock_line10 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10OVERALL_TOTAL_BARREL_IN_STOCK"));

    const overall_total_barrel_instock_line11 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11OVERALL_TOTAL_BARREL_IN_STOCK"));

    const overall_total_barrel_instock_line12 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12OVERALL_TOTAL_BARREL_IN_STOCK"));


    const sum_overall_total_barrel_instock = overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK





    return (
        <>
            <Helmet>
                <title>Marination Tracking Page1 | MES</title>
            </Helmet>
            <Grid container spacing={1} style={{position: "relative", top: '-1.5vw'}}>
                <Grid item xs={12} sm={12}>
                    <HeaderMarinationTrack
                    />
                </Grid>
                <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }} style={{ backgroundColor: '#000000', paddingLeft: 10 }}>
                    <Grid item xs={3} style={{ height: '284px', paddingTop: 10 }}>
                        <ItemSummary>
                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 54, fontWeight: 'bold', lineHeight: "5vh" }} >
                                        Total&nbsp;
                                    </Typography>
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 40, fontWeight: 'bold', lineHeight: "5vh", paddingTop: 3 }} >
                                        (kg)
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingRight: 0, paddingLeft: 20 }}>
                                <ItemTotal>
                                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                                        <Typography style={{ color: colors.neutral['black'], fontSize: 54, fontWeight: 'bold', lineHeight: "5vh", position: "relative", top: "0vw" }} >
                                            {total_kg_processing_line7_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined ? 0 : sum_instock_processing.toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 2 }}>
                                        <ItemNumber>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                <Typography style={{ color: '#b58514', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                    {(total_kg_processing_line7_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line8_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line9_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line10_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line11_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line12_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined)
                                                        ? 0 : Math.round(sum_total_kg_processing).toLocaleString()}
                                                </Typography>
                                                <Typography style={{ color: '#b58514', fontSize: 48, fontWeight: 'bold', lineHeight: "5vh", position: "relative", top: "0vw" }} >
                                                    &nbsp;:&nbsp;
                                                </Typography>
                                                <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                    {(overall_total_kg_instock_line7?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line8?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line9?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line10?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line11?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line12?.OVERALL_TOTAL_KG_IN_STOCK === undefined)
                                                        ? 0 : Math.round(sum_overall_total_kg_instock).toLocaleString()}
                                                </Typography>
                                            </Box>
                                        </ItemNumber>
                                    </Grid>
                                </ItemTotal>
                            </Grid>

                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingLeft: 20 }}>
                                <Grid item xs={12} style={{ paddingTop: 5 }}>
                                    <Grid container rowSpacing={1.5} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                        <Grid item xs={6} style={{ paddingRight: 3 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 28, lineHeight: "3vh" }} >
                                                    Line
                                                </Typography>
                                                <Typography style={{ color: '#84d8c8', fontSize: 32, lineHeight: "3vh", paddingTop: 0, fontWeight: 'bold' }} >
                                                    7
                                                </Typography>
                                            </Box>
                                            <ItemLine>
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "0vw" }} textAlign="right">
                                                    {overall_total_kg_instock_line7?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? 0 : Math.round(overall_total_kg_instock_line7?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                                                </Typography>
                                            </ItemLine>
                                        </Grid>

                                        <Grid item xs={6} >
                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 28, lineHeight: "3vh", paddingTop: 1 }} >
                                                    Line
                                                </Typography>
                                                <Typography style={{ color: '#f48c94', fontSize: 32, lineHeight: "3vh", paddingTop: 0, fontWeight: 'bold' }} >
                                                    8
                                                </Typography>
                                            </Box>
                                            <ItemLine>
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "0vw" }} textAlign="right">
                                                    {overall_total_kg_instock_line8?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? 0 : Math.round(overall_total_kg_instock_line8?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                                                </Typography>
                                            </ItemLine>
                                        </Grid>

                                        <Grid item xs={6} style={{ paddingRight: 3 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 28, lineHeight: "3vh", paddingTop: 1 }} >
                                                    Line
                                                </Typography>
                                                <Typography style={{ color: '#fbb2a4', fontSize: 32, lineHeight: "3vh", paddingTop: 0, fontWeight: 'bold' }} >
                                                    9
                                                </Typography>
                                            </Box>
                                            <ItemLine>
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "0vw" }} textAlign="right">
                                                    {overall_total_kg_instock_line9?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? 0
                                                        : Math.round(overall_total_kg_instock_line9?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                                                </Typography>
                                            </ItemLine>
                                        </Grid>

                                        <Grid item xs={6} >
                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 28, lineHeight: "3vh", paddingTop: 1 }} >
                                                    Line
                                                </Typography>
                                                <Typography style={{ color: '#a4aad4', fontSize: 32, lineHeight: "3vh", paddingTop: 0, fontWeight: 'bold' }} >
                                                    10
                                                </Typography>
                                            </Box>
                                            <ItemLine>
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "0vw" }} textAlign="right">
                                                    {overall_total_kg_instock_line10?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? 0 : Math.round(overall_total_kg_instock_line10?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                                                </Typography>
                                            </ItemLine>
                                        </Grid>

                                        <Grid item xs={6} style={{ paddingRight: 3 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 28, lineHeight: "3vh", paddingTop: 1 }} >
                                                    Line
                                                </Typography>
                                                <Typography style={{ color: '#ddd564', fontSize: 32, lineHeight: "3vh", paddingTop: 0, fontWeight: 'bold' }} >
                                                    11
                                                </Typography>
                                            </Box>
                                            <ItemLine>
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "0vw" }} textAlign="right">
                                                    {overall_total_kg_instock_line11?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? 0 : Math.round(overall_total_kg_instock_line11?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                                                </Typography>
                                            </ItemLine>
                                        </Grid>

                                        <Grid item xs={6} >
                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 28, lineHeight: "3vh", paddingTop: 1 }} >
                                                    Line
                                                </Typography>
                                                <Typography style={{ color: '#94cbcc', fontSize: 32, lineHeight: "3vh", paddingTop: 0, fontWeight: 'bold' }} >
                                                    12
                                                </Typography>
                                            </Box>
                                            <ItemLine>
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "0vw" }} textAlign="right">
                                                    {overall_total_kg_instock_line12?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? 0 : Math.round(overall_total_kg_instock_line12?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                                                </Typography>
                                            </ItemLine>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30, paddingLeft: 20 }}>
                                <Typography style={{ color: colors.neutral['white'], fontSize: 36, fontWeight: 'bold', lineHeight: "4vh", position: "relative", top: "0vw" }} >
                                    Room Status
                                </Typography>
                                <Grid item xs={12} textAlign="center" style={{ paddingTop: 8 }}>
                                    <ItemRoomStatus>
                                        <BarChartRoomStatus
                                            total_instock={(overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined)
                                                ? 0 : Math.round(sum_overall_total_barrel_instock)}
                                            line7={overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? 0 : Math.round(overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                                            line8={overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? 0 : Math.round(overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                                            line9={overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? 0 : Math.round(overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                                            line10={overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? 0 : Math.round(overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                                            line11={overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? 0 : Math.round(overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                                            line12={overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? 0 : Math.round(overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                                            remaining={(overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined)
                                                ? 96 - 0 : 96 - Math.round(sum_overall_total_barrel_instock)}
                                            actual={(overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined)
                                                ? 0 : Math.round(sum_overall_total_barrel_instock)}
                                            height='60%'
                                        />

                                    </ItemRoomStatus>
                                </Grid>
                            </Grid>

                        </ItemSummary>
                    </Grid>
                    <Grid item xs={9} style={{ paddingTop: 10 }}>
                        <Grid container rowSpacing={1.5} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
                            <Grid item xs={4} style={{ paddingRight: 3, height: '38vh' }} >
                                <Item>
                                    <Grid item xs={12} textAlign="left" style={{ paddingTop: 10 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                L
                                            </Typography>
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                07&nbsp;
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 48, lineHeight: "2vh", position: "relative", top: "0vw" }} >
                                                {sku_line7?.CURRENT_SKU === undefined || sku_line7?.CURRENT_SKU === '' ? '' : sku_line7?.CURRENT_SKU}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {batch_line7?.CURRENT_BATCH === undefined
                                        ? ''
                                        : batch_line7?.CURRENT_BATCH === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemSummary>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            Batch :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            {batch_line7?.CURRENT_BATCH === undefined || batch_line7?.CURRENT_BATCH === '' ? 0 : batch_line7?.CURRENT_BATCH}
                                                        </Typography>
                                                    </Box>
                                                </ItemSummary>
                                            </Grid>
                                    }

                                    {total_barrel_line7?.TOTAL_BARREL === undefined
                                        ? ''
                                        : total_barrel_line7?.TOTAL_BARREL === '' || sessionStorage.getItem('total_barrel_line7') === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_barrel_line7?.TOTAL_BARREL === undefined || total_barrel_line7?.TOTAL_BARREL === '' ? 0 : total_barrel_line7?.TOTAL_BARREL}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        Bin&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_kg_line7?.TOTAL_KG === undefined || total_kg_line7?.TOTAL_KG === '' ? 0 : Math.round(total_kg_line7?.TOTAL_KG).toLocaleString()}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        kg&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined
                                        ? ''
                                        : total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? 0 : total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_kg_processing_line7?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line7?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? 0 : Math.round(total_kg_processing_line7?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK === undefined
                                        ? ''
                                        : total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 25, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemTotalBarrel>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK === '' ? 0 : total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_kg_instock_line7?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line7?.TOTAL_KG_IN_STOCK === '' ? 0 : Math.round(total_kg_instock_line7?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                                                        </Typography>
                                                    </Box>
                                                </ItemTotalBarrel>
                                            </Grid>
                                    }
                                </Item>
                            </Grid>

                            <Grid item xs={4} style={{ paddingRight: 3, height: '38vh' }} >
                                <Item>
                                    <Grid item xs={12} textAlign="left" style={{ paddingTop: 10 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                L
                                            </Typography>
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                08&nbsp;
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 48, lineHeight: "2vh", position: "relative", top: "0vw" }} >
                                                {sku_line8?.CURRENT_SKU === undefined ? '' : sku_line8?.CURRENT_SKU}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {batch_line8?.CURRENT_BATCH === undefined
                                        ? ''
                                        : batch_line8?.CURRENT_BATCH === '' || sessionStorage.getItem('batch_line8') === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemSummary>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            Batch :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            {batch_line8?.CURRENT_BATCH === undefined || batch_line8?.CURRENT_BATCH === '' ? 0 : batch_line8?.CURRENT_BATCH}
                                                        </Typography>
                                                    </Box>
                                                </ItemSummary>
                                            </Grid>
                                    }

                                    {total_barrel_line8?.TOTAL_BARREL === undefined
                                        ? ''
                                        : total_barrel_line8?.TOTAL_BARREL === '' || sessionStorage.getItem('total_barrel_line8') === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_barrel_line8?.TOTAL_BARREL === undefined || total_barrel_line8?.TOTAL_BARREL === '' ? 0 : total_barrel_line8?.TOTAL_BARREL}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        Bin&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_kg_line8?.TOTAL_KG === undefined || total_kg_line8?.TOTAL_KG === '' ? 0 : Math.round(total_kg_line8?.TOTAL_KG).toLocaleString()}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        kg&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined
                                        ? ''
                                        : total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? 0 : total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_kg_processing_line8?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line8?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? 0 : Math.round(total_kg_processing_line8?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK === undefined
                                        ? ''
                                        : total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK === '' || sessionStorage.getItem('total_barrel_instock_line8') === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 25, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemTotalBarrel>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK === '' ? 0 : total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_kg_instock_line8?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line8?.TOTAL_KG_IN_STOCK === '' ? 0 : Math.round(total_kg_instock_line8?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                                                        </Typography>
                                                    </Box>
                                                </ItemTotalBarrel>
                                            </Grid>
                                    }
                                </Item>
                            </Grid>

                            <Grid item xs={4} style={{ paddingRight: 3, height: '38vh' }} >
                                <Item>
                                    <Grid item xs={12} textAlign="left" style={{ paddingTop: 10 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                L
                                            </Typography>
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                09&nbsp;
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 48, lineHeight: "2vh", position: "relative", top: "0vw" }} >
                                                {sku_line9?.CURRENT_SKU === undefined ? '' : sku_line9?.CURRENT_SKU}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {batch_line9?.CURRENT_BATCH === undefined
                                        ? ''
                                        : batch_line9?.CURRENT_BATCH === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemSummary>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            Batch :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            {batch_line9?.CURRENT_BATCH === undefined || batch_line9?.CURRENT_BATCH === '' ? 0 : batch_line9?.CURRENT_BATCH}
                                                        </Typography>
                                                    </Box>
                                                </ItemSummary>
                                            </Grid>
                                    }

                                    {total_barrel_line9?.TOTAL_BARREL === undefined
                                        ? ''
                                        : total_barrel_line9?.TOTAL_BARREL === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_barrel_line9?.TOTAL_BARREL === undefined || total_barrel_line9?.TOTAL_BARREL === '' ? 0 : total_barrel_line7?.TOTAL_BARREL}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        Bin&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_kg_line9?.TOTAL_KG === undefined || total_kg_line9?.TOTAL_KG === '' ? 0 : Math.round(total_kg_line9?.TOTAL_KG).toLocaleString()}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        kg&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined
                                        ? ''
                                        : total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? 0 : total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_kg_processing_line9?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line9?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? 0 : Math.round(total_kg_processing_line9?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK === undefined
                                        ? ''
                                        : total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 25, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemTotalBarrel>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK === '' ? 0 : total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_kg_instock_line9?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line9?.TOTAL_KG_IN_STOCK === '' ? 0 : Math.round(total_kg_instock_line9?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                                                        </Typography>
                                                    </Box>
                                                </ItemTotalBarrel>
                                            </Grid>
                                    }
                                </Item>
                            </Grid>

                            <Grid item xs={4} style={{ paddingRight: 3, height: '38vh' }} >
                                <Item>
                                    <Grid item xs={12} textAlign="left" style={{ paddingTop: 10 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                L
                                            </Typography>
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                10&nbsp;
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 48, lineHeight: "2vh", position: "relative", top: "0vw" }} >
                                                {sku_line10?.CURRENT_SKU === undefined ? '' : sku_line10?.CURRENT_SKU}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {batch_line10?.CURRENT_BATCH === undefined
                                        ? ''
                                        : batch_line10?.CURRENT_BATCH === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemSummary>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            Batch :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            {batch_line10?.CURRENT_BATCH === undefined || batch_line10?.CURRENT_BATCH === '' ? 0 : batch_line10?.CURRENT_BATCH}
                                                        </Typography>
                                                    </Box>
                                                </ItemSummary>
                                            </Grid>
                                    }

                                    {total_barrel_line10?.TOTAL_BARREL === undefined
                                        ? ''
                                        : total_barrel_line10?.TOTAL_BARREL === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_barrel_line10?.TOTAL_BARREL === undefined || total_barrel_line10?.TOTAL_BARREL === '' ? 0 : total_barrel_line10?.TOTAL_BARREL}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        Bin&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_kg_line10?.TOTAL_KG === undefined || total_kg_line10?.TOTAL_KG === '' ? 0 : Math.round(total_kg_line10?.TOTAL_KG).toLocaleString()}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        kg&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined
                                        ? ''
                                        : total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? 0 : total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_kg_processing_line10?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line10?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? 0 : Math.round(total_kg_processing_line10?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK === undefined
                                        ? ''
                                        : total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 25, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemTotalBarrel>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK === '' ? 0 : total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_kg_instock_line10?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line10?.TOTAL_KG_IN_STOCK === '' ? 0 : Math.round(total_kg_instock_line10?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                                                        </Typography>
                                                    </Box>
                                                </ItemTotalBarrel>
                                            </Grid>
                                    }
                                </Item>
                            </Grid>

                            <Grid item xs={4} style={{ paddingRight: 3, height: '38vh' }} >
                                <Item>
                                    <Grid item xs={12} textAlign="left" style={{ paddingTop: 10 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                L
                                            </Typography>
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                11&nbsp;
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 48, lineHeight: "2vh", position: "relative", top: "0vw" }} >
                                                {sku_line11?.CURRENT_SKU === undefined ? '' : sku_line11?.CURRENT_SKU}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {batch_line11?.CURRENT_BATCH === undefined
                                        ? ''
                                        : batch_line11?.CURRENT_BATCH === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemSummary>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            Batch :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            {batch_line11?.CURRENT_BATCH === undefined || batch_line11?.CURRENT_BATCH === '' ? 0 : batch_line11?.CURRENT_BATCH}
                                                        </Typography>
                                                    </Box>
                                                </ItemSummary>
                                            </Grid>
                                    }

                                    {total_barrel_line11?.TOTAL_BARREL === undefined
                                        ? ''
                                        : total_barrel_line11?.TOTAL_BARREL === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_barrel_line11?.TOTAL_BARREL === undefined || total_barrel_line11?.TOTAL_BARREL === '' ? 0 : total_barrel_line11?.TOTAL_BARREL}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        Bin&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_kg_line11?.TOTAL_KG === undefined || total_kg_line11?.TOTAL_KG === '' ? 0 : Math.round(total_kg_line11?.TOTAL_KG).toLocaleString()}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        kg&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined
                                        ? ''
                                        : total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? 0 : total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_kg_processing_line11?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line11?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? 0 : Math.round(total_kg_processing_line11?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK === undefined
                                        ? ''
                                        : total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 25, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemTotalBarrel>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK === '' ? 0 : total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_kg_instock_line11?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line11?.TOTAL_KG_IN_STOCK === '' ? 0 : Math.round(total_kg_instock_line11?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                                                        </Typography>
                                                    </Box>
                                                </ItemTotalBarrel>
                                            </Grid>
                                    }
                                </Item>
                            </Grid>

                            <Grid item xs={4} style={{ paddingRight: 3, height: '38vh' }} >
                                <Item>
                                    <Grid item xs={12} textAlign="left" style={{ paddingTop: 10 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                L
                                            </Typography>
                                            <Typography style={{ color: '#2e9281', fontSize: 42, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                12&nbsp;
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 48, lineHeight: "2vh", position: "relative", top: "0vw" }} >
                                                {sku_line12?.CURRENT_SKU === undefined ? '' : sku_line12?.CURRENT_SKU}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {batch_line12?.CURRENT_BATCH === undefined
                                        ? ''
                                        : batch_line12?.CURRENT_BATCH === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemSummary>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            Batch :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: colors.neutral['white'], fontSize: 44, lineHeight: "4vh", fontWeight: 'bold' }} >
                                                            {batch_line12?.CURRENT_BATCH === undefined || batch_line12?.CURRENT_BATCH === '' ? 0 : batch_line12?.CURRENT_BATCH}
                                                        </Typography>
                                                    </Box>
                                                </ItemSummary>
                                            </Grid>
                                    }

                                    {total_barrel_line12?.TOTAL_BARREL === undefined
                                        ? ''
                                        : total_barrel_line12?.TOTAL_BARREL === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_barrel_line12?.TOTAL_BARREL === undefined || total_barrel_line12?.TOTAL_BARREL === '' ? 0 : total_barrel_line12?.TOTAL_BARREL}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        Bin&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 52, lineHeight: "2vh", fontWeight: 'bold' }} >
                                                        {total_kg_line12?.TOTAL_KG === undefined || total_kg_line12?.TOTAL_KG === '' ? 0 : Math.round(total_kg_line12?.TOTAL_KG).toLocaleString()}&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['grey'], fontSize: 32, lineHeight: "2vh", paddingTop: 5 }} >
                                                        kg&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined
                                        ? ''
                                        : total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 30 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? 0 : total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: '#b58514', fontSize: 44, lineHeight: "3vh", fontWeight: 'bold' }} >
                                                        {total_kg_processing_line12?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line12?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? 0 : Math.round(total_kg_processing_line12?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                    }

                                    {total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK === undefined
                                        ? ''
                                        : total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK === '' ? '' :
                                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 25, paddingLeft: 10, paddingRight: 10 }}>
                                                <ItemTotalBarrel>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK === '' ? 0 : total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                                                        </Typography>
                                                        <Typography style={{ color: '#2e9281', fontSize: 48, lineHeight: "5vh", fontWeight: 'bold' }} >
                                                            {total_kg_instock_line12?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line12?.TOTAL_KG_IN_STOCK === '' ? 0 : Math.round(total_kg_instock_line12?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                                                        </Typography>
                                                    </Box>
                                                </ItemTotalBarrel>
                                            </Grid>
                                    }
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </>
    )
}

export default MarinationTrackingPage1


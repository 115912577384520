import { useRef, useEffect, useState } from 'react'
import '../styles.css'
import { Helmet } from "react-helmet";
import { apiUrl } from "../constants";
import axios from "axios";
import { Box, Grid, Card, Container, TextField, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";
import db from "../firebase-config";
import LensIcon from '@mui/icons-material/Lens';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import authorize from '../assets/img/authorize.png';







const CorrelationP2 = () => {
    const exchangeToken = useExchangeToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [seleteUserName, setSeleteUserName] = useState(null);
    const [openSelectUserName, setOpenSelectUserName] = useState(false);
    const [seleteAuthorize, setSeleteAuthorize] = useState(null);
    const [openSelectAuthorize, setOpenSelectAuthorize] = useState(false);
    const [openConfirmChangeAuthorize, setOpenConfirmChangeAuthorize] = useState();
    const [unlockSuccessful, setUnlockSuccessful] = useState();
    const [notifySubmittedUnlockSuccessful, setNotifySubmittedUnlockSuccessful] = useState(false);
    const [notifySubmittedUnlockFailed, setNotifySubmittedUnlockFailed] = useState(false);


    const [seleteNote, setSeleteNote] = useState(null);
    const [enable_giveaway, setEnable_giveaway] = useState([]);
    const [User, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);
    const display = useSelector(state => state.auth.email);

    const handleOpenSelectUserName = () => {
        setOpenSelectUserName(true);
    };

    const handleCloseSelectUserName = () => {
        setOpenSelectUserName(false);
    };

    const handleOpenSelectAuthorize = () => {
        setOpenSelectAuthorize(true);
    };

    const handleCloseSelectAuthorize = () => {
        setOpenSelectAuthorize(false);
    };

    const handleClickConfirmChangeAuthorize = () => {
        setOpenConfirmChangeAuthorize(true);
    };

    const handleCloseConfirmChangeAuthorize = () => {
        setOpenConfirmChangeAuthorize(false);
    };


    const handleSelectionInUnlockform = (event) => {
        if (event.target.name === "user") {
            setSeleteUserName(event.target.value);
        }
        if (event.target.name === "status") {
            setSeleteAuthorize(event.target.value);
        }
        if (event.target.name === "note") {
            setSeleteNote(event.target.value);
        }
    };


    useEffect(() => {
        const fetchInitialTokenAndData = async () => {
            try {
                const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
                if (token) {
                    setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
                }
            } catch (err) {
                console.error("Error exchanging token:", err);
                setError("Failed to exchange token");
            }
        };

        fetchInitialTokenAndData();
    }, [exchangeToken]);


    useEffect(() => {
        const fetchMyAPI = async () => {
            if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
            setLoading(true);
            try {
                const response = await axios.get(`${apiUrl.USER}`, {
                    headers: {
                        Authorization: `Bearer ${ac_token}`,
                        // "X-xss-protection": "1; mode=block;",
                        // "X-frame-options": "SAMEORIGIN",
                        // "X-content-type-options": "nosniff",
                        // "Cache-Control": "no-cache, private",
                        // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                        // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                    },
                });

                if (response.status === 200) {
                    setUser(response.data.filter(i => i.email === display))
                }
            } catch (err) {
                setError(err.message);
                console.error("Error fetching BU data:", err);
            } finally {
                setLoading(false);
            }
        };

        if (isTokenExchanged && ac_token) {
            fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
        }
    }, [isTokenExchanged, ac_token]);

    const handleUnlock = async () => {
        // console.log({
        //     "plant_id": "4117",
        //     "factory": "00000000000000",
        //     "line": "LINE7",
        //     "user": User.map(x => x.first_name) + " " + User.map(x => x.last_name),
        //     "status": (get_enable?.VALUE == true ? 'ปิดการใช้งาน' : 'เปิดการใช้งาน') == 'เปิดการใช้งาน' ? true : false,
        //     "note": seleteNote
        // });

        await axios.post(apiUrl.UNLOCK_GIVEAWAY, {
            "plant_id": "4117",
            "factory": "00000000000000",
            "line": "LINE7",
            "user": User.map(x => x.first_name) + " " + User.map(x => x.last_name),
            "status": (get_enable?.VALUE == true ? 'ปิดการใช้งาน' : 'เปิดการใช้งาน') == 'เปิดการใช้งาน' ? true : false,
            "note": seleteNote
        }, {
            headers: {
                Authorization: `Bearer ${ac_token}`,
                "X-xss-protection": "1; mode=block;",
                "X-frame-options": "SAMEORIGIN",
                "X-content-type-options": "nosniff",
                "Cache-Control": "no-cache, private",
                "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
            },
        })
            .then(response => {
                // console.log(response)
                if (response.status == 200) {
                    setSeleteUserName();
                    setSeleteAuthorize();
                    setSeleteNote();
                    setUnlockSuccessful(1)
                    setNotifySubmittedUnlockSuccessful(true);
                }
                else {
                    setUnlockSuccessful(2)
                    setNotifySubmittedUnlockFailed(true);
                }
            })
            .catch(error => {
                console.log('unlock error: ', error);
            })
    }

    useEffect(() => {
        const subscriberUnlock = db
            .collection("prod_mes_ignition_giveaway_tag_list")
            .where("TAG", "==", "Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Cloud_Enable")
            .onSnapshot((querySnapshot) => {
                const unlockData = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setEnable_giveaway(unlockData);
            });

        // Cleanup listeners
        return () => {
            subscriberUnlock();
        };
    }, []);

    const get_enable = enable_giveaway.find((tag) => tag.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Cloud_Enable"));



    return (
        <Box>
            <Helmet>
                <title>UnlockGiveaway Page2 | MES</title>
            </Helmet>
            <Grid container spacing={0}>
                {/* Top row display date */}
                <Grid xs={12} >
                    <Box>
                        <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: "30px", position: "relative", paddingLeft: 0, paddingTop: 10 }} textAlign="center">
                            {get_enable?.VALUE == true ? 'ปิดการใช้งาน Giveaway' : 'เปิดการใช้งาน Giveaway'}
                        </Typography>
                        <Typography style={{ position: "relative", paddingLeft: 0, paddingTop: 10 }} textAlign="center">
                            <img src={authorize} height="150px" />
                        </Typography>
                        {enable_giveaway.map((item) => {
                            return <Typography style={{ fontWeight: "bold", fontSize: "16px", position: "relative", left: 0, paddingTop: 10 }} textAlign="center">
                                <LensIcon fontSize="medium" stroke={item.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: item.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", paddingLeft: 0, top: 5 }}></LensIcon>
                                &nbsp;{item.VALUE == true ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
                            </Typography>
                        })}
                    </Box>

                    <Box>
                        <Typography style={{ fontSize: '16px', fontWeight: 'medium', paddingLeft: 0, paddingTop: 20 }} textAlign="left">
                            ชื่อ
                        </Typography>
                        <TextField
                            //required
                            id="outlined-required"
                            //type="number"
                            name="user"
                            value={User.map(x => x.first_name) + " " + User.map(x => x.last_name)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleSelectionInUnlockform}
                            sx={{ width: '100%' }}
                        />
                    </Box>

                    <Box>
                        <Typography style={{ fontSize: '16px', fontWeight: 'medium', paddingLeft: 0, paddingTop: 20 }} textAlign="left">
                            การใช้งาน
                        </Typography>
                        <TextField
                            //required
                            id="outlined-required"
                            //type="number"
                            name="status"
                            value={get_enable?.VALUE == true ? 'ปิดการใช้งาน' : 'เปิดการใช้งาน'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleSelectionInUnlockform}
                            sx={{ width: '100%' }}
                        />
                    </Box>

                    <Box>
                        <Typography style={{ fontSize: '16px', fontWeight: 'medium', position: "relative", paddingLeft: 0, paddingTop: 20 }} textAlign="left">
                            {get_enable?.VALUE == true ? 'เหตุผลที่ต้องการปิดการใช้งาน' : 'เหตุผลที่ต้องการเปิดการใช้งาน'}
                        </Typography>
                        <TextField
                            //required
                            id="outlined-required"
                            //type="number"
                            name="note"
                            value={seleteNote}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleSelectionInUnlockform}
                            sx={{ width: '100%' }}
                        />
                    </Box>

                    <Box style={{ position: "relative", paddingLeft: 0, paddingTop: 30 }}>
                        <Button disabled={!seleteNote} fullWidth style={{ fontSize: '16px', fontWeight: "bold", backgroundColor: !seleteNote ? '#cfcfcf' : '#3cbea8' }} variant="contained" onClick={() => { handleClickConfirmChangeAuthorize(); }}>ยืนยัน</Button>
                    </Box>
                </Grid>
            </Grid>

            {/* pop up start tumbler  */}
            <Dialog
                open={openConfirmChangeAuthorize}
                onClose={handleCloseConfirmChangeAuthorize}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ยืนยัน
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {get_enable?.VALUE == true ? 'ปิดการใช้งาน Giveaway?' : 'เปิดการใช้งาน Giveaway?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenConfirmChangeAuthorize(false); }}>ยกเลิก</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenConfirmChangeAuthorize(false); handleUnlock(); }}>ยืนยัน</Button>
                </DialogActions>
            </Dialog>

            {unlockSuccessful == 1 ?
                <div style={{ marginTop: 20 }}>
                    <Dialog
                        open={notifySubmittedUnlockSuccessful}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                                {get_enable?.VALUE == true ? 'ปิดการใช้งาน Giveaway สำเร็จ' : 'เปิดการใช้งาน Giveaway สำเร็จ'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifySubmittedUnlockSuccessful(false); window.location.reload(false); }}>OK</Button>
                        </DialogActions>
                    </Dialog>

                </div>
                : console.log()
            }

            {unlockSuccessful == 2 ?
                <div style={{ marginTop: 20 }}>
                    <Dialog
                        open={notifySubmittedUnlockFailed}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                                เกิดข้อผิดพลาด!!!, โปรดลองใหม่อีกครั้ง
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifySubmittedUnlockFailed(false); }}>OK</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                : console.log()
            }
        </Box>
    )
}

export default CorrelationP2

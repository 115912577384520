import React, { useEffect, useRef } from 'react';
import { useAuth } from '../../hooks/useAuth'; // Adjust the import path to your actual AuthContext file

// Custom hook to get search params
const useSearchParams = () => {
  return new URLSearchParams(window.location.search);
};

const AuthGuard = ({ children }) => {
  const { isLogin, loading, silentLogin, kit} = useAuth();
  const isSilentCalled = useRef(false);
  const searchParams = useSearchParams();
  const sessionKey = searchParams.get('sessionKey');
  localStorage.setItem('sessionKey', searchParams.get('sessionKey'))
  useEffect(() => {
    if (kit && silentLogin && !isSilentCalled.current) {
      isSilentCalled.current = true;
      silentLogin(sessionKey || undefined);
    }
  }, [kit, silentLogin, sessionKey]);

  return isLogin ? (
    <>{children}</>
  ) : !isLogin && loading ? (
    <div className="flex justify-center items-center py-3 h-full">{'Loading ...'}</div>
  ) : (
    ""
  );
};

export default AuthGuard;

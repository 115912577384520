import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
});

store.subscribe(() => {
  //console.log('Store Updated:', store.getState());
});


export default store;

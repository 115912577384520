import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import db from "../../firebase-config"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import HeaderDetail from '../../components/header/HeaderDetail';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Target from "../../assets/img/svg/target.svg"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import GrigToDetail from "../../components/grid/GridToDetail";
import Fighting from "../../assets/img/first1.gif";
import Running from "../../assets/img/running.gif"
import Run from '../../assets/img/running_png.png';
import Fist from '../../assets/img/protest_png.png';
import useCheckToken from '../../hooks/useCheckToken';
import { useParams } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#bcd6d0',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    //borderRadius: 10,
    width: '101.5%',
    position: "relative",
    left: '-1.5vw'

}));

const ItemTarget = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(1),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    borderRadius: 0,
    width: '101.5%',
    position: "relative",
    left: '-1.5vw'

}));


const PerformanceDetailFPP1Page1 = () => {
    const { bu, plant, factory, line } = useParams();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [performanceP1, setPerformanceP1] = useState([]);

    useCheckToken();

    const line_detail = () => {
        try {

            const buName = bu;
            const plantName = plant;
            const factoryName = factory;
            const lineName = line;

            const lineMapping = {
                "แปรรูปไก่_4117_FPP1_Line1": 'Line1',
                "แปรรูปไก่_4117_FPP1_Line2": 'Line2',
                "แปรรูปไก่_4117_FPP1_Line5": 'Line5',
                "แปรรูปไก่_4117_FPP1_Line6": 'Line6',
            };

            const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

            return lineMapping[key];
        } catch (error) {
            console.error("Error in line_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };

    const line_performance = () => {
        try {

            const buName = bu;
            const plantName = plant;
            const factoryName = factory;
            const lineName = line;

            const lineMapping = {
                "แปรรูปไก่_4117_FPP1_Line1": 'LINE1',
                "แปรรูปไก่_4117_FPP1_Line2": 'LINE2',
                "แปรรูปไก่_4117_FPP1_Line5": 'LINE5',
                "แปรรูปไก่_4117_FPP1_Line6": 'LINE6',
            };

            const key = `${buName}_${plantName}_${factoryName}_${lineName}`;

            return lineMapping[key];
        } catch (error) {
            console.error("Error in line_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };

    useEffect(() => {
        const subscriberPerformance = db
            .collection("prod_mes_ignition_performance_tracking_page1")
            .onSnapshot((querySnapshot) => {
                const performanceData = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setPerformanceP1(performanceData);
            });

        // const subscriberError = db
        //     .collection("prod_mes_ignition_keep_alive")
        //     .where("ID", "==", "Further.4071.00000000000000.Thailand.Protrax.Packing.LINE1.0NoError")
        //     .onSnapshot((querySnapshot) => {
        //         const errorData = querySnapshot.docs.map(doc => ({
        //             ...doc.data(),
        //             id: doc.id,
        //         }));
        //         setNo_error(errorData);
        //     });

        // Cleanup listeners
        return () => {
            subscriberPerformance();
            // subscriberError();
        };
    }, []);

    const active_sku = performanceP1.find((x) => x.id.includes(`4071.00000000000000.${line_performance()}ACTIVE_SKU`)) || { ACTIVE_SKU: "-" };
    const actual_pack = performanceP1.find((x) => x.id.includes(`4071.00000000000000.${line_performance()}ACTUAL_PACK`)) || { ACTUAL_PACK: 0 };
    const actual_kg = performanceP1.find((x) => x.id.includes(`4071.00000000000000.${line_performance()}ACTUAL_KG`)) || { ACTUAL_KG: 0 };
    const shift = performanceP1.find((x) => x.id.includes(`4071.00000000000000.${line_performance()}SHIFT`)) || { SHIFT: "" };
    const status = performanceP1.find((x) => x.id.includes(`4071.00000000000000.${line_performance()}STATUS`)) || { STATUS: 0 };
    const target_kg = performanceP1.find((x) => x.id.includes(`4071.00000000000000.${line_performance()}TARGET_KG`)) || { TARGET_KG: 0 };
    const warning = performanceP1.find((x) => x.id.includes(`4071.00000000000000.${line_performance()}WARNING`)) || { MESSAGE: null };

    const remaining = Math.round(target_kg?.TARGET_KG) - Math.round(actual_kg?.ACTUAL_KG) <= 0 ? 0 : Math.round(target_kg?.TARGET_KG) - Math.round(actual_kg?.ACTUAL_KG) || 0;
    const persent_actual = (actual_kg?.ACTUAL_KG * 100) / target_kg?.TARGET_KG || 0;


    return (
        <>
            <Helmet>
                <title>Performance Page1 | MES</title>
            </Helmet>
            <Grid style={{paddingTop: 25}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <HeaderDetail
                            line={line_detail()}
                            sku={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? 'ไม่ตรงแผนผลิต' : actual_kg?.ACTUAL_KG == null ? active_sku?.ACTIVE_SKU : active_sku?.ACTIVE_SKU}
                            icon={shift?.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ height: '55px' }} />) : (<LightModeOutlinedIcon style={{ height: '55px' }} />)}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Item>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: '#b58514', fontSize: 20, lineHeight: "17px", fontWeight: 'bold' }} >
                                <img src={Target} height="120px" />
                            </Typography>
                            <Typography style={{ color: colors.neutral['green'], fontSize: 100, fontWeight: 'bold', lineHeight: "17px", paddingTop: 5 }} >
                                &nbsp;เป้าหมาย&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.neutral['green'], fontSize: 120, lineHeight: "17px", fontWeight: 'bold' }} >
                                &nbsp;{warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? Math.round(target_kg?.TARGET_KG).toLocaleString() : Math.round(target_kg?.TARGET_KG).toLocaleString()}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.neutral['green'], fontSize: 50, lineHeight: "17px", fontWeight: 'bold', paddingTop: 20 }} >
                                กก.
                            </Typography>
                        </Box>
                    </Item>

                </Grid>

                <Grid item xs={12} sm={12}>
                    <ItemTarget>
                        <Grid container rowSpacing={0.8} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
                            <GrigToDetail
                                color={colors.neutral['green']}
                                image={status?.STATUS == 1 ? <img src={Fighting} height="100vh" /> : <img src={Fist} height="100vh" />}
                                section={'ทำได้'}
                                persent={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? 0 : Math.round(persent_actual)}
                                color_persent={colors.neutral['green']}
                                value={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? 0 : Math.round(actual_kg?.ACTUAL_KG).toLocaleString()}
                            />

                            <GrigToDetail
                                color={'#4a4a49'}
                                image={status?.STATUS == 1 ? <img src={Running} height="100vh" /> : <img src={Run} height="100vh" />}
                                //width={no_data === 1 ? (performanceP1.ACTUAL_WEIGHT_PERCENT === 'NA' ? '20vh' : (100 - performanceP1.ACTUAL_WEIGHT_PERCENT < 100 ? '20vh' : '40vh')) : '20vh'}
                                section={'คงเหลือ'}
                                persent={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? 0 : (100 - Math.round(persent_actual) <= 0 ? 0 : 100 - Math.round(persent_actual))}
                                color_persent={colors.secondary[800]}
                                value={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? 0 : (remaining).toLocaleString()}
                            />
                        </Grid>

                        {actual_kg?.ACTUAL_KG == null ?
                            <Grid item xs={12} textAlign="left" style={{ paddingRight: 0, paddingLeft: 150 }}>
                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} textAlign="center">
                                    <Typography style={{ color: '#2e9281', fontSize: 70, position: "relative", paddingLeft: 0, top: "0vw" }}>
                                        {actual_kg?.ACTUAL_KG == null ? Math.round(actual_pack?.ACTUAL_PACK).toLocaleString() : ''}
                                    </Typography>
                                    <Typography style={{ color: '#ffffff', fontSize: 50, paddingTop: 18 }}>
                                        &nbsp;ถุง (ไม่มีข้อมูล Std. Cap ในระบบ)
                                    </Typography>
                                </Box>
                            </Grid>
                            : ''}
                    </ItemTarget>
                </Grid>
            </Grid>


        </>
    )
}

export default PerformanceDetailFPP1Page1

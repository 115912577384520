import { useRef, useEffect, useState, useMemo } from 'react'
import moment from 'moment-timezone';
import '../styles.css'
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import { Box, Grid, Card, Tooltip, Stack, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Slide
} from "@material-ui/core/";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Backdrop from '@mui/material/Backdrop';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";
import Circular from "../components/model/Circular";


const slideProps = {
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: { enter: "auto", exit: "auto" }
};


const CompletedInInfeed = () => {
    const exchangeToken = useExchangeToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const today = new Date();
    const hourago = new Date(today.getTime());
    const datetime = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
    const hourago_start = new Date(today.getTime() - (48 * 60 * 60 * 1000));
    const date_start = moment(hourago_start).tz('Asia/Bangkok').format('YYYY-MM-DD');
    const date_end = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');

    const [isTokenExchanged, setIsTokenExchanged] = useState(false);

    const [completed_preparation_zone, setCompleted_preparation_zone] = useState([]);

    const [openEditStatusInStock, setOpenEditStatusInStock] = useState();
    const [openEditStatusProcessing, setOpenEditStatusProcessing] = useState();
    const [openEditStatusReject, setOpenEditStatusReject] = useState();

    const [completedCheckOnEditStatus, setCompletedCheckOnEditStatus] = useState();
    const [editStatus, setEditStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [done, setDone] = useState(false);
    const [popupTimeout, setPopupTimeout] = useState(false);
    const [openPopupTimeout, setOpenPopupTimeout] = useState(false);

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);

    const display = useSelector(state => state.auth.activeSession.user);

    const handleOpenEditStatusInStock = () => {
        setOpenEditStatusInStock(true);
    };

    const handleCloseEditStatusInStock = () => {
        setOpenEditStatusInStock(false);
    };


    const handleOpenEditStatusProcessing = () => {
        setOpenEditStatusProcessing(true);
    };

    const handleCloseEditStatusProcessing = () => {
        setOpenEditStatusProcessing(false);
    };

    const handleOpenEditStatusReject = () => {
        setOpenEditStatusReject(true);
    };

    const handleCloseEditStatusReject = () => {
        setOpenEditStatusReject(false);
    };

    const handleClosePopupTimeout = () => {
        setOpenPopupTimeout(false);
    };

    useEffect(() => {
        const fetchInitialTokenAndData = async () => {
            try {
                const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
                if (token) {
                    setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
                }
            } catch (err) {
                console.error("Error exchanging token:", err);
                setError("Failed to exchange token");
            }
        };

        fetchInitialTokenAndData();
    }, [exchangeToken]);


    useEffect(() => {
        const fetchMyAPI = async () => {
            if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
            setLoading(true);
            const timeout = 10000; // กำหนด timeout 10 วินาที (10,000 มิลลิวินาที)
            const timeoutError = new Promise((_, reject) =>
                setTimeout(() => reject(new Error("Request timed out")), timeout)
            );
            try {
                setDone(true);
                const response = await Promise.race([
                    axios.get(`${apiUrl.GET_TUMBLER_BETWEEN_2DAY}${date_start}/${date_end}/`, {
                        headers: {
                            Authorization: `Bearer ${ac_token}`,
                            // "X-xss-protection": "1; mode=block;",
                            // "X-frame-options": "SAMEORIGIN",
                            // "X-content-type-options": "nosniff",
                            // "Cache-Control": "no-cache, private",
                            // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                            // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                        },
                    }),
                    timeoutError // ตรวจสอบ timeout
                ]);

                if (response.status === 200) {
                    setCompleted_preparation_zone(response.data.filter(i => i.STATUS === "Completed"))
                    setDone(false);
                    setPopupTimeout(1)
                }
            } catch (err) {
                if (err.message === "Request timed out") {
                    console.error("Timeout Error: The request took too long to complete.");
                } else if (err.request.status === 404) {
                    setPopupTimeout(2)
                } else {
                    setPopupTimeout(3)
                }
                setDone(false);
            } finally {
                setLoading(false);
            }
        };

        if (isTokenExchanged && ac_token) {
            fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
        }
    }, [isTokenExchanged, ac_token, date_start, date_end]);

    const sort_factory_date = completed_preparation_zone.sort((a, b) => a.FACTORY_DATE.localeCompare(b.FACTORY_DATE));
    const sort_batch = sort_factory_date.sort((a, b) => a.AT_COMPLETED_TIMESTAMP.localeCompare(b.AT_COMPLETED_TIMESTAMP));


    const categories = useMemo(() => {
        const categories = {};
        completed_preparation_zone.forEach((item) => {
            if (!categories[item.MATERIAL_DESCRIPTION]) {
                categories[item.MATERIAL_DESCRIPTION] = [];
            }
            categories[item.MATERIAL_DESCRIPTION].push(item);
        });
        return categories;
    }, [completed_preparation_zone]);

    const check_update = async () => {
        await axios.get(`${apiUrl.GET_TUMBLER_BETWEEN_2DAY}${date_start}/${date_end}/`, {
            headers: {
                Authorization: `Bearer ${ac_token}`,
                // "X-xss-protection": "1; mode=block;",
                // "X-frame-options": "SAMEORIGIN",
                // "X-content-type-options": "nosniff",
                // "Cache-Control": "no-cache, private",
                // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
            },
        }
        )
            .then(response => {
                //console.log(response.data)
                if (response.status == 200) {
                    setCompleted_preparation_zone(response.data.filter(i => i.STATUS === "Completed"))

                }
            })
            .catch(error => {
                console.log('error: ', error)

            })
    }

    const handleEditStatusInStock = async () => {
        await axios.put(`${apiUrl.PUT_UPDATE_TUMBLER}${completedCheckOnEditStatus.CHECK_ON}/`, {
            "status": 'In Stock',
            "username": display,
        }, {
            headers: {
                Authorization: `Bearer ${ac_token}`,
                // "X-xss-protection": "1; mode=block;",
                // "X-frame-options": "SAMEORIGIN",
                // "X-content-type-options": "nosniff",
                // "Cache-Control": "no-cache, private",
                // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
            }
        })
            .then(response => {
                // console.log(response)
                if (response.status == 200) {
                    setEditStatus(1)
                    check_update()
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setEditStatus(2)
                }
                else {
                    setEditStatus(2)
                }
                console.log('edit line error: ', error);
            })
    }

    const handleEditStatusProcessing = async () => {

        await axios.put(`${apiUrl.PUT_UPDATE_TUMBLER}${completedCheckOnEditStatus.CHECK_ON}/`, {
            "status": 'Processing',
            "at_process_timestamp": datetime,
            "username": display,
        }, {
            headers: {
                Authorization: `Bearer ${ac_token}`,
                // "X-xss-protection": "1; mode=block;",
                // "X-frame-options": "SAMEORIGIN",
                // "X-content-type-options": "nosniff",
                // "Cache-Control": "no-cache, private",
                // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
            }
        })
            .then(response => {
                // console.log(response)
                if (response.status == 200) {
                    setEditStatus(1)
                    check_update()
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setEditStatus(2)
                }
                else {
                    setEditStatus(2)
                }
                console.log('edit line error: ', error);
            })
    }

    const handleEditStatusReject = async () => {

        //console.log(`${apiUrl.EDIT_TUMBLER}${tumblerCheckOnEditWeight.CHECK_ON}/`)
        await axios.put(`${apiUrl.PUT_UPDATE_TUMBLER}${completedCheckOnEditStatus.CHECK_ON}/`, {
            "status": 'Reject',
            "at_rejected_timestamp": datetime,
            "username": display,
        }, {
            headers: {
                Authorization: `Bearer ${ac_token}`,
                // "X-xss-protection": "1; mode=block;",
                // "X-frame-options": "SAMEORIGIN",
                // "X-content-type-options": "nosniff",
                // "Cache-Control": "no-cache, private",
                // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"

            }
        })
            .then(response => {
                // console.log(response)
                if (response.status == 200) {
                    setEditStatus(1)
                    check_update()
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setEditStatus(2)
                }
                else {
                    setEditStatus(2)
                }
                console.log('edit line error: ', error);
            })
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setEditStatus(false);
        }, 3000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (popupTimeout === 2) {
            setOpenPopupTimeout(true);
        } else if (popupTimeout === 3) {
            setOpenPopupTimeout(true);
        }
    }, [popupTimeout]);


    return (
        <Box style={{ width: "100%", height: "79vh" }}>
            <Helmet>
                <title>Completed | MES</title>
            </Helmet>
            {done ? <Circular /> :
                <Grid container spacing={2} style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }} >
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }} >
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ position: "relative", top: '0vw', height: '0px' }}>
                                <Box >
                                    <div>
                                        {Object.entries(categories).map((entry) => {
                                            const category = entry[0];
                                            const itemList = entry[1];

                                            const categories_time = {};
                                            for (const item of itemList) {
                                                if (item.FACTORY_DATE in categories_time) {
                                                    categories_time[item.FACTORY_DATE].push(item);
                                                } else {
                                                    categories_time[item.FACTORY_DATE] = [item];
                                                }
                                            }
                                            ///nested accordion (https://codesandbox.io/p/sandbox/nested-accordion-wib5q?file=%2Fsrc%2FApp.js)
                                            return (
                                                <Accordion
                                                    TransitionComponent={Slide}
                                                    TransitionProps={slideProps}
                                                    sx={{
                                                        backgroundColor: "#ffffff"
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {Object.entries(categories_time).map((entry) => {
                                                            const category_list = entry[0];
                                                            const item_list = entry[1];
                                                            return (
                                                                <Accordion
                                                                    TransitionComponent={Slide}
                                                                    TransitionProps={slideProps}
                                                                    sx={{
                                                                        backgroundColor: "#ffffff"
                                                                    }}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                    >
                                                                        <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category_list}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        {item_list.map((item, index) => {

                                                                            return <Card sx={{
                                                                                p: 1,
                                                                                margin: 'auto',
                                                                                //maxWidth: 1500,
                                                                                flexGrow: 1,
                                                                                backgroundColor: (theme) =>
                                                                                    theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
                                                                            }}>
                                                                                <Grid item xs={12} sm container>
                                                                                    <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "0vw" }}>
                                                                                        <Grid item xs>
                                                                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                                <Typography style={{ fontSize: 18, fontWeight: 'bold' }} gutterBottom>
                                                                                                    {item.MATERIAL_DESCRIPTION}&nbsp;&nbsp;&nbsp;
                                                                                                </Typography>
                                                                                            </Box>

                                                                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                                <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px', width: '80px' }} onClick={() => { }}>
                                                                                                    {item.LINE}&nbsp;
                                                                                                </Typography>
                                                                                                <Button style={{ backgroundColor: '#E8AB6D', color: "black", fontSize: 18, height: '20px' }} onClick={() => { }}>
                                                                                                    {item.SHIFT}
                                                                                                </Button>
                                                                                            </Box>

                                                                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '5%' }} textAlign="left" >

                                                                                            </Box>

                                                                                            <Typography style={{ fontSize: 15, width: '1000%' }} gutterBottom>
                                                                                                {(item.AT_COMPLETED_TIMESTAMP).split("Z")[0]}
                                                                                            </Typography>
                                                                                            <Typography style={{ fontSize: 18 }} gutterBottom>
                                                                                                ชุดนวด: {item.BATCH}
                                                                                            </Typography>
                                                                                            <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px' }}>
                                                                                                {item.ACTUAL_BARREL_WEIGHT} kg.
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item xs container spacing={1} style={{ position: "relative", top: "1vw" }} textAlign="right">
                                                                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                                                                            <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                                                                                                <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} onClick={() => { setCompletedCheckOnEditStatus({ CHECK_ON: item.CHECK_ON, STATUS: item.STATUS }); handleOpenEditStatusInStock(); }} >IN STOCK</Button>
                                                                                                <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} onClick={() => { setCompletedCheckOnEditStatus({ CHECK_ON: item.CHECK_ON, STATUS: item.STATUS }); handleOpenEditStatusProcessing(); }}>PROCESSING</Button>
                                                                                                <Button style={{ height: '30px', width: '85px', fontWeight: 'bold', color: '#3A59F2', backgroundColor: '#c7c9c8', fontSize: 15 }} >COMPLETED</Button>
                                                                                                <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} onClick={() => { setCompletedCheckOnEditStatus({ CHECK_ON: item.CHECK_ON, STATUS: item.STATUS }); handleOpenEditStatusReject(); }}>REJECT</Button>
                                                                                            </ButtonGroup>
                                                                                        </Grid>

                                                                                    </Grid>

                                                                                </Grid>
                                                                            </Card>
                                                                        })}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            );
                                                        })}
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })}
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Dialog
                open={openEditStatusInStock}
                transitionDuration={1}
                onClose={handleCloseEditStatusInStock}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
                    EDIT STATUS
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to edit status from 'COMPLETED' to 'IN STOCK'
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditStatusInStock(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditStatusInStock(false); handleEditStatusInStock(); }} >Save</Button>
                </DialogActions>
            </Dialog>

            {/* pop up edit status processing */}
            <Dialog
                open={openEditStatusProcessing}
                transitionDuration={1}
                onClose={handleCloseEditStatusProcessing}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
                    EDIT STATUS
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to edit status from 'COMPLETED' to 'PROCESSING'
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditStatusProcessing(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditStatusProcessing(false); handleEditStatusProcessing(); }} >Save</Button>
                </DialogActions>
            </Dialog>

            {/* pop up edit status reject */}
            <Dialog
                open={openEditStatusReject}
                transitionDuration={1}
                onClose={handleCloseEditStatusReject}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
                    EDIT STATUS
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to edit status from 'COMPLETED' to 'REJECT'
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditStatusReject(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditStatusReject(false); handleEditStatusReject(); }} >Save</Button>
                </DialogActions>
            </Dialog>

            {/*show pop up success edit status*/}
            {editStatus === 1 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Successfully editted status
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }
            {editStatus === 2 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Failed to edit status, Plead try again
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }

            <Dialog
                open={openPopupTimeout}
                onClose={handleClosePopupTimeout}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {popupTimeout == 2 ? 'No data available' : 'Unable to load data. Please try again.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClosePopupTimeout(); popupTimeout == 2 ? handleClosePopupTimeout() : window.location.reload(false); }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default CompletedInInfeed

import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import './fonts/fonts.css'; // Import the CSS file
// Color design tokens export
export const tokens = (mode) => ({
    ...(mode === "dark"
      ? {
          primary: {
            100: "#e0f7f9",
            200: "#b3ecf0",
            300: "#80e0e6",
            400: "#4dd4dc",
            500: "#00c7d1",
            600: "#00b3bd",
            700: "#009fa9",
            800: "#008b95",
            900: "#006873",
          },
          secondary: {
            100: "#e0e0e0",
            200: "#c2c2c2",
            300: "#a3a3a3",
            400: "#858585",
            500: "#666666",
            600: "#525252",
            700: "#3d3d3d",
            800: "#292929",
            900: "#141414",
          },
          accent: {
            100: "#ffe9e5",
            200: "#ffcdc7",
            300: "#ffb1a9",
            400: "#ff958b",
            500: "#ff6d57",
            600: "#e6614f",
            700: "#cc5546",
            800: "#b3493d",
            900: "#80332a",
          },
          complementary: {
            gold: {
              100: "#fff8e1",
              200: "#ffecb3",
              300: "#ffe082",
              400: "#ffd54f",
              500: "#ffca28",
              600: "#ffb300",
              700: "#ffa000",
              800: "#ff8f00",
              900: "#ff6f00",
            },
          },
          neutral: {
            white: "#ffffff",
            black: "#000000",
            green: "#2e9281"
          },
        }
      : {
          primary: {
            100: "#006873",
            200: "#008b95",
            300: "#009fa9",
            400: "#00b3bd",
            500: "#00c7d1",
            600: "#4dd4dc",
            700: "#80e0e6",
            800: "#b3ecf0",
            900: "#e0f7f9",
          },
          secondary: {
            100: "#141414",
            200: "#292929",
            300: "#3d3d3d",
            400: "#525252",
            500: "#666666",
            600: "#858585",
            700: "#a3a3a3",
            800: "#c2c2c2",
            900: "#e0e0e0",
          },
          accent: {
            100: "#ffe9e5",
            200: "#ffcdc7",
            300: "#ffb1a9",
            400: "#ff958b",
            500: "#ff6d57",
            600: "#e6614f",
            700: "#cc5546",
            800: "#b3493d",
            900: "#80332a",
          },
          complementary: {
            gold: {
              100: "#fff8e1",
              200: "#ffecb3",
              300: "#ffe082",
              400: "#ffd54f",
              500: "#ffca28",
              600: "#ffb300",
              700: "#ffa000",
              800: "#ff8f00",
              900: "#ff6f00",
            },
          },
          neutral: {
            white: "#ffffff",
            black: "#000000",
            green: "#2e9281",
            grey: "#c2c2c2"
          },
        }),
  });
  
// MUI theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // Palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.secondary[500],
            },
            accent: {
              main: colors.accent[500],
            },
            background: {
              default: colors.primary[800],
              paper: colors.primary[700],
            },
          }
        : {
            // Palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.secondary[500],
            },
            accent: {
              main: colors.accent[500],
            },
            background: {
              default: "#fcfcfc",
              paper: colors.primary[100],
            },
          }),
    },
    typography: {
      fontFamily: ["CPF Imm Sook"].join(","),
      fontSize: 18,
      h1: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 22,
      },
      h5: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 20,
      },
      h6: {
        fontFamily: ["CPF Imm Sook"].join(","),
        fontSize: 18,
      },
    },
  };
};

// Context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay, Virtual } from "swiper/core";
import "swiper/swiper-bundle.css";
import "../slider/styles.css";

// ใช้ SwiperCore เพื่อติดตั้ง module ที่ต้องการ
SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

function Slider(props) {
    return (
        <div className="wrapper">
            <Swiper
                id="swiper"
                className="swiper-container"  // ใช้ className เพื่อกำหนด wrapper ของ Swiper
                virtual
                slidesPerView={1}
                spaceBetween={30}
                slidesPerGroup={2}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                pagination={{
                    clickable: true,
                    el: '.swiper-pagination',
                }}
            >
                <SwiperSlide style={{ listStyle: "none" }}>
                    <div className="slide">{props.page1}</div>
                </SwiperSlide>
                <SwiperSlide style={{ listStyle: "none" }}>
                    <div className="slide">{props.page2}</div>
                </SwiperSlide>
            </Swiper>
            {/* ปุ่มสำหรับ Navigation */}
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
            {/* สำหรับ Pagination */}
            <div className="swiper-pagination"></div>
        </div>
    );
}

export default Slider;

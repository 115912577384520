import { useRef, useEffect, useState, useMemo } from 'react'
import moment from 'moment-timezone';
import '../styles.css'
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import { Box, Grid, Card, Tooltip, Stack, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Slide
} from "@material-ui/core/";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";
import Circular from "../components/model/Circular";
import LensIcon from '@mui/icons-material/Lens';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';

const slideProps = {
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: { enter: "auto", exit: "auto" }
};

const line_detail = [
    { Line: "Line 7", Sku: "KJS-SG-5002", Status: "Run", Shift: "D", color: "#C6E377" },
    { Line: "Line 8", Sku: "KJS-SG-5002", Status: "Pause - Cleaning", Shift: "D", color: "#E2E2E2" },
    { Line: "Line 9", Sku: "KJS-SG-5002", Status: "Pause - Breakdown", Shift: "D", color: "#E8453C" },
    { Line: "Line 10", Sku: "KJS-SG-5002", Status: "Stop", Shift: "D", color: "#5D5D5D" },
    { Line: "Line 11", Sku: "KJS-SG-5002", Status: "Run", Shift: "D", color: "#C6E377" },
    { Line: "Line 12", Sku: "KJS-SG-5002", Status: "Run", Shift: "D", color: "#C6E377" },
];

const pause_detail = [
    {
        value: "Change Product",
        label: "Change Product"
    },
    {
        value: "Break",
        label: "Break"
    },
    {
        value: "Cleaning",
        label: "Cleaning"
    },
    {
        value: "Material Shortage",
        label: "Material Shortage"
    },
    {
        value: "Defect/Reject",
        label: "Defect/Reject"
    },
    {
        value: "Breakdown",
        label: "Breakdown"
    },
]



const StatusLineInInfeed = () => {
    const exchangeToken = useExchangeToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const today = new Date();
    const hourago = new Date(today.getTime());
    const hourago_start = new Date(today.getTime() - (48 * 60 * 60 * 1000));
    const datetime = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
    const date_start = moment(hourago_start).tz('Asia/Bangkok').format('YYYY-MM-DD');
    const date_end = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');

    const [status_preparation_zone, setStatus_preparation_zon] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [done, setDone] = useState(false);
    const [popupTimeout, setPopupTimeout] = useState(false);
    const [openPopupTimeout, setOpenPopupTimeout] = useState(false);
    const [openPopupRun, setOpenPopupRun] = useState(false);
    const [openPopupPause, setOpenPopupPause] = useState(false);
    const [openPopupStop, setOpenPopupStop] = useState(false);

    const [editStatus, setEditStatus] = useState(false);
    const [historyInRun, setHistoryInRun] = useState({});
    const [historyInPause, setHistoryInPause] = useState({});
    const [historyInStop, setHistoryInStop] = useState({});

    const [historySelectedPause, setHistorySelectedPause] = useState('');

    const [openSelectPause, setOpenSelectPause] = useState(false);

    const [isTokenExchanged, setIsTokenExchanged] = useState(false);

    const handleClosePopupTimeout = () => {
        setOpenPopupTimeout(false);
    };

    const handleOpenPopupRun = () => {
        setOpenPopupRun(true);
    };
    const handleClosePopupRun = () => {
        setOpenPopupRun(false);
    };
    const handleOpenPopupPause = () => {
        setOpenPopupPause(true);
    };
    const handleClosePopupPause = () => {
        setOpenPopupPause(false);
    };
    const handleOpenPopupStop = () => {
        setOpenPopupStop(true);
    };
    const handleClosePopupStop = () => {
        setOpenPopupStop(false);
    };

    const handleCloseSelectPause = () => {
        setOpenSelectPause(false);
    };

    const handleOpenSelectPause = () => {
        setOpenSelectPause(true);
    };

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);


    useEffect(() => {
        const fetchInitialTokenAndData = async () => {
            try {
                const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
                if (token) {
                    setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
                }
            } catch (err) {
                console.error("Error exchanging token:", err);
                setError("Failed to exchange token");
            }
        };

        fetchInitialTokenAndData();
    }, [exchangeToken]);

    useEffect(() => {
        const fetchMyAPI = async () => {
            setLoading(true);
            const timeout = 10000; // กำหนด timeout 10 วินาที (10,000 มิลลิวินาที)
            const timeoutError = new Promise((_, reject) =>
                setTimeout(() => reject(new Error("Request timed out")), timeout)
            );
            try {
                setDone(true);
                const response = await Promise.race([
                    axios.get(`${apiUrl.GET_TUMBLER_STATUS}`, {
                        "headers": {
                            "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                        }
                    }),
                    timeoutError // ตรวจสอบ timeout
                ]);
                if (response.status === 200) {
                    const fetchedData = response.data;

                    // ตรวจสอบประเภทของข้อมูลที่ได้รับมา
                    if (Array.isArray(fetchedData) && fetchedData.length === 0) {
                        console.log("No data available.");
                        setStatus_preparation_zon([]);  // แสดงว่าไม่มีข้อมูล
                    } else if (typeof fetchedData === 'string') {
                        // ถ้าเป็นสตริงให้แทนที่ NaN และแปลงเป็น array ของ object
                        const correctedJsonString = fetchedData.replace(/NaN/g, 'null');
                        const arrayOfObjects = JSON.parse(correctedJsonString);
                        setStatus_preparation_zon(arrayOfObjects); // กำหนดค่าของ status_preparation_zone
                    } else {
                        setStatus_preparation_zon(fetchedData); // ถ้าเป็น array แล้วเก็บไว้ใน state
                    }
                    setDone(false);
                    setPopupTimeout(1);
                }
            } catch (err) {
                if (err.message === "Request timed out") {
                    console.error("Timeout Error: The request took too long to complete.");
                } else if (err.request.status === 404) {
                    setPopupTimeout(2)
                } else {
                    setPopupTimeout(3)
                }
                setDone(false);
            } finally {
                setLoading(false);
            }
        };

        if (isTokenExchanged && ac_token) {
            fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
        }
    }, [isTokenExchanged]);

    status_preparation_zone.sort((a, b) => {
        const numA = parseInt(a.LINE.replace(/\D/g, "")); // เอาเฉพาะตัวเลข
        const numB = parseInt(b.LINE.replace(/\D/g, ""));
        return numA - numB;
    });

    const check_update = async () => {
        await  axios.get(`${apiUrl.GET_TUMBLER_STATUS}`, {
            "headers": {
                "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
            }
        }
        )
            .then(response => {
                //console.log(response.data)
                if (response.status == 200) {
                    const fetchedData = response.data;

                    // ตรวจสอบประเภทของข้อมูลที่ได้รับมา
                    if (Array.isArray(fetchedData) && fetchedData.length === 0) {
                        console.log("No data available.");
                        setStatus_preparation_zon([]);  // แสดงว่าไม่มีข้อมูล
                    } else if (typeof fetchedData === 'string') {
                        // ถ้าเป็นสตริงให้แทนที่ NaN และแปลงเป็น array ของ object
                        const correctedJsonString = fetchedData.replace(/NaN/g, 'null');
                        const arrayOfObjects = JSON.parse(correctedJsonString);
                        setStatus_preparation_zon(arrayOfObjects); // กำหนดค่าของ status_preparation_zone
                    } else {
                        setStatus_preparation_zon(fetchedData); // ถ้าเป็น array แล้วเก็บไว้ใน state
                    }
                }
            })
            .catch(error => {
                console.log('error: ', error)
            })
    }


    const handleEditStatusRun = () => {
        setTimeout(() => {
            axios.post(`${apiUrl.PUT_TUMBLER_STATUS}`, {
                "plant_id": "4117",
                "factory": "00000000000000",
                "line": historyInRun?.LINE || '',
                "status": "Run"
            }, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        setEditStatus(1)
                        check_update()
                    }
                })
                .catch(error => {
                    if (error.request.status === 400) {
                        setEditStatus(2)
                    }
                    else {
                        setEditStatus(2)
                    }
                    console.log('edit status error: ', error);
                })
        });
    }

    const handleEditionPause = (event) => {
        if (event.target.name === "pause") {
            setHistorySelectedPause(event.target.value);
        }
    };

    const handleEditStatusPause = () => {
        setTimeout(() => {
            axios.post(`${apiUrl.PUT_TUMBLER_STATUS}`, {
                "plant_id": "4117",
                "factory": "00000000000000",
                "line": historyInPause?.LINE || '',
                "status": historySelectedPause
            }, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        setEditStatus(1)
                        check_update()
                    }
                })
                .catch(error => {
                    if (error.request.status === 400) {
                        setEditStatus(2)
                    }
                    else {
                        setEditStatus(2)
                    }
                    console.log('edit status error: ', error);
                })
        });
    }

    const handleEditStatusStop = () => {
        setTimeout(() => {
            axios.post(`${apiUrl.PUT_TUMBLER_STATUS}`, {
                "plant_id": "4117",
                "factory": "00000000000000",
                "line": historyInStop?.LINE || '',
                "status": "Stop"
            }, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        setEditStatus(1)
                        check_update()
                    }
                })
                .catch(error => {
                    if (error.request.status === 400) {
                        setEditStatus(2)
                    }
                    else {
                        setEditStatus(2)
                    }
                    console.log('edit status error: ', error);
                })
        });
    }


    useEffect(() => {
        if (popupTimeout === 2) {
            setOpenPopupTimeout(true);
        } else if (popupTimeout === 3) {
            setOpenPopupTimeout(true);
        }
    }, [popupTimeout]);

    useEffect(() => {
        const timer = setInterval(() => {
            setEditStatus(false)
        }, 3000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Status Line | MES</title>
            </Helmet>
            {done ? <Circular /> :
                <Box sx={{ width: "100%", padding: 2, backgroundColor: "#f8f9fa" }}>
                    {status_preparation_zone.map((item, index) => {
                        return (
                            <Accordion
                                key={index}
                                sx={{
                                    backgroundColor: "#ffffff",
                                    boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
                                    borderRadius: "8px",
                                    marginBottom: 1,
                                    transition: "0.3s ease-in-out",
                                    "&:hover": { boxShadow: "0px 5px 10px rgba(0,0,0,0.15)" },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                    sx={{ padding: "8px 16px" }}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item xs={1} sm={0.5}>
                                            <LensIcon fontSize="small" sx={{ color: item.STATUS == 'Run' ? '#C6E377' : (item.STATUS == 'Changge Product' || item.STATUS == 'Break' || item.STATUS == 'Cleaning') ? '#E2E2E2' : (item.STATUS == 'Material Shortage' || item.STATUS == 'Defect/Reject' || item.STATUS == 'Breakdown') ? '#E8453C' : item.STATUS == 'Stop' ? '#5D5D5D' : '#e2e2e2' }} />
                                        </Grid>
                                        <Grid item xs={3} sm={1}>
                                            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{item.LINE} :</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={8} textAlign="left" sx={{ paddingTop: 3 }}>
                                            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{item.STATUS == null ? "-" : item.STATUS}</Typography>
                                            <Grid item xs={12}>
                                                <Typography sx={{ fontSize: 14, color: "#777", fontStyle: "italic" }}>
                                                    {item.SKU}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} textAlign="right">
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    width: 32,
                                                    height: 32,
                                                    borderRadius: "50%",
                                                    minWidth: "unset",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 0,
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                    color: "#333",
                                                    borderColor: "#ccc",
                                                    transition: "0.2s ease-in-out",
                                                    "&:hover": { backgroundColor: "#f0f0f0" },
                                                }}
                                            >
                                                {item.SHIFT}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>

                                <AccordionDetails sx={{ backgroundColor: "#fafafa", padding: 2 }}>
                                    <Card
                                        sx={{
                                            p: 2,
                                            backgroundColor: "#ffffff",
                                            borderRadius: "8px",
                                            boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                                        }}
                                    >
                                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                                            <Grid item xs={4} sm={4} textAlign="center">
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        width: "100%", // ให้ปุ่มขยายเต็มความกว้าง
                                                        padding: "10px",
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                        color: "#333",
                                                        borderColor: "#ccc",
                                                        transition: "0.2s ease-in-out",
                                                        "&:hover": { backgroundColor: "#f0f0f0" },
                                                    }}
                                                    disabled={item.STATUS === "Run"}
                                                    onClick={() => { setHistoryInRun({ LINE: item.LINE, SKU: item.SKU, STATUS: item.STATUS }); handleOpenPopupRun(); }}
                                                >
                                                    Run
                                                </Button>
                                            </Grid>

                                            <Grid item xs={4} sm={4} textAlign="center">
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        width: "100%", // ให้ปุ่มขยายเต็มความกว้าง
                                                        padding: "10px",
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                        color: "#333",
                                                        borderColor: "#ccc",
                                                        transition: "0.2s ease-in-out",
                                                        "&:hover": { backgroundColor: "#f0f0f0" },
                                                    }}
                                                    disabled={item.STATUS === "Change Product" || item.STATUS === "Break" || item.STATUS === "Cleaning" || item.STATUS === "Material Shortage" || item.STATUS === "Defect/Reject" || item.STATUS === "Breakdown"}
                                                    onClick={() => { setHistoryInPause({ LINE: item.LINE, SKU: item.SKU, STATUS: item.STATUS }); handleOpenPopupPause(); }}
                                                >
                                                    Pause
                                                </Button>
                                            </Grid>

                                            <Grid item xs={4} sm={4} textAlign="center">
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        width: "100%", // ให้ปุ่มขยายเต็มความกว้าง
                                                        padding: "10px",
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                        color: "#333",
                                                        borderColor: "#ccc",
                                                        transition: "0.2s ease-in-out",
                                                        "&:hover": { backgroundColor: "#f0f0f0" },
                                                    }}
                                                    disabled={item.STATUS === "Stop"}
                                                    onClick={() => { setHistoryInStop({ LINE: item.LINE, SKU: item.SKU, STATUS: item.STATUS }); handleOpenPopupStop(); }}
                                                >
                                                    Stop
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </AccordionDetails>

                            </Accordion>
                        );
                    })}

                    <Dialog
                        open={openPopupRun}
                        onClose={handleClosePopupRun}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to "run" production of {historyInRun?.LINE || ''}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenPopupRun(false); handleEditStatusRun(); }}>
                                Yes
                            </Button>
                            <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenPopupRun(false); }}>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openPopupPause}
                        onClose={handleClosePopupPause}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title" >
                            Pause Detail - Reasons
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <FormControl sx={{ m: 1, minWidth: '90%' }}>
                                    <InputLabel >Select Pause</InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        open={openSelectPause}
                                        onClose={handleCloseSelectPause}
                                        onOpen={handleOpenSelectPause}
                                        value={historySelectedPause || ''}
                                        label="Select Pause"
                                        onChange={handleEditionPause}
                                        name="pause"
                                    >
                                        {pause_detail.map((item) => (
                                            <MenuItem value={item.value}>{item.value}</MenuItem>
                                        ))

                                        }
                                    </Select>
                                </FormControl>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenPopupPause(false); handleEditStatusPause() }}>
                                Yes
                            </Button>
                            <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenPopupPause(false); }}>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openPopupStop}
                        onClose={handleClosePopupStop}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to "stop" production of {historyInStop?.LINE || ''}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenPopupStop(false); handleEditStatusStop() }}>
                                Yes
                            </Button>
                            <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenPopupStop(false); }}>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Dialog
                        open={openPopupTimeout}
                        onClose={handleClosePopupTimeout}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {popupTimeout == 2 ? 'No data available' : 'Unable to load data. Please try again.'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { handleClosePopupTimeout(); popupTimeout == 2 ? handleClosePopupTimeout() : window.location.reload(false); }} autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {editStatus === 1 ?
                        <div>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open
                            >
                                <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                                    Successfully editted status
                                </Typography>
                            </Backdrop>
                        </div>
                        : console.log()
                    }
                    {editStatus === 2 ?
                        <div>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open
                            >
                                <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                                    Failed to edit status, Plead try again
                                </Typography>
                            </Backdrop>
                        </div>
                        : console.log()
                    }
                </Box>
            }
        </>
    )
}

export default StatusLineInInfeed

import React, { useEffect, useState } from "react";
import SliderSwiper from "../compenents/slider/SliderSwiper";
import GiveawayDetailPage1 from "../pages/GiveawayDetailPage1"
import GiveawayDetailPage2 from "../pages/GiveawayDetailPage2"



export default function GiveawayTab() {
    return (

        <SliderSwiper
            page1={<GiveawayDetailPage1 />}
            page2={<GiveawayDetailPage2 />}
        />
    );
}






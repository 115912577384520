import { useRoutes, Navigate } from 'react-router-dom';
import BUList from "./pages/BUList";
import PlantList from './pages/PlantList';
import FactoryList from "./pages/FactoryList"
import LineList from './pages/LineList';
import LineDetail from './pages/LineDetail';
import ExternalRedirect from "./components/auth/ExternalRedirect";
import MainLayout from './components/layout/MainLayout';
import PerformanceTab from './pages/PerformanceTab';
import GiveawayTab from './pages/GiveawayTab'
import MarinationTab from './pages/MarinationTab';
import InfeedTab from './pages/InfeedTab';
import AllUser from './pages/account_page/AllUser';
import Register from './pages/account_page/Register';
import UserEdit from './components/account/UserEdit';
import Setting from './pages/setting_page/Setting';
import MasterPlantEdit from './components/setting/MasterPlantEdit';
import NewMasterPlant from './pages/setting_page/NewMasterPlant';
import MasterPlantList from './pages/setting_page/MasterPlant';
import MasterSTDPlant from './pages/setting_page/MasterSTDPlant';
import MasterSTDFactory from './pages/setting_page/MasterSTDFactory'
import MasterSTDLisi from './pages/setting_page/MasterSTD'
import NewMasterSTD from './pages/setting_page/NewMasterSTD';
import StandardSettingEdit from './components/setting/StandardSettingEdit';
import CorrelationTab from './pages/CorrelationTab';
import OverviewTab from "./pages/mes_korat_on_web/OverviewTab";
import ProductionLine from './pages/mes_korat_on_web/ProductionLine';
import MainLayoutProductionTracking from './components/layout/MainLayoutProductionTracking';
import ProductionEggP1 from './pages/rollout/performance/ProductionEggP1';
import { useParams } from 'react-router-dom';
import PerformanceDetailFPP1 from './pages/fpp1/PerformanceDetailFPP1Page1';
import HeatingYield from './pages/heating_yield/HeatingYield';





const routes = (isLoggedIn) => [
  // Need auth pages
  {
    path: "/",
    element: isLoggedIn ? <MainLayout /> : <ExternalRedirect to="https://cpf.axonscloud.com/login" />,
    children: [
      { path: "/", element: <BUList /> },
      { path: ":bu/", element: <PlantList /> },
      { path: ":bu/:plant", element: <FactoryList /> },
      { path: ":bu/:plant/:factory", element: <LineList /> },
      { path: ":bu/:plant/:factory/:line", element: <LineDetail /> },
      { path: ":bu/:plant/:factory/:line/:detail", element: <DetailTabs />},
      { path: "account", element: <AllUser /> },
      { path: "register", element: <Register /> },
      { path: "user-edit", element: <UserEdit /> },
      { path: "setting", element: <Setting /> },
      { path: "new-master-plant", element: <NewMasterPlant /> },
      { path: "edit-master-plant", element: <MasterPlantEdit /> },
      { path: "list-master-plant", element: <MasterPlantList /> },
      { path: "master-std-plant", element: <MasterSTDPlant /> },
      { path: "master-std-factory", element: <MasterSTDFactory /> },
      { path: "list-master-std", element: <MasterSTDLisi /> },
      { path: "new-master-std", element: <NewMasterSTD /> },
      { path: "edit-master-std", element: <StandardSettingEdit /> },
      { path: "productionLine", element: <ProductionLine /> },
      { path: "/", element: <Navigate to="/home" /> },

    ],
  },

  {
    path: "/",
    element: isLoggedIn ? <MainLayoutProductionTracking /> : <ExternalRedirect to="https://cpf.axonscloud.com/login" />,
    children: [
      { path: "egg-nj/performance/line3/p1", element: <ProductionEggP1 /> },
    ],
},
];

const DetailTabs = () => {
  const { factory, detail } = useParams();

  switch (true) {
    case detail === "Performance" && factory === "FPP2":
      return <PerformanceTab />;
    case detail === "Giveaway" && factory === "FPP2":
      return <GiveawayTab />;
    case detail === "Marination Tracking":
      return <MarinationTab />;
    case detail === "MobileInfeed":
      return <InfeedTab />;
    case detail === "Heating Yield":
      return <HeatingYield />;
    case detail === "Unlockgiveaway":
      return <CorrelationTab />;
    case detail === "OverviewDashboard":
      return <OverviewTab />;
    case detail === "Performance" && factory === "FPP1":
      return <PerformanceDetailFPP1 />;
    default:
      return <BUList />;
  }
};

function RoutesConfig({ loggedIn }) {
  console.log('in Route config: ', loggedIn)
  const routing = useRoutes(routes(loggedIn));
  return routing;
}

export default RoutesConfig;
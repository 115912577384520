import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import moment from 'moment-timezone';
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ColumnChartGiveaway from "../compenents/chart/ColumnChartGiveaway";
import db from "../firebase-config";
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";



const GiveawayDetailPage2 = () => {
    const exchangeToken = useExchangeToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);

    const [chart_giveaway, setChart_giveaway] = useState([]);
    const [no_data, setNo_data] = useState(false);
    const [no_data_p2, setNo_data_p2] = useState(false);
    const [giveawayP1, setGiveawayP1] = useState({
        WARNING: "",
        PACK_SIZE_STD: "",
        SETTING_PACK_SIZE: ""
    });
    const [giveawayP2, setGiveawayP2] = useState({
        ACTUAL_PERCENT_GIVEAWAY: "",
        TARGET_GIVEAWAY: "",
        MIN_GIVEAWAY: ""
    });
    const [error, setError] = useState(null);


    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);



    useEffect(() => {
        const fetchInitialTokenAndData = async () => {
            try {
                const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
                if (token) {
                    setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
                }
            } catch (err) {
                console.error("Error exchanging token:", err);
                setError("Failed to exchange token");
            }
        };

        fetchInitialTokenAndData();
    }, [exchangeToken]);

    const api_giveaway = () => {
        try {
            // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
            const buName = localStorage.getItem("buName");
            const plant = localStorage.getItem("plant");
            const factory = localStorage.getItem("factory");
            const line = localStorage.getItem("line");

            // ตรวจสอบเงื่อนไขโดยใช้ object mapping
            const apiMapping = {
                "แปรรูปไก่_4117_00000000000000_Line7": apiUrl.GIVEAWAY_PAGE1,
                "แปรรูปไก่_4117_00000000000000_Line8": apiUrl.GIVEAWAY_LINE8_PAGE1,
                "แปรรูปไก่_4117_00000000000000_Line9": apiUrl.GIVEAWAY_LINE9_PAGE1,
                "แปรรูปไก่_4117_00000000000000_Line10": apiUrl.GIVEAWAY_LINE10_PAGE1,
            };

            // สร้าง key สำหรับจับคู่
            const key = `${buName}_${plant}_${factory}_${line}`;

            // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
            return apiMapping[key];
        } catch (error) {
            console.error("Error in api_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };

    const api_giveaway_p2 = () => {
        try {
            // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
            const buName = localStorage.getItem("buName");
            const plant = localStorage.getItem("plant");
            const factory = localStorage.getItem("factory");
            const line = localStorage.getItem("line");

            // ตรวจสอบเงื่อนไขโดยใช้ object mapping
            const apiMapping = {
                "แปรรูปไก่_4117_00000000000000_Line7": apiUrl.GIVEAWAY_PAGE2,
                "แปรรูปไก่_4117_00000000000000_Line8": apiUrl.GIVEAWAY_LINE8_PAGE2,
                "แปรรูปไก่_4117_00000000000000_Line9": apiUrl.GIVEAWAY_LINE9_PAGE2,
                "แปรรูปไก่_4117_00000000000000_Line10": apiUrl.GIVEAWAY_LINE10_PAGE2,
            };

            // สร้าง key สำหรับจับคู่
            const key = `${buName}_${plant}_${factory}_${line}`;

            // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
            return apiMapping[key];
        } catch (error) {
            console.error("Error in api_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };

    const line_giveaway = () => {
        try {
            // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
            const buName = localStorage.getItem("buName");
            const plant = localStorage.getItem("plant");
            const factory = localStorage.getItem("factory");
            const line = localStorage.getItem("line");

            // ตรวจสอบเงื่อนไขโดยใช้ object mapping
            const lineMapping = {
                "แปรรูปไก่_4117_00000000000000_Line7": 'line7',
                "แปรรูปไก่_4117_00000000000000_Line8": 'line8',
                "แปรรูปไก่_4117_00000000000000_Line9": 'line9',
                "แปรรูปไก่_4117_00000000000000_Line10": 'line10',
            };

            // สร้าง key สำหรับจับคู่
            const key = `${buName}_${plant}_${factory}_${line}`;

            // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
            return lineMapping[key];
        } catch (error) {
            console.error("Error in line_performance:", error);
            return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
    };


    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_giveaway_5_min_timeframe")
            //.where("TIMESTAMP", ">=", timestr_chart)
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                const filteredDocs = getPostFromFirebase.filter(doc =>
                    doc.id.includes(line_giveaway())
                );
                setChart_giveaway(filteredDocs);
                function groupBy(filteredDocs, property) {
                    return filteredDocs.reduce(function (memo, x) {
                        if (!memo[x[property]]) {
                            memo[x[property]] = [];
                        }
                        memo[x[property]].push(x);
                        return memo;
                    }, {});
                }

                //group by name
                const grouped = groupBy(getPostFromFirebase, "TIMESTAMP");
                const keys = Object.keys(grouped);
                var output = [];

                //loop keys
                keys.forEach(key => {
                    //merge using reduce
                    const out = grouped[key].reduce((current) => {
                        return {
                            GIVEAWAY_PERCENT: current.GIVEAWAY_PERCENT,
                            TIMESTAMP: current.TIMESTAMP,

                        }
                    });
                    output.push(out);
                });
                sessionStorage.setItem('time_giveaway', output.map(item =>
                    moment(new Date(item.TIMESTAMP)).tz('Asia/Bangkok').format('HH:mm')
                ));
                sessionStorage.setItem('value_giveaway', output.map(i => i.GIVEAWAY_PERCENT));
            });

        // return cleanup function
        return () => subscriber();
    }, [chart_giveaway]);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${api_giveaway()}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        setGiveawayP1({
                            WARNING: response.data[0].WARNING,
                            SETTING_PACK_SIZE: response.data[0].SETTING_PACK_SIZE,
                            PACK_SIZE_STD: response.data[0].PACK_SIZE_STD,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${api_giveaway_p2()}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        setGiveawayP2({
                            ACTUAL_PERCENT_GIVEAWAY: response.data[0].ACTUAL_PERCENT_GIVEAWAY,
                            TARGET_GIVEAWAY: response.data[0].TARGET_GIVEAWAY,
                            MIN_GIVEAWAY: response.data[0].MIN_GIVEAWAY,
                        })
                        setNo_data_p2(1)

                    }
                })
                .catch(error => {
                    setNo_data_p2(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);

    function groupBy(chart_giveaway, property) {
        return chart_giveaway.reduce(function (memo, x) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    //group by name
    const grouped = groupBy(chart_giveaway, "TIMESTAMP");
    const keys = Object.keys(grouped);

    const output = keys.map(key => {
        const item = grouped[key].reduce((current) => ({
            GIVEAWAY_PERCENT: current.GIVEAWAY_PERCENT || 0,  // Ensure default value
            TIMESTAMP: current.TIMESTAMP || 'default_timestamp',  // Ensure default timestamp
        }));
        return item;
    });

    const getValidValue = (value) => {
        return value === undefined || value === null || value === '-' ? 0 : Number(value);
    }


    const time_giveaway = output.map(item =>
        moment(new Date(item.TIMESTAMP)).tz('Asia/Bangkok').format('HH:mm')
    )

    const value_giveaway = output.map(i => i.GIVEAWAY_PERCENT == null ? 0 : i.GIVEAWAY_PERCENT);

    ///// store data in sessionStorage
    const array_value = sessionStorage.getItem('value_giveaway') == null ? [0] : sessionStorage.getItem('value_giveaway').split(",").map(Number);

    const array_time = sessionStorage.getItem('time_giveaway') == null ? [0] : sessionStorage.getItem('time_giveaway').split(",")


    const show_value = Array.isArray(value_giveaway) && value_giveaway.length > 0 ? value_giveaway : array_value.length > 0 ? array_value : [0];
    const show_time = Array.isArray(time_giveaway) && time_giveaway.length > 0 ? time_giveaway : array_time.length > 0 ? array_time : ['00:00'];

    // Ensure valid target value
    const target = getValidValue(no_data === 1 ? giveawayP2.TARGET_GIVEAWAY : sessionStorage.getItem('target_giveaway'));

    const valid_show_value = show_value.map(getValidValue);
    const valid_show_time = show_time.map(time => time || '00:00');

    const sliced_show_value = valid_show_value.slice(-12);
    const sliced_show_time = valid_show_time.slice(-12);

    return (
        <>
            <Helmet>
                <title>Giveaway Page2 | MES</title>
            </Helmet>
            <Grid item xs={12} sm={12} style={{ backgroundColor: colors.neutral['black'], paddingTop: 10, paddingLeft: 50 }}>
                <Grid item xs={12} textAlign="left" style={{ paddingRight: 20 }}>
                    <Typography style={{ color: colors.neutral['white'], fontSize: 60, fontWeight: 'bold' }} >
                        Realtime Giveaway (%)
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid item xs={8} >
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <ColumnChartGiveaway
                                    value={sliced_show_value}
                                    time={sliced_show_time}
                                    setting={no_data == 1 ? Number(giveawayP1.SETTING_PACK_SIZE) : 0}
                                    std={no_data == 1 ? Number(giveawayP1.PACK_SIZE_STD) : 0}
                                    target={target}
                                    height='300%'
                                    fontsize={24}
                                />

                            </Grid>
                        </Grid>

                        <Grid item xs={4} style={{ paddingTop: 30 }}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: colors.neutral['white'], fontSize: 42, fontWeight: 'bold' }} >
                                    Best Practice
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: '#4AC6B1', fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data_p2 == 1 ? (giveawayP2.MIN_GIVEAWAY == undefined ? '-' : (giveawayP2.MIN_GIVEAWAY == 'None' ? '-' : Number(giveawayP2.MIN_GIVEAWAY))) : '-'}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 50 }}>
                                <Typography style={{ color: colors.neutral['white'], fontSize: 42, fontWeight: 'bold' }} >
                                    Target
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: '#4AC6B1', fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data_p2 == 1 ? (giveawayP2.TARGET_GIVEAWAY == undefined ? '-' : (giveawayP2.TARGET_GIVEAWAY == 'None' ? '-' : Number(giveawayP2.TARGET_GIVEAWAY))) : '-'}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 50 }} >
                                <Typography style={{ color: colors.neutral['white'], fontSize: 42, fontWeight: 'bold' }} >
                                    Actual
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: '#4AC6B1', fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data_p2 == 1 ? (giveawayP2.ACTUAL_PERCENT_GIVEAWAY == undefined ? '-' : (giveawayP2.ACTUAL_PERCENT_GIVEAWAY == 'None' ? '-' : Number(giveawayP2.ACTUAL_PERCENT_GIVEAWAY))) : '-'}%
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default GiveawayDetailPage2

export const apiUrl = {
REFRESH_TOKEN_URL: `https://immjai-backendapi-ecs.cpfmes.com/account/token/refresh/`,
EXCHANGE_TOKEN_URL:`https://immjai-backendapi-ecs.cpfmes.com/account/exchange-token/`,
GET_TUMBLER_BETWEEN_2DAY: `https://immjai-backendapi-ecs.cpfmes.com/api/tumbler/4117/00000000000000/`,
GET_TUMBLER_CHECK_STATUS: `https://immjai-backendapi-ecs.cpfmes.com/api/tumbler/4117/00000000000000/`,
POST_INSERT_TUMBLER: `https://immjai-backendapi-ecs.cpfmes.com/api/tumbler/`,
PUT_UPDATE_TUMBLER: `https://immjai-backendapi-ecs.cpfmes.com/api/tumbler-update/`,
MASTER_BU: `https://immjai-backendapi-ecs.cpfmes.com/api/master-plant/bu`,
MASTER_PLANT: `https://immjai-backendapi-ecs.cpfmes.com/api/master-plant/`,
USER: `https://immjai-backendapi-ecs.cpfmes.com/account/user/`,
REGISTER: `https://immjai-backendapi-ecs.cpfmes.com/account/register-user/`,
MASTER_PLANT_STD: `https://immjai-backendapi-ecs.cpfmes.com/api/model-master-plant/`,
EDIT_MASTER_PLANT: `https://immjai-backendapi-ecs.cpfmes.com/api/master-plant/`,
GET_MASTER_STD: `https://immjai-backendapi-ecs.cpfmes.com/api/master-std/`,
POST_MASTER_STD: `https://immjai-backendapi-ecs.cpfmes.com/api/model-master-std/`,
UNLOCK_GIVEAWAY: `https://immjai-backendapi-ecs.cpfmes.com/api/function/giveaway/`,


PERFORMANCE_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page1/line7`,
PERFORMANCE_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page2/line7`,
PERFORMANCE_LINE8_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page1/line8`,
PERFORMANCE_LINE8_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page2/line8`,
PERFORMANCE_LINE9_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page1/line9`,
PERFORMANCE_LINE9_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page2/line9`,
PERFORMANCE_LINE10_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page1/line10`,
PERFORMANCE_LINE10_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page2/line10`,

GIVEAWAY_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page1/line7`,
GIVEAWAY_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page2/line7`,
GIVEAWAY_LINE8_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page1/line8`,
GIVEAWAY_LINE8_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page2/line8`,
GIVEAWAY_LINE9_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page1/line9`,
GIVEAWAY_LINE9_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page2/line9`,
GIVEAWAY_LINE10_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page1/line10`,
GIVEAWAY_LINE10_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page2/line10`,

}
import TopBarProductionTracking from "./TopBarProductionTracking";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { useState } from "react";
import { Outlet } from 'react-router-dom';
import * as React from 'react';
import CPFImmSook from '../../assets/fonts/CPF_Imm_Sook_Font/CPF_Imm_Sook_Regular.ttf';



const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

function MainLayoutProductionTracking() {
  const [mode, setMode] = useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          //mode,
          background: {
            default: '#000000',
          },
        },
        typography: {
          fontFamily: "CPF_Imm_Sook"
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              "@font-face": {
                fontFamily: "CPF_Imm_Sook",
                src: `url(${CPFImmSook}) format("truetype")`
              },
            }
          }
        }
      }),
  );
  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box>
            <TopBarProductionTracking />
            <Outlet />
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  )
}

export default MainLayoutProductionTracking
import * as React from 'react';
import { Box, Grid, Typography} from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



function GrigToProductionTracking(props) {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: props.color,
        padding: theme.spacing(0),
        textAlign: 'center',
        //border: '0.8px dashed #2e9281',
        borderRadius: 15,
        //height: '80%'

    }));

    return (

        <Grid item xs={6}  >
            <Grid item xs={12} textAlign="center" style={{ paddingRight: 10, paddingLeft: 10 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center">
                    <Typography >
                        {props.image}
                    </Typography>
                    <Typography style={{ color: "#ffffff", fontSize: 40, lineHeight: "0vh", paddingTop: 20}}>
                    &nbsp;{props.section}
                    </Typography>
                    <Typography style={{ color: props.color_persent, fontSize: 50, lineHeight: "0vh", paddingTop: 16}}>
                    &nbsp;{props.persent}%
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} textAlign="center" style={{ paddingRight: 40, paddingLeft: 40 }}>
                <Item>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center">
                        <Typography style={{ color: '#ffffff', fontSize: 70, lineHeight: "23vh"}}>
                            {props.value}
                        </Typography>
                        <Typography style={{ color: '#ffffff', fontSize: 30, paddingTop: 12, lineHeight: "23vh" }}>
                        &nbsp;กก.
                        </Typography>
                    </Box>
                </Item>
            </Grid>
        </Grid>
    )
}


export default GrigToProductionTracking
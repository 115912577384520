import React, { useEffect, useState } from "react";
import SliderSwiper from "../compenents/slider/SliderSwiper";
import MarinationTrackingPage1 from "../pages/MarinationTrackingPage1";
import MarinationTrackingPage2 from "../pages/MarinationTrackingPage2";




export default function MarinationTab() {
    return (

        <SliderSwiper
            page1={<MarinationTrackingPage1 />}
            page2={<MarinationTrackingPage2 />}
        />
    );
}






import { useRoutes, Navigate } from 'react-router-dom';
import BUList from "./pages/BUList";
import PlantList from './pages/PlantList';
import FactoryList from "./pages/FactoryList"
import LineList from './pages/LineList';
import LineDetail from './pages/LineDetail';
import ExternalRedirect from "./compenents/auth/ExternalRedirect";
import MainLayout from './compenents/layout/MainLayout';
import PerformanceTab from './pages/PerformanceTab';
import GiveawayTab from './pages/GiveawayTab'
import MarinationTab from './pages/MarinationTab';
import InfeedTab from './pages/InfeedTab';
import MainLayoutProductionTracking from './compenents/layout/MainLayoutProductionTracking';
import ProductionLine8P1 from './monitor/productionTracking/ProductionLine8P1';
import ProductionLine9P1 from './monitor/productionTracking/ProductionLine9P1';
import ProductionLine10P1 from './monitor/productionTracking/ProductionLine10P1';
import ProductionLine8P2 from './monitor/productionTracking/ProductionLine8P2';
import ProductionLine9P2 from './monitor/productionTracking/ProductionLine9P2';
import ProductionLine10P2 from './monitor/productionTracking/ProductionLine10P2';
import GiveawayLine8P1 from './monitor/giveaway/GiveawayLine8P1';
import GiveawayLine9P1 from './monitor/giveaway/GiveawayLine9P1';
import GiveawayLine10P1 from './monitor/giveaway/GiveawayLine10P1';
import GiveawayLine8P2 from './monitor/giveaway/GiveawayLine8P2';
import GiveawayLine9P2 from './monitor/giveaway/GiveawayLine9P2';
import GiveawayLine10P2 from './monitor/giveaway/GiveawayLine10P2';
import AllUser from './pages/account_page/AllUser';
import Register from './pages/account_page/Register';
import UserEdit from './compenents/account/UserEdit';
import Setting from './pages/setting_page/Setting';
import MasterPlantEdit from './compenents/setting/MasterPlantEdit';
import NewMasterPlant from './pages/setting_page/NewMasterPlant';
import MasterPlantList from './pages/setting_page/MasterPlant';
import MasterSTDPlant from './pages/setting_page/MasterSTDPlant';
import MasterSTDFactory from './pages/setting_page/MasterSTDFactory'
import MasterSTDLisi from './pages/setting_page/MasterSTD'
import NewMasterSTD from './pages/setting_page/NewMasterSTD';
import StandardSettingEdit from './compenents/setting/StandardSettingEdit';
import CorrelationTab from './pages/CorrelationTab';



const routes = (isLoggedIn) => [
    // Need auth pages
    {
      path: "/",
      element: isLoggedIn ? <MainLayout /> : <ExternalRedirect to="https://cpf-test.uat.axonscloud.com/" />,
      children: [
        { path: "/", element: <BUList /> },
        { path: ":bu/", element: <PlantList /> },
        { path: ":bu/:plant", element: <FactoryList /> },
        { path: ":bu/:plant/:factory", element: <LineList /> },
        { path: ":bu/:plant/:factory/:line", element: <LineDetail /> },
        { path: ":bu/:plant/:factory/:line/:detail", element: ( localStorage.getItem("factoryName") === "Performance" ? <PerformanceTab />: localStorage.getItem("factoryName") === "Yield Loss" ? <GiveawayTab /> : localStorage.getItem("factoryName") === "Marination Tracking" ? <MarinationTab /> : localStorage.getItem("factoryName") === "Infeed" ?  <InfeedTab/> : <CorrelationTab/>) },
        { path: "account", element: <AllUser /> },
        { path: "register", element: <Register /> },
        { path: "user-edit", element: <UserEdit /> },
        { path: "setting", element: <Setting /> },
        { path: "new-master-plant", element: <NewMasterPlant /> },
        { path: "edit-master-plant", element: <MasterPlantEdit /> },
        { path: "list-master-plant", element: <MasterPlantList /> },
        { path: "master-std-plant", element: <MasterSTDPlant /> },
        { path: "master-std-factory", element: <MasterSTDFactory /> },
        { path: "list-master-std", element: <MasterSTDLisi /> },
        { path: "new-master-std", element: <NewMasterSTD /> },
        { path: "edit-master-std", element: <StandardSettingEdit /> },
        { path: "/", element: <Navigate to="/" /> },
      ],
    },
/// No Need auth page
    {
      path: "/",
      element: /*!isLoggedIn ?*/ <MainLayoutProductionTracking /> /*: <ExternalRedirect to="https://cpf-test.uat.axonscloud.com/" />*/,
      children: [
          { path: "line-monitor/korat/line8/p1", element: <ProductionLine8P1 />},
          { path: "line-monitor/korat/line9/p1", element: <ProductionLine9P1 />},
          { path: "line-monitor/korat/line10/p1", element: <ProductionLine10P1 />},
          { path: "line-monitor/korat/line8/p2", element: <ProductionLine8P2 />},
          { path: "line-monitor/korat/line9/p2", element: <ProductionLine9P2 />},
          { path: "line-monitor/korat/line10/p2", element: <ProductionLine10P2 />},
          { path: "line-monitor/korat/line8/p1", element: <GiveawayLine8P1 />},
          { path: "line-monitor/korat/line9/p1", element: <GiveawayLine9P1 />},
          { path: "line-monitor/korat/line10/p1", element: <GiveawayLine10P1 />},
          { path: "line-monitor/korat/line8/p2", element: <GiveawayLine8P2 />},
          { path: "line-monitor/korat/line9/p2", element: <GiveawayLine9P2 />},
          { path: "line-monitor/korat/line10/p2", element: <GiveawayLine10P2 />},
      ],
    }
  ];
  
  function RoutesConfig({ loggedIn }) {
    console.log('in Route config: ', loggedIn)
    const routing = useRoutes(routes(loggedIn));
    return routing;
  }
  
  export default RoutesConfig;
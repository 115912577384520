import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import RoutesConfig from './RoutesConfig';
import { useAuth } from './hooks/useAuth';
import AuthGuard from './compenents/auth/AuthGuard';
import store from './redux/store';

const App = () => {
  const { isLogin } = useAuth(); // ใช้ useAuth ในการเช็คสถานะการเข้าสู่ระบบ

  return (
    <Provider store={store}>
      <Router>
        <div>
          <div className="h-screen bg-default-50">
            <div className="page-content container mx-auto h-full">
              <AuthGuard>
                <RoutesConfig loggedIn={isLogin} />
              </AuthGuard>
            </div>
          </div>
        </div>
      </Router>
    </Provider>
  );
};

export default App;

import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Link,
    Grid
  } from "@material-ui/core";
  import { Link as RouterLink, useNavigate } from "react-router-dom";
  import PersonAdd from '@mui/icons-material/PersonAdd';
  
  function UserToolbar(props) {
    return (
      <Box {...props}>
         <Grid item xs={12} >
          <Button
            component={RouterLink}
            to="/register"
            variant="contained"
            style={{ borderRadius: 5, fontSize: 16, fontWeight: "bold", background: '#575755' }}
          >
            Create new account
          </Button>
        </Grid>
      </Box>
    );
  }
  
  UserToolbar.propTypes = {};
  
  export default UserToolbar;
  
import { useRef, useEffect, useState, useMemo } from 'react'
import moment from 'moment-timezone';
import '../styles.css'
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import { Box, Grid, Card, Tooltip, Stack, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Slide
} from "@material-ui/core/";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setState } from '../redux/authSlice';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";

const slideProps = {
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: { enter: "auto", exit: "auto" }
};



const RejectInInfeed = () => {
    const exchangeToken = useExchangeToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const today = new Date();
    const hourago = new Date(today.getTime());
    const hourago_start = new Date(today.getTime() - (24 * 60 * 60 * 1000));
    const datetime = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
    const date_start = moment(hourago_start).tz('Asia/Bangkok').format('YYYY-MM-DD');
    const date_end = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [reject_preparation_zone, setReject_preparation_zone] = useState([]);

    const [noData, setNoData] = useState(false);
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);


    useEffect(() => {
        const fetchInitialTokenAndData = async () => {
            try {
                const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
                if (token) {
                    setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
                }
            } catch (err) {
                console.error("Error exchanging token:", err);
                setError("Failed to exchange token");
            }
        };

        fetchInitialTokenAndData();
    }, [exchangeToken]);


    useEffect(() => {
        const fetchMyAPI = async () => {
            if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
            setLoading(true);
            try {
                const response = await axios.get(`${apiUrl.GET_TUMBLER_BETWEEN_2DAY}${date_start}/${date_end}/`, {
                    headers: {
                        Authorization: `Bearer ${ac_token}`,
                    },
                });

                if (response.status === 200) {
                    setReject_preparation_zone(response.data.filter(i => i.STATUS === "Reject"))
                }
            } catch (err) {
                setError(err.message);
                console.error("Error fetching instock data:", err);
            } finally {
                setLoading(false);
            }
        };

        if (isTokenExchanged && ac_token) {
            fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
        }
    }, [isTokenExchanged, ac_token]);

    const sort_factory_date = reject_preparation_zone.sort((a, b) => a.FACTORY_DATE.localeCompare(b.FACTORY_DATE));

    const sort_batch = sort_factory_date.sort((a, b) => a.AT_REJECTED_TIMESTAMP.localeCompare(b.AT_REJECTED_TIMESTAMP));



    const categories = useMemo(() => {
        const categories = {};
        reject_preparation_zone.forEach((item) => {
            if (!categories[item.MATERIAL_DESCRIPTION]) {
                categories[item.MATERIAL_DESCRIPTION] = [];
            }
            categories[item.MATERIAL_DESCRIPTION].push(item);
        });
        return categories;
    }, [reject_preparation_zone]);



    return (
        <>
            <Helmet>
                <title>Completed | MES</title>
            </Helmet>

            <Grid container spacing={2} style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }} >
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }} >
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ position: "relative", top: '0vw', height: '0px' }}>
                            <Box >
                                <div>
                                    {Object.entries(categories).map((entry) => {
                                        const category = entry[0];
                                        const itemList = entry[1];

                                        const categories_time = {};
                                        for (const item of itemList) {
                                            if (item.FACTORY_DATE in categories_time) {
                                                categories_time[item.FACTORY_DATE].push(item);
                                            } else {
                                                categories_time[item.FACTORY_DATE] = [item];
                                            }
                                        }

                                        return (
                                            <Accordion
                                                TransitionComponent={Slide}
                                                TransitionProps={slideProps}
                                                sx={{
                                                    backgroundColor: "#ebebeb"
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {Object.entries(categories_time).map((entry) => {
                                                        const category_list = entry[0];
                                                        const item_list = entry[1];
                                                        return (
                                                            <Accordion
                                                                TransitionComponent={Slide}
                                                                TransitionProps={slideProps}
                                                                sx={{
                                                                    backgroundColor: "#ebebeb"
                                                                }}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category_list}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    {item_list.map((item) => {

                                                                        return <Card sx={{
                                                                            p: 1,
                                                                            margin: 'auto',
                                                                            //maxWidth: 1500,
                                                                            flexGrow: 1,
                                                                            backgroundColor: (theme) =>
                                                                                theme.palette.mode === 'dark' ? '#1A2027' : '#ebebeb',
                                                                        }}>
                                                                            <Grid item xs={12} sm container>
                                                                                <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "0vw" }}>
                                                                                    <Grid item xs>
                                                                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                            <Typography style={{ fontSize: 18, fontWeight: 'bold' }} gutterBottom>
                                                                                                {item.MATERIAL_DESCRIPTION}&nbsp;&nbsp;&nbsp;
                                                                                            </Typography>

                                                                                        </Box>

                                                                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                            <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px', width: '80px' }} onClick={() => { }}>
                                                                                                {item.LINE}
                                                                                            </Typography>
                                                                                            <Button style={{ backgroundColor: '#E8AB6D', color: "black", fontSize: 18, height: '20px' }} onClick={() => { }}>
                                                                                                {item.SHIFT}
                                                                                            </Button>
                                                                                        </Box>

                                                                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '5%' }} textAlign="left" >

                                                                                        </Box>

                                                                                        <Typography style={{ fontSize: 15, width: '1000%' }} gutterBottom>
                                                                                            {(item.AT_REJECTED_TIMESTAMP).split("Z")[0]}
                                                                                        </Typography>
                                                                                        <Typography style={{ fontSize: 18 }} gutterBottom>
                                                                                            ชุดนวด: {item.BATCH}
                                                                                        </Typography>
                                                                                        <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px' }}>
                                                                                            {item.ACTUAL_BARREL_WEIGHT} kg.
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs container spacing={1} style={{ position: "relative", top: "1vw" }} textAlign="right">
                                                                                    <Grid item xs={12} md={12} lg={12} xl={12}>
                                                                                        <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                                                                                            <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} >IN STOCK</Button>
                                                                                            <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} >PROCESSING</Button>
                                                                                            <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} >COMPLETED</Button>
                                                                                            <Button style={{ height: '30px', width: '85px', fontWeight: 'bold', color: '#eb2626', backgroundColor: '#c7c9c8', fontSize: 15 }} >REJECT</Button>
                                                                                        </ButtonGroup>
                                                                                    </Grid>

                                                                                </Grid>
                                                                            </Grid>
                                                                        </Card>
                                                                    })}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        );
                                                    })}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </div>
                            </Box>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>


        </>
    )
}

export default RejectInInfeed

// src/components/ExternalRedirect.js
import React, { useEffect } from 'react';

function ExternalRedirect({ to }) {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return null;
}

export default ExternalRedirect;

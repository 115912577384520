import React, { useEffect, useRef } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthGuard = ({ children }) => {
  const { isLogin, loading, silentLogin, kit } = useAuth();
  const sessionData = useSelector(state => state.auth);  // ใช้ useSelector เพื่อดึงข้อมูลจาก store

  // log ข้อมูลจาก store
  // console.log('Current Session Data:', sessionData);

  const isSilentCalled = useRef(false);
  const [searchParams] = useSearchParams();
  const sessionKey = searchParams.get('sessionKey');

  localStorage.setItem('sessionKey', sessionKey);

  useEffect(() => {
    if (kit && silentLogin && !isSilentCalled.current) {
      isSilentCalled.current = true;
      silentLogin(sessionKey || undefined);
    }
  }, [kit, silentLogin, sessionKey]);

  return isLogin ? (
    <>{children}</>
  ) : !isLogin && loading ? (
    <div className="flex justify-center items-center py-3 h-full">Loading...</div>
  ) : (
    ""
  );
};

export default AuthGuard;

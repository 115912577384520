import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import CardPhoto from '../compenents/card/CardPhotoToUseLocation';
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import chickenKoratImg from "../assets/img/chickenKorat.jpg"
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";


const CardPhotoMemo = React.memo(CardPhoto);

const LineDetail = () => {
  const exchangeToken = useExchangeToken();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [module, setModule] = useState([]);
  const [isTokenExchanged, setIsTokenExchanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useCheckToken();

  const ac_token = useSelector(state => state.auth.access_token);


  const list = localStorage.getItem("buName") === "แปรรูปไก่" && localStorage.getItem("factoryName") === "Line 7" ? [
    { id: 1, name: "Performance", fileLocation: chickenKoratImg },
    { id: 2, name: "Yield Loss", fileLocation: chickenKoratImg },
    { id: 3, name: "Marination Tracking", fileLocation: chickenKoratImg },
    { id: 4, name: "Infeed", fileLocation: chickenKoratImg },
    { id: 5, name: "Correlation", fileLocation: chickenKoratImg },
  ]
    : [
      { id: 1, name: "Performance", fileLocation: chickenKoratImg },
      { id: 2, name: "Yield Loss", fileLocation: chickenKoratImg },
      { id: 3, name: "Marination Tracking", fileLocation: chickenKoratImg },
      { id: 4, name: "Infeed", fileLocation: chickenKoratImg },
      { id: 5, name: "Correlation", fileLocation: chickenKoratImg },
    ]


  useEffect(() => {
    const fetchInitialTokenAndData = async () => {
      try {
        const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
        if (token) {
          setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
        }
      } catch (err) {
        console.error("Error exchanging token:", err);
        setError("Failed to exchange token");
      }
    };

    fetchInitialTokenAndData();
  }, [exchangeToken]);


  useEffect(() => {
    const fetchMyAPI = async () => {
      if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl.MASTER_PLANT}${localStorage.getItem("buName")}/${localStorage.getItem("plant")}/${localStorage.getItem("factory")}/${localStorage.getItem("line")}`, {
          headers: {
            Authorization: `Bearer ${ac_token}`,
          },
        });

        if (response.status === 200) {
          setModule(response.data.MODULE || [])
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching module data:", err);
      } finally {
        setLoading(false);
      }
    };

    if (isTokenExchanged && ac_token) {
      fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
    }
  }, [isTokenExchanged, ac_token]);


  const moduleData = useMemo(
    () =>
      module.length === 0
        ? []
        : Object.keys(module).map((key) => ({ key, name: module[key] })),
    [module]
  );



  return (
    <Box style={{ width: "100%", height: "90vh" }}>
      <Helmet>
        <title>Line | MES</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            m='1%'
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="100%"
            gap="1%"
          >
            {/* ESTIMATED */}
            {moduleData.map((item) => (
              <Box
                key={item.key || item.id}
                gridColumn="span 2"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CardPhotoMemo name={item.name} fileLocation={item.fileLocation || chickenKoratImg} />
              </Box>
            ))
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LineDetail

import { createSlice } from '@reduxjs/toolkit';
import { AxpKit } from '@axons-library/portal-js-sdk';

const initialState = {
  accessToken: '',
  refreshToken: '',
  access_token: '',
  refresh_token: '',
  rb_ac_exp: '',
  rb_rf_exp: '',
  sessionKey: '',
  display: '',
  isRefreshing: false,
  kit: null,
  activeSession: null,
  loading: true,
  myApps: [],
  fetching: false,
  isLogin: false,
  initialized: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setState: (state, action) => {
      Object.assign(state, action.payload);
    },
    setKit: (state, action) => {
      state.kit = action.payload;
    },
  },
});

export const { setState, setKit } = authSlice.actions;
export default authSlice.reducer;

import React, { useEffect, useState } from "react";
import { Box, Grid, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment-timezone';
import axios from 'axios';
import { apiUrl } from "../../constants";
import OEE from "../../components/chart/OEE";
import APQ from "../../components/chart/APQ";
import BarChartAccumulativeProduction from "../../components/chart/BarChartAccumulativeProduction";
import Defect from '../../components/chart/Defect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Chart from "react-apexcharts";
import LensIcon from '@mui/icons-material/Lens';
import Rightarrow from "../../assets/img/svg/right-arrow.svg";
import { useSelector } from 'react-redux';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  height: '99%',
  color: theme.palette.text.secondary,
}));




function sumArray(array) {
  let sum = 0;
  for (const item of array) {
    sum += item;
  }
  return sum;
}
export default function ReportYerterdayLine7() {
  const exchangeToken = useExchangeToken();
  const [isTokenExchanged, setIsTokenExchanged] = useState(false);
  const [error, setError] = useState(null);
  const today = new Date();
  const hourago = new Date(today.getTime() - (24 * 60 * 60 * 1000)); //hourago = new Date(today.getTime());
  const timestr = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');

  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(true);
  const [productionDetail, setProductionDetail] = useState([]);
  const [dailyReport, setDailyReport] = useState({
    TARGET_PRODUCTION_QUANTITY: "",
    PRODUCTION_UNIT: "",
    ACTUAL_WEIGHT: "",
    ACTUAL_KG_PER_PERSON_PER_HOUR: "",
    STD_KG_PER_PERSON_PER_HOUR: "",
    EMPLOYEE: "",
    STD_EMPLOYEE: "",
    YIELD: "",
    STD_YIELD: "",
    A: "",
    P: "",
    Q: "",
    OEE: "",
    A_TARGET: "",
    P_TARGET: "",
    Q_TARGET: "",
    OEE_TARGET: "",
    unit: "",
    ACTUAL_KG_PER_HOUR: "",
    STD_KG_PER_HOUR: "",
  })
  const [defectDonutDataSeries, setDefectDonutDataSeries] = useState();
  const [layout, setLayout] = useState([]);

  useCheckToken();
  const ac_token = useSelector(state => state.auth.access_token);

  useEffect(() => {
      const exchange = async () => {
          if (!isTokenExchanged) {
              try {
                  await exchangeToken(); // แลกเปลี่ยนโทเค็น
                  setIsTokenExchanged(true);
              } catch (err) {
                  console.error("Error exchanging token:", err);
                  setError("Failed to exchange token");
              }
          }
      };

      exchange();
  }, [isTokenExchanged, exchangeToken]);


  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.DAILY_REPORT}${timestr}/`, {
        headers: {
          Authorization: `Bearer ${ac_token}`,
      },
      }
      )
        .then(response => {
          // console.log(response)
          if (response.status == 200) {
            setDailyReport({
              TARGET_PRODUCTION_QUANTITY: response.data.REPORT[0].TARGET_PRODUCTION_QUANTITY,
              PRODUCTION_UNIT: response.data.REPORT[0].PRODUCTION_UNIT,
              ACTUAL_WEIGHT: response.data.REPORT[0].ACTUAL_WEIGHT,
              ACTUAL_KG_PER_PERSON_PER_HOUR: response.data.REPORT[0].ACTUAL_KG_PER_PERSON_PER_HOUR,
              STD_KG_PER_PERSON_PER_HOUR: response.data.REPORT[0].STD_KG_PER_PERSON_PER_HOUR,
              EMPLOYEE: response.data.REPORT[0].EMPLOYEE,
              STD_EMPLOYEE: response.data.REPORT[0].STD_EMPLOYEE,
              YIELD: response.data.REPORT[0].YIELD,
              STD_YIELD: response.data.REPORT[0].STD_YIELD,
              A: response.data.REPORT[0].A != 'NA' ? response.data.REPORT[0].A : 0,
              P: response.data.REPORT[0].P != 'NA' ? response.data.REPORT[0].P : 0,
              Q: response.data.REPORT[0].Q != 'NA' ? response.data.REPORT[0].Q : 0,
              OEE: response.data.REPORT[0].OEE != 'NA' ? response.data.REPORT[0].OEE : 0,
              A_TARGET: response.data.REPORT[0].A_TARGET,
              P_TARGET: response.data.REPORT[0].P_TARGET,
              Q_TARGET: response.data.REPORT[0].Q_TARGET,
              OEE_TARGET: response.data.REPORT[0].OEE_TARGET,
              unit: response.data.REPORT[0].unit,
              ACTUAL_KG_PER_HOUR: response.data.REPORT[0].ACTUAL_KG_PER_HOUR,
              STD_KG_PER_HOUR: response.data.REPORT[0].STD_KG_PER_HOUR
            })
            setProductionDetail(response.data.PRODUCTION_DETAIL)
            setLayout(response.data.LAYOUT.filter((ele, ind) => ind === response.data.LAYOUT.findIndex(elem => elem.MACHINE_NAME === ele.MACHINE_NAME && elem.MATERIAL_DESCRIPTION === ele.MATERIAL_DESCRIPTION))) /// how to remove duplicate objects from an array in reactjs(https://stackoverflow.com/questions/55429037/how-to-remove-duplicate-objects-from-an-array-in-reactjs)
            //setDefect(response.data.DEFECT)
            setLoad(false)
          }
        })
        .catch(error => {
          setDailyReport('No Data')
          setLayout('No Data')
          console.log('error: ', error)
        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
    return () => {
      clearInterval(interval);
    }

  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.GET_DEFECT_FOR_DONUT}LINE7/${timestr}/`, {
        headers: {
          Authorization: `Bearer ${ac_token}`,
      },
      }
      )
        .then(response => {
          // console.log(response)
          if (response.status == 200) {
            setDefectDonutDataSeries(response.data)
            setLoading(false)
          }
        })
        .catch(error => {
          setDefectDonutDataSeries('No Data')
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    /*const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
    return () => {
      clearInterval(interval);
    }*/

  }, [])



  let nameFryer1 = []
  let nameFryer2 = []
  let nameMultihead = []
  let nameCheckWeight = []
  let nameXray = []
  let nameMD = []

  for (let i = 0; i < layout.length; i++) {
    if (layout[i].MACHINE_NAME == 'EFRB5108') {
      nameFryer1.push(layout[i].MATERIAL_DESCRIPTION)
    }
    if (layout[i].MACHINE_NAME == 'EFRB5107') {
      nameFryer2.push(layout[i].MATERIAL_DESCRIPTION)
    }
    if (layout[i].MACHINE_NAME == 'Multihead1') {
      nameMultihead.push(layout[i].MATERIAL_DESCRIPTION)
    }
    if (layout[i].MACHINE_NAME == 'CheckWeight') {
      nameCheckWeight.push(layout[i].MATERIAL_DESCRIPTION)
    }
    if (layout[i].MACHINE_NAME == 'Xray') {
      nameXray.push(layout[i].MATERIAL_DESCRIPTION)
    }
    if (layout[i].MACHINE_NAME == 'MetalDetector') {
      nameMD.push(layout[i].MATERIAL_DESCRIPTION)
    }
  }


  if (Array.isArray(dailyReport)) {
    console.log(dailyReport.pop());
  } else {
    //console.log('The value is NOT an array')
  }





  return (
    <>
      <Helmet>
        <title>Report Line7 | MES</title>
      </Helmet>
      <Grid container spacing={2} style={{paddingLeft: 0}}>
        {/* Top row */}
        <Grid id="top-row" container sx={{ pt: 1.5, pl: 2 }} spacing={2}>
          <Box sx={{ width: '100%', bgcolor: "#32adad", display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
            <Typography style={{ color: "white", fontWeight: "bold", fontSize: "22px" }} variant="button" >
              Report LINE7
            </Typography>
          </Box>
        </Grid>
        {/* left column */}
        <Grid container spacing={1} >
          <Grid item xs={7} lg={7}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} xl={12}>
                <Item>
                  <Box width="100%" sx={{ background: "#d4d4d4" }}>
                    <Typography fontSize={'21px'} fontWeight="bold">
                      ข้อมูลการผลิต
                    </Typography>
                  </Box>
                  <Box mb={-10}>
                    <Grid container spacing="18px" alignItems="left" justifyContent="left" style={{ position: "relative", top: "-1px" }} >
                      {/* oee */}
                      <Grid item xs={3}>
                        {(load === true || dailyReport === 'No data')
                          ? <OEE
                            value={[0]}
                            title='OEE'
                          />
                          :
                          <OEE
                            value={[Math.round(dailyReport.OEE)]}
                            title='OEE'
                          />
                        }
                      </Grid>
                      {/* a */}
                      <Grid item xs={2}>
                        {(load === true || dailyReport === 'No data')
                          ? <APQ
                            value={[0]}
                            title='AVA'
                            target={['92%']}
                          />
                          :
                          <APQ
                            value={[Math.round(dailyReport.A)]}
                            title='AVA'
                            target={[Math.round(dailyReport.A_TARGET)]}
                          />
                        }
                      </Grid>
                      {/* p */}
                      <Grid item xs={2}>
                        {(load === true || dailyReport === 'No data')
                          ? <APQ
                            value={[0]}
                            title='PER'
                            target={['95%']}
                          />
                          :
                          <APQ
                            value={[Math.round(dailyReport.P)]}
                            title='PER'
                            target={[Math.round(dailyReport.P_TARGET)]}
                          />
                        }
                      </Grid>
                      {/* q */}
                      <Grid item xs={2}>
                        {(load === true || dailyReport === 'No data')
                          ? <APQ
                            value={[0]}
                            title='QUA'
                            target={['98%']}
                          />
                          :
                          <APQ
                            value={[Math.round(dailyReport.Q)]}
                            title='QUA'
                            target={[Math.round(dailyReport.Q_TARGET)]}
                          />
                        }
                      </Grid>
                    </Grid>
                  </Box>
                  {/* ผลผลิตสะสม */}
                  <Box pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", paddingTop: 40 }}>
                    <Grid item xs={12} md={12} xl={12}>
                      <table style={{ 'padding-top': '0px' }}>
                        <tbody>
                          <tr>
                            <td align="left" style={{ width: '120px', fontSize: 18, fontWeight: 'bold', borderBottom: 'none', height: "1px",  color: "black" }}>ผลผลิตสะสม</td>
                            <td align="left" style={{ width: '300px', fontSize: 14, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>
                              { load === true || dailyReport === undefined ? 
                              <BarChartAccumulativeProduction
                              target_quantity={0}
                              actual_weight={0}
                              target_now={0}
                              unit={'TON'}
                              height='50%'
                            />
                              :
                                <BarChartAccumulativeProduction
                                target_quantity={dailyReport.TARGET_PRODUCTION_QUANTITY}
                                actual_weight={dailyReport.ACTUAL_WEIGHT}
                                target_now={dailyReport.TARGET_PRODUCTION_QUANTITY}
                                unit={dailyReport.PRODUCTION_UNIT}
                                height='50%'
                              />
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Box>
                  {/* perfrmance */}
                  <Box pt={0} px={2} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", paddingTop: 0 }}>
                    <Grid item xs={7.5}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 100 }} aria-label="custom pagination table">
                          <TableBody>
                            {[
                              { name: "Performance", value: dailyReport.ACTUAL_KG_PER_HOUR, unit: "kg/hr", std: "(std. " + [dailyReport.STD_KG_PER_HOUR != 'NA' ? Math.round(dailyReport.STD_KG_PER_HOUR) : 'NA'] + ")" },
                              { name: "", value: dailyReport.ACTUAL_KG_PER_PERSON_PER_HOUR, unit: "kg/person/hr", std: "(std. " + dailyReport.STD_KG_PER_PERSON_PER_HOUR + ")" },
                              { name: "No.Employee", value: dailyReport.EMPLOYEE, unit: "person", std: "(std. " + dailyReport.STD_EMPLOYEE + ")" },
                              { name: "Yield", value: dailyReport.YIELD, unit: "%", std: "(std. " + [dailyReport.STD_YIELD != 'NA' ? Math.round(dailyReport.STD_YIELD) : 'NA'] + "%)" }
                            ].map((row) => (
                              <TableRow key={row.name}>
                                <TableCell style={{ width: '23%', fontSize: 16, fontWeight: 'bold' }} align="right">
                                  {row.name}
                                </TableCell>
                                <TableCell style={{ width: '20%', fontSize: 16 }} align="right">
                                  {row.value}
                                </TableCell>
                                <TableCell style={{ width: '17%', fontSize: 16 }} align="left">
                                  {row.unit}
                                </TableCell>
                                <TableCell style={{ width: '40%', fontSize: 14 }} align="left">
                                  {row.std}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    {/* defect */}
                    <Grid item xs={4.5}>
                      <Box pt={0} px={2} display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography color="black" fontSize={'18px'} fontWeight="bold">
                          &nbsp;&nbsp;Defect สะสม:&nbsp;&nbsp;{(loading === true || defectDonutDataSeries === 'No data') ? ' ' : sumArray(defectDonutDataSeries.value)} กก.

                        </Typography>
                      </Box>


                      {(loading === true || defectDonutDataSeries === 'No data') ? 'No Data' :
                        <Defect
                          labels={defectDonutDataSeries.type}
                          value={defectDonutDataSeries.value} />
                      }

                    </Grid>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Grid>
          {/* right column */}
          <Grid item xs={5}>
            <Item >
              <Box width="100%"  sx={{ background: "#d4d4d4" }}>
                <Typography fontSize={'22px'} fontWeight="bold">
                  รายการสินค้า&nbsp;&nbsp;&nbsp;
                  {/*<Button
                    //sx={{ backgroundColor: '#3cbea8', borderColor: '#3cbea8' }}
                    variant="contained"
                    style={{ color: "white", fontWeight: "bold", fontFamily: 'Monospace', backgroundColor: '#3cbea8' }}
                    onClick={updateDataPO}
                  >
                    Update
                    </Button>*/}
                </Typography>
              </Box>
              <Box mb={0}>
                <Grid container spacing="8px" alignItems="left" justifyContent="left" style={{ position: "relative", top: "20px" }} >
                  <TableContainer sx={{ minWidth: 200, minHeight: 100 }} aria-label="simple table">
                    <Table >
                      <TableRow>
                        <TableCell align="center" style={{ width: '10%', fontSize: 16, fontWeight: "bold" }}></TableCell>
                        <TableCell align="center" style={{ width: '25%', fontSize: 16, fontWeight: "bold" }}></TableCell>
                        <TableCell align="center" style={{ width: '30%', fontSize: 16, fontWeight: "bold" }}>ACTUAL</TableCell>
                        <TableCell align="right" style={{ width: '12%', fontSize: 16, fontWeight: "bold" }}>TARGET</TableCell>
                        <TableCell align="right" style={{ width: '12%', fontSize: 16, fontWeight: "bold" }}>UNIT</TableCell>
                        <TableCell align="right" style={{ width: '11%', fontSize: 16, fontWeight: "bold" }}>YIELD</TableCell>
                      </TableRow>
                      <TableBody>
                        {productionDetail.map((item, index) => {
                          //change color status on condition in table map (https://stackoverflow.com/questions/63872649/change-color-on-conditions-in-table-map-in-reactjs)
                          //change icon border bold (https://github.com/react-icons/react-icons/issues/232)
                          //let colorStatus = item.STATUS == 'Running' ? "light" : item.STATUS == 'Completed' ? 'success' : "disabled"
                          let colorStroke = item.STATUS == 'Running' ? "#00d084" : item.STATUS == 'Completed' ? "#00d084" : "#FFFFFF"

                          const getColor = () => {
                            let color;
                            try {
                              if (item.STATUS == 'Completed') {
                                color = "#00d084"
                              } else if (item.STATUS == 'Running') {
                                color = "#e0e0e0"
                              } else if (item.STATUS == 'Not Start') {
                                color = "#FFFFFF"
                              }
                              return color;
                            }
                            catch {
                              console.log()
                            }
                          }
                          return <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center" style={{ fontSize: 16, width: 80, paddingBottom: 0, paddingTop: 0, height: 40 }}>
                              <LensIcon fontSize="medium" stroke={colorStroke} strokeWidth="2" sx={{ color: getColor() }}>{item.STATUS}</LensIcon>
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: 16, width: 140, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.MATERIAL_DESCRIPTION}</TableCell>
                            <TableCell align="center" style={{ fontSize: 16, paddingBottom: 0, paddingTop: 0, height: 40 }} >
                              <Box mb={-4} ml={1} style={{ position: "relative", display: "block", top: "-20px" }}>
                                { load === true || productionDetail === undefined ? '' :
                                  <Chart
                                  options={{
                                    chart: {
                                      type: 'bar',
                                      stacked: 'true',
                                      toolbar: {
                                        show: false
                                      },
                                      zoom: {
                                        enabled: false
                                      }
                                    },
                                    plotOptions: {
                                      bar: {
                                        horizontal: true,
                                      }
                                    },
                                    colors: item.STATUS === 'Running' ? ['#00E396', '#fff9c4'] : ['#00E396', '#e0e0e0'],
                                    dataLabels: {
                                      enabled: true,
                                      textAnchor: 'middle',
                                      style: {
                                        colors: ['#616161']
                                      },
                                      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                                        if (seriesIndex === 0) {
                                          return value.toFixed(2)
                                        }
                                      },
                                      offsetX: 0,
                                      dropShadow: {
                                        enabled: false
                                      },
                                      distributed: true,
                                      offsetX: 0,
                                      offsetY: 0,
                                      total: {
                                        enabled: true,
                                        offsetX: 0,
                                        style: {
                                          fontSize: '13px',
                                          fontWeight: 900,
                                          alignItems: 'left',
                                          fontFamily: 'CPF_Imm_Sook'
                                        }
                                      }
                                    },
                                    legend: {
                                      show: false,
                                      showForSingleSeries: true,
                                      customLegendItems: ['Actual', 'Expected'],
                                      markers: {
                                        fillColors: ['#00E396', '#775DD0']
                                      }
                                    },
                                    tooltip: {
                                      enabled: false,
                                    },
                                    xaxis: {
                                      type: 'category',
                                      categories: [], type: 'category',
                                      categories: [],
                                      range: item.TARGET_PRODUCTION_QUANTITY,
                                      min: 0,
                                      max: item.TARGET_PRODUCTION_QUANTITY > item.ACTUAL_WEIGHT ? item.TARGET_PRODUCTION_QUANTITY : item.ACTUAL_WEIGHT,
                                      show: false,
                                      labels: {
                                        show: false
                                      },
                                      axisBorder: {
                                        show: false
                                      },
                                      axisTicks: {
                                        show: false
                                      },
                                    },
                                    yaxis: {
                                      axisBorder: {
                                        show: false
                                      },
                                      axisTicks: {
                                        show: false,
                                      },
                                      labels: {
                                        show: false,
                                      },
                                    },
                                    grid: {
                                      padding: {
                                        left: -16
                                      }
                                    },
                                    annotations: {
                                      xaxis: [
                                        {
                                          x: item.TARGET_PRODUCTION_QUANTITY,
                                          strokeDashArray: 0,
                                          borderColor: "#775DD0",
                                          label: {
                                            borderColor: "#775DD0",
                                            borderWidth: 2,
                                            borderRadius: 20,
                                            style: {
                                              color: "#fff",
                                              background: "#775DD0",
                                              fontFamily: 'CPF_Imm_Sook'
                                            },
                                            orientation: "horizontal",
                                            text: item.TARGET_PRODUCTION_QUANTITY
                                          }
                                        },
                                      ]
                                    }
                                  }}

                                  series={[
                                    {
                                      name: 'Actual',
                                      data: [item.ACTUAL_WEIGHT]
                                    },
                                    {
                                      name: 'Waiting',
                                      data: [item.TARGET_PRODUCTION_QUANTITY - item.ACTUAL_WEIGHT < 0 ? 0 : item.TARGET_PRODUCTION_QUANTITY - item.ACTUAL_WEIGHT]
                                    }
                                  ]}
                                  type='bar'
                                  width='100%'
                                  height='50%'
                                />}
                              </Box>
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: 16, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.TARGET_PRODUCTION_QUANTITY}</TableCell>
                            <TableCell align="right" style={{ fontSize: 16, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.PRODUCTION_UNIT}</TableCell>
                            <TableCell align="right" style={{ fontSize: 16, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.YIELD}</TableCell>

                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>
        {/* Bottom row */}
        <Grid container spacing={1} style={{ position: "relative", paddingTop: 0}}>
          <Grid item xs={12}>
            <Item sx={{ height: "100%" }}>
              <Box width="100%" sx={{ background: "#d4d4d4" }} style={{ position: "relative", top: "0px" }}>
                <Typography fontSize={'20px'} fontWeight="medium" color="black">
                  Layout
                </Typography>
              </Box>
              {layout.length > 0 ?

                <Box pt={0} px={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Grid item xs={2} md={2} xl={1} >
                    <Typography fontSize={'16px'} fontWeight="medium">
                      NA
                    </Typography>
                    <Card sx={{ background: "#32adad" }}>
                      <Box width="100px" height="40px" textAlign="center" >
                        <Typography fontSize={'13px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                          Preperation
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                  <Grid item xs={2} md={2} xl={1} >
                    <Typography fontSize={'16px'} fontWeight="medium">
                      {nameFryer1 != '' ? nameFryer1 : 'NA'}
                    </Typography>
                    <Card sx={{ background: "#32adad" }}>
                      <Box width="100px" height="40px" textAlign="center" >
                        <Typography fontSize={'13px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                          FRYER1
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                  <Grid item xs={2} md={2} xl={1} >
                    <Typography fontSize={'16px'} fontWeight="medium" >
                      {nameFryer2 != '' ? nameFryer2 : 'NA'}
                    </Typography>
                    <Card sx={{ background: "#32adad" }}>
                      <Box width="100px" height="40px" textAlign="center" >
                        <Typography fontSize={'13px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }}>
                          FRYER2
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                  <Grid item xs={2} md={2} xl={1} >
                    <Typography fontSize={'16px'} fontWeight="medium" >
                      NA
                    </Typography>
                    <Card sx={{ background: "#32adad" }}>
                      <Box width="100px" height="40px" textAlign="center" >
                        <Typography fontSize={'13px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                          IQF
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                  <Grid item xs={2} md={2} xl={1} >
                    <Typography fontSize={'16px'} fontWeight="medium" >
                      {nameMultihead != '' ? nameMultihead : 'NA'}
                    </Typography>
                    <Card sx={{ background: "#32adad" }}>
                      <Box width="100px" height="40px" textAlign="center" >
                        <Typography fontSize={'13px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                          MULTIHEAD
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                  <Grid item xs={2} md={2} xl={1} >
                    <Typography fontSize={'16px'} fontWeight="medium" >
                      {nameCheckWeight != '' ? nameCheckWeight : 'NA'}
                    </Typography>
                    <Card sx={{ background: "#32adad" }}>
                      <Box width="100px" height="40px" textAlign="center" >
                        <Typography fontSize={'13px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }}>
                          Check weight
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                  <Grid item xs={2} md={2} xl={1} >
                    <Typography fontSize={'16px'} fontWeight="medium" >
                      {nameXray != '' ? nameXray : 'NA'}
                    </Typography>
                    <Card sx={{ background: "#32adad" }}>
                      <Box width="100px" height="40px" textAlign="center" >
                        <Typography fontSize={'13px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }}  >
                          X-Ray
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                  <Grid item xs={2} md={2} xl={1} >
                    <Typography fontSize={'16px'} fontWeight="medium" >
                      {nameMD != '' ? nameMD : 'NA'}
                    </Typography>
                    <Card sx={{ background: "#32adad" }}>
                      <Box width="120px" height="40px" textAlign="center" >
                        <Typography fontSize={'13px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                          Metal Detector
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>

                </Box>
                : <div>Not Running</div>}
            </Item>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}

